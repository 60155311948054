export enum PageSwitchQueryKeys {
  CUSTOMIZATION_FIELDS_QUERY_KEY = 'CUSTOMIZATION_FIELDS_QUERY_KEY',
}

export interface FiltersParams {
  departments?: number | string;
  subDepartments?: number | string;
  season?: number | string;
  event?: string;
  identifier?: number | string;
  name?: string;
}

export type CustomizationField = {
  id: string;
  name: string;
  type: string;
  configurable: boolean;
  hasValues: boolean;
  hasAssignments: boolean;
  hasSwatches: boolean;
  hasVisibility: boolean;
  hasNotInCatalogFilter: boolean;
  hasAddFromCatalog: boolean;
  hasVisibilityFilter: boolean;
  dependsOnForSwatches?: string;
  dependsOn?: string;
  hasStatusFilter: boolean;
  hasUnassignedFilter: boolean;
};

export type GetCustomizationFieldsResponse = {
  data: {
    fields: Array<CustomizationField>;
  };
};
