import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import {IconSVG} from 'components/atoms/IconSVG';

import {ModalProps} from './definitions';

const Modal = ({
  headerIcon,
  title,
  titleSize = 'text-big',
  content,
  actions,
  gap = 4,
  ...props
}: ModalProps) => {
  return (
    <Dialog
      PaperProps={{
        className: 'p-[60px] font-book text-mediumSmall rounded-none',
      }}
      {...props}>
      <Stack gap={gap}>
        {headerIcon && (
          <Stack width="100%" alignItems="center">
            <IconSVG icon={headerIcon} size={96} />
          </Stack>
        )}
        {title && (
          <DialogTitle className={`p-0 font-bold uppercase ${titleSize}`}>
            {title}
          </DialogTitle>
        )}
        {content && (
          <DialogContent className="p-0 text-primary-2 min-w-[480px] flex flex-col items-center">
            {content}
          </DialogContent>
        )}
        {actions && (
          <DialogActions
            disableSpacing
            className={`p-0 flex w-full ${
              actions?.length > 1 ? 'justify-between' : 'justify-center'
            }`}>
            {actions}
          </DialogActions>
        )}
      </Stack>
    </Dialog>
  );
};

export default React.memo(Modal);
