import React from 'react';

import {Breadcrumbs, Link} from '@mui/material';

import {IconSVG} from '../IconSVG';

import {IBreadcrumb} from './interfaces';

const Breadcrumb = ({breadcrumb}: IBreadcrumb) => {
  return (
    <div className="2xl:container mx-auto">
      <Breadcrumbs
        className="uppercase text-smaller text-primary-main font-bold"
        classes={{
          separator: '-mr-1',
          li: 'p-2',
        }}
        separator={<IconSVG icon="keyboard-arrow-right" size={18} />}
        aria-label="breadcrumb">
        {breadcrumb.map(({label, link}) => (
          <Link
            key={label}
            href={link}
            color="inherit"
            className="no-underline ">
            {label}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default React.memo(Breadcrumb);
