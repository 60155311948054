import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import type {TFunction} from 'i18next';
import i18next, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import {i18nSettings} from './utils';

interface ProvidedValue {
  currentLanguage?: string;
  setLanguage: (language: string) => void;
  t: TFunction;
}

const Context = createContext<ProvidedValue>({
  currentLanguage: undefined,
  setLanguage: () => {
    console.log('TranslationsProvider is not rendered!');
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  t: () => null,
});

interface Props {
  params: Partial<InitOptions>;
  children?: React.ReactNode;
}

export const TranslationsProvider = memo((props: Props) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [language, setLanguage] = useState<string | undefined>(
    props.params.lng,
  );

  useEffect(() => {
    (async () => {
      // Initialize i18next
      const settings = i18nSettings(props.params);
      await i18next.use(LanguageDetector).use(initReactI18next).init(settings);
      if (!props.params.lng) {
        setLanguage(i18next.language);
      }
      setInitialized(true);
    })();
  }, [props.params]);

  const handleSetLanguage = useCallback(
    async (language: string) => {
      await i18next.changeLanguage(language);
      setLanguage(language);
    },
    [setLanguage],
  );

  const MemoizedValue = React.useMemo(() => {
    const value: ProvidedValue = {
      currentLanguage: language,
      setLanguage: handleSetLanguage,
      t: i18next.t.bind(i18next),
    };

    return value;
  }, [language, handleSetLanguage]);
  TranslationsProvider.displayName = 'TranslationsProvider';
  return (
    <Context.Provider value={MemoizedValue}>
      {initialized && props.children}
    </Context.Provider>
  );
});

export const useTranslations = (): ProvidedValue => React.useContext(Context);
