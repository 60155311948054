import React from 'react';

import {Info} from './Info';

const ModelForm = () => {
  return (
    <div className="flex flex-col gap-[30px]">
      <Info />
    </div>
  );
};

export default React.memo(ModelForm);
