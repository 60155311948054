import React, {ReactElement, useCallback, useMemo} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Sidebar} from 'components/organisms/Sidebar';
import {ValueFormFields} from 'components/organisms/ValueForm/definitions';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {AssignmentsQueryKeys} from 'store/pageSwitch/assignments/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {ValueForm} from '../ValueForm';

import {ValuesSidebarProps} from './definitions';

const ValuesSidebar = ({
  open,
  onClose,
  mode,
  currentPage,
  initialData,
  onCreateValue,
  onUpdateValue,
  onDeleteValue,
}: ValuesSidebarProps): ReactElement => {
  const {t} = useTranslations();

  const formMethods = useForm<ValueFormFields>({
    defaultValues: {
      id: '',
      name: '',
      picture: undefined,
      color: undefined,
      tracking_tool_id: '',
      default_if: '',
      fallback: false,
      always_selected: false,
      shortcut_image_provider: '',
    },
    values: initialData,
  });

  const {
    reset,
    formState: {isValid, isSubmitting},
    handleSubmit,
  } = useMemo(() => {
    return formMethods;
  }, [formMethods]);

  const queryClient = useQueryClient();

  const onSubmit = useCallback<SubmitHandler<ValueFormFields>>(
    data => {
      const copyData = structuredClone(data);
      copyData.default_if =
        data.default_if && data.default_if.trim().split(' ').join(',');

      queryClient.invalidateQueries({
        queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
        exact: false,
        refetchType: 'all',
      });

      if (mode === 'create') {
        onCreateValue?.(copyData);
      }

      if (mode === 'update') {
        onUpdateValue?.(copyData);
      }
    },
    [mode, onCreateValue, onUpdateValue, queryClient],
  );

  return (
    <Sidebar
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      actions={{
        leftButtonProps: {
          variant: mode === 'create' ? 'tertiary' : 'secondary',
          iconLeft: mode === 'create' ? undefined : 'delete',
          onClick:
            mode === 'create'
              ? () => {
                  reset();
                  onClose();
                }
              : onDeleteValue,
        },
        leftButtonLabel:
          mode === 'create'
            ? t('ValuesSidebar.actions.cancel')
            : t('ValuesSidebar.actions.delete'),
        rightButtonProps: {
          iconLeft: mode === 'create' ? 'add' : 'save',
          disabled: !isValid || isSubmitting,
          onClick: handleSubmit(onSubmit),
        },
        rightButtonLabel:
          mode === 'create'
            ? t('ValuesSidebar.actions.add')
            : t('ValuesSidebar.actions.save'),
      }}>
      <FormProvider {...formMethods}>
        <ValueForm
          mode={mode}
          showAppearanceFields={currentPage.hasSwatches}
          linkToSwathes={Boolean(currentPage.dependsOnForSwatches)}
        />
      </FormProvider>
    </Sidebar>
  );
};

export default React.memo(ValuesSidebar);
