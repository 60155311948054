import React, {useCallback, useMemo, useState} from 'react';

import {PageContentTabs} from 'components/molecules/PageContentTabs';
import {
  CustomTabsProps,
  TabKey,
} from 'components/molecules/PageContentTabs/definitions';
import {PageProvider} from 'contexts/page/PageContext';
import {useTranslations} from 'vidiemme/react-i18n';

import {useFilterContext} from '../../../contexts';
import {AssignmentsTable} from '../AssignmentsTable';
import {Swatches} from '../Swatches';
import {ValuesTable} from '../ValuesTable';
import {VisibilityTable} from '../VisibilityTable';

import {PageContentProps, TabListMap} from './definitions';

const tabListMap: TabListMap = {
  hasValues: 'values',
  hasAssignments: 'assignments',
  hasSwatches: 'swatches',
  hasVisibility: 'visibility',
};

const PageContent = ({
  currentPage,
  customizationId,
  hasDependencyField,
  dependencyFieldName,
}: PageContentProps) => {
  const {t} = useTranslations();

  const {id: currentPageId} = currentPage;
  const {resetFilters} = useFilterContext();

  const [tabValue, setTabValue] = useState<number>(0);

  const handleTabChange = useCallback<CustomTabsProps['onChange']>(
    (event, newValue) => {
      setTabValue(newValue);
      resetFilters();
    },
    [resetFilters],
  );

  const tabKeyList = useMemo(() => {
    const tempTabList: Array<TabKey> = [];

    if (currentPage !== undefined) {
      const keyList = Object.keys(tabListMap) as (keyof TabListMap)[];

      keyList.forEach(singleTab => {
        if (singleTab in currentPage && currentPage[singleTab]) {
          tempTabList.push(tabListMap[singleTab]);
        }
      });
    }

    return tempTabList;
  }, [currentPage]);

  const goToTab = useCallback(
    (tabKey: TabKey) => {
      const newIndex = tabKeyList.indexOf(tabKey);
      if (newIndex !== -1) {
        setTabValue(newIndex);
      }
    },
    [tabKeyList],
  );

  const tabPanelMap = useMemo(() => {
    const panelMap: CustomTabsProps['tabPanelMap'] = {};

    //TODO miss visibility table
    if (customizationId && currentPage && currentPageId) {
      if (currentPage.hasValues) {
        panelMap['values'] = (
          <ValuesTable
            key={`${customizationId}-${currentPageId}`}
            customizationId={customizationId!}
            currentPageId={currentPageId}
            currentPage={currentPage}
            hasAddFromCatalog={currentPage.hasAddFromCatalog}
          />
        );
      }
      if (currentPage.hasAssignments && dependencyFieldName) {
        panelMap['assignments'] = (
          <AssignmentsTable
            key={`${customizationId}-${currentPageId}`}
            customizationId={customizationId!}
            currentPageId={currentPageId}
            currentPage={currentPage}
            dependencyName={dependencyFieldName}
            hasCurrentPageNotInCatalogFilter={currentPage.hasNotInCatalogFilter}
          />
        );
      }
      if (currentPage.hasSwatches && dependencyFieldName) {
        panelMap['swatches'] = (
          <Swatches
            key={`${customizationId}-${currentPageId}`}
            customizationId={customizationId!}
            currentPageId={currentPageId}
            currentPage={currentPage}
            dependencyName={dependencyFieldName}
          />
        );
      }
      if (currentPage.hasVisibility) {
        panelMap['visibility'] = (
          <VisibilityTable
            key={`${customizationId}-${currentPageId}`}
            customizationId={customizationId}
          />
        );
      }
    }
    return panelMap;
  }, [currentPage, currentPageId, customizationId, dependencyFieldName]);

  const labelInfo = useMemo(() => {
    if (!hasDependencyField) {
      return t('Tabs.noDependencyLabel', {primary: currentPage.name});
    }

    return t('Tabs.dependsOnLabel', {
      primary: currentPage.name,
      dependency: dependencyFieldName,
    });
  }, [currentPage.name, dependencyFieldName, hasDependencyField, t]);

  return (
    <PageProvider value={{currentPage, goToTab, dependencyFieldName}}>
      <PageContentTabs
        tabKeyList={tabKeyList}
        tabPanelMap={tabPanelMap}
        labelInfo={labelInfo}
        value={tabValue}
        onChange={handleTabChange}
      />
    </PageProvider>
  );
};

export default React.memo(PageContent);
