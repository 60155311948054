import React, {ReactElement, useCallback, useMemo} from 'react';

import {Button} from 'components/atoms/Button';
import {useUtils} from 'hooks';
import {NavLink} from 'react-router-dom';
import {useAuthentication} from 'store/authentication';
import {ReactComponent as Logo} from 'themes/base/assets/logo.svg';
import {useTranslations} from 'vidiemme/react-i18n';
import './header.scss';

const Header = (): ReactElement => {
  const {t} = useTranslations();
  const {getStorage} = useUtils();
  const {logout} = useAuthentication();

  const user = useMemo(() => {
    const userObj = getStorage('user');
    return userObj && JSON.parse(userObj)?.data?.user;
  }, [getStorage]);

  const onClickLogout = useCallback(() => {
    logout();
  }, [logout]);
  return (
    <header>
      <div className="2xl:container mx-auto h-[60px] flex items-center basis-full justify-between px-5 2xl:px-0">
        {user && (
          <NavLink to="/">
            <Logo />
          </NavLink>
        )}
        {!user && <Logo />}
        <div className="flex items-center">
          <p className="mr-2.5 font-bold uppercase text-white">{user?.name}</p>
          {user && (
            <Button
              onClick={onClickLogout}
              iconRight="exit"
              variant="logout"
              classNames="text-smaller">
              {t('General.logout')}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
