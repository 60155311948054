import {SwatchWithDependency} from 'store/pageSwitch/swatches/definitions';

import {SwatchesTableCommon} from '../SwatchesTable/definitions';

export const SWATCHES_PAGINATION_DEPENDENCY_LIMIT: number = 200;

export interface SwatchesCombinedTableProps
  extends SwatchesTableCommon<SwatchWithDependency> {
  setDependencyPagination: SwatchesTableCommon<SwatchWithDependency>['setPrimaryPagination'];
  dependencyPagination: SwatchesTableCommon<SwatchWithDependency>['primaryPagination'];
}
