import React from 'react';

import {AdvancedSettings} from './AdvancedSettings';
import {Appearance} from './Appearance';
import {ValueFormProps} from './definitions';
import {Info} from './Info';

const ValueForm = ({
  mode,
  showAppearanceFields,
  linkToSwathes,
}: ValueFormProps) => {
  return (
    <div className="flex flex-col gap-[30px]">
      <Info disableIdField={mode === 'update'} />
      {showAppearanceFields && <Appearance linkToSwathes={linkToSwathes} />}
      <AdvancedSettings disabledFallbackField={mode === 'update'} />
    </div>
  );
};

export default React.memo(ValueForm);
