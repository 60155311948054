import React from 'react';

import {DialogProps} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

const SaveConfirmModal: React.FC<
  DialogProps & {
    onClickPrimary: () => void;
    onClickSecondary: () => void;
  }
> = ({onClickPrimary, onClickSecondary, ...props}) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      title={t('Assignments.SaveConfirmModal.title')}
      content={t('Assignments.SaveConfirmModal.subTitle')}
      actions={[
        <Button variant="secondary" key="secondary" onClick={onClickSecondary}>
          {t('Assignments.SaveConfirmModal.secondaryCta')}
        </Button>,
        <Button variant="primary" key="primary" onClick={onClickPrimary}>
          {t('Assignments.SaveConfirmModal.primaryCta')}
        </Button>,
      ]}
    />
  );
};

export default React.memo(SaveConfirmModal);
