import React, {useCallback, useMemo, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Button} from 'components/atoms/Button';
import {OptionItem} from 'components/atoms/Select/definitions';
import {Filters} from 'components/molecules/Filters';
import {Filter} from 'components/molecules/Filters/definitions';
import {Table} from 'components/molecules/Table';
import {RowAction} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {AddFromCatalogSidebar} from 'components/organisms/AddFromCatalogSidebar';
import {ConfirmDeleteModal} from 'components/organisms/ConfirmDeleteModal';
import GenericModal from 'components/organisms/GenericModal';
import {ValueFormFields} from 'components/organisms/ValueForm/definitions';
import {ValuesSidebar} from 'components/organisms/ValuesSidebar';
import {ValuesSidebarProps} from 'components/organisms/ValuesSidebar/definitions';
import {useFilterContext} from 'contexts';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {AssignmentsQueryKeys} from 'store/pageSwitch/assignments/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {usePageValues} from 'store/pageSwitch/values';
import {
  CreateValueParams,
  UpdateValueParams,
  ValueItem,
} from 'store/pageSwitch/values/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {ValuesTableProps} from './definitions';

const ValuesTable = ({
  customizationId,
  currentPageId,
  currentPage,
  hasAddFromCatalog,
}: ValuesTableProps) => {
  const {t} = useTranslations();

  const [createSidebarOpened, setCreateSidebarOpened] =
    useState<boolean>(false);

  const [updateValueData, setUpdateValueData] = useState<ValueItem | undefined>(
    undefined,
  );

  const [addFromCatalogOpened, setAddFromCatalogOpened] =
    useState<boolean>(false);

  const [confirmDeleteModalsOpen, setConfirmDeleteModalsOpen] =
    useState<boolean>(false);

  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [successDeletedModelModalOpen, setSuccessDeletedModelModalOpen] =
    useState<boolean>(false);
  const [singleDelete, setSingleDelete] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const {
    useGetValues,
    callDeleteValues,
    callCreateValue,
    callUpdateValue,
    useGetValuesFromCatalog,
  } = usePageValues();
  const queryClient = useQueryClient();

  //Rows Data
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  //Pagination param
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  //Filters param
  const {filters} = useFilterContext();

  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  //Sorting params
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const paramSorting = useMemo<{
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
  }>(() => {
    const sortingParam = sorting.length > 0 && sorting[0];
    if (sortingParam) {
      return {
        sortBy: sortingParam.id,
        orderBy: sortingParam.desc ? 'DESC' : 'ASC',
      };
    } else {
      return {};
    }
  }, [sorting]);

  const {data: values, isLoading: isLoading} = useGetValues({
    customizationId: customizationId,
    currentPageId: currentPageId,
    options: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      ...paramSorting,
      ...paramFilters,
    },
  });

  const {data: valuesFromCatalogCount, refetch: refetchValues} =
    useGetValuesFromCatalog({
      customizationId: customizationId,
      currentPageId: currentPageId,
      options: {
        onlyCount: true,
      },
    });

  const valuesFromCatalogTotal = useMemo<number>(() => {
    return valuesFromCatalogCount ? valuesFromCatalogCount.data.totalCount : 0;
  }, [valuesFromCatalogCount]);

  const deleteSelectedRows = useCallback(
    (selectedRows: Record<string, boolean>) => {
      return callDeleteValues(
        {
          customizationId: customizationId!,
          currentPageId: currentPageId,
          params: {
            valueIds: Object.keys(selectedRows),
          },
        },
        {
          onSuccess: () => {
            setSuccessDeletedModelModalOpen(true);
          },
        },
      );
    },
    [callDeleteValues, customizationId, currentPageId],
  );

  const handleCreateValue = useCallback(
    (data: ValueFormFields) => {
      const filteredData: CreateValueParams['params'] = {...data};

      if (filteredData.color === '') {
        delete filteredData.color;
      }

      callCreateValue(
        {
          customizationId,
          currentPageId,
          params: filteredData,
        },
        {
          onSuccess: () => {
            setSuccessModalOpen(true);
            setCreateSidebarOpened(false);
          },
          onError: () => {
            setErrorModalOpen(true);
          },
        },
      );
    },
    [callCreateValue, currentPageId, customizationId],
  );

  const handleUpdateValue = useCallback(
    (data: ValueFormFields) => {
      if (updateValueData === undefined) {
        return;
      }

      const filteredData: UpdateValueParams['params'] = {...data};

      if (filteredData.color === '') {
        delete filteredData.color;
      }

      callUpdateValue(
        {
          customizationId,
          currentPageId,
          valueId: updateValueData.id,
          params: filteredData,
        },
        {
          onSuccess: () => {
            setSuccessModalOpen(true);
            setUpdateValueData(undefined);
          },
        },
      );
    },
    [callUpdateValue, currentPageId, customizationId, updateValueData],
  );

  const handleDeleteValue = useCallback(() => {
    if (!updateValueData) {
      return;
    }

    deleteSelectedRows({
      [updateValueData.id]: true,
    }).then(() => {
      setUpdateValueData(undefined);
      queryClient.invalidateQueries({
        queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
        exact: false,
        refetchType: 'all',
      });
    });
  }, [deleteSelectedRows, queryClient, updateValueData]);

  //Rows Data
  const columns = useMemo<MRT_ColumnDef<ValueItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('Values.table.id'),
      },
      {
        accessorKey: 'name',
        header: t('Values.table.name'),
        Cell: ({cell}) => {
          const cellComponent = cell.row.original.picture ? (
            <TextWithRoundedImage
              image={cell.row.original.picture}
              text={cell.getValue() as string}
            />
          ) : (
            <p>{cell.getValue<string>()}</p>
          );
          return cellComponent;
        },
      },
    ],
    [t],
  );

  const status = useMemo<{[index: string]: string}>(() => {
    return {
      unassigned: t('Values.status.unassigned'),
      unexpected: t('Values.status.unexpected'),
    };
  }, [t]);

  const statusOptions = useMemo(() => {
    if (!currentPage.hasStatusFilter) {
      return [];
    }

    const optionsList: Array<OptionItem> = [];

    Object.keys(status).forEach(singleStatusKey => {
      if (
        (currentPage.hasNotInCatalogFilter &&
          singleStatusKey === 'unexpected') ||
        (currentPage.hasUnassignedFilter && singleStatusKey == 'unassigned')
      ) {
        optionsList.push({
          value: singleStatusKey,
          label: status[singleStatusKey],
        });
      }
    });

    return optionsList;
  }, [
    currentPage.hasNotInCatalogFilter,
    currentPage.hasStatusFilter,
    currentPage.hasUnassignedFilter,
    status,
  ]);

  const actions = useMemo<RowAction<ValueItem>[]>(() => {
    return [
      {
        label: t('Table.unexpected'),
        icon: 'error',
        condition: 'unexpected',
      },
      {
        label: t('Table.unassigned'),
        icon: 'layers-clear',
        condition: 'unassigned',
      },
      {
        icon: 'edit',
        onClick: row => {
          setUpdateValueData(row);
        },
      },
    ];
  }, [t]);

  const tableFooter = useMemo(() => {
    return (
      <div className="table-footer-buttons  sm:px-5 2xl:px-0  flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full">
        <div className="flex justify-between py-5 w-full">
          <div className="flex items-center">
            <Button
              onClick={() => {
                setConfirmDeleteModalsOpen(true);
              }}
              iconLeft={'delete'}
              variant={'outline'}
              disabled={Object.keys(rowSelection).length === 0}
              count={Object.keys(rowSelection).length}>
              {t('Values.buttons.delete')}
            </Button>
          </div>
          <div className="flex items-center gap-5">
            <Button
              onClick={() => {
                setCreateSidebarOpened(true);
              }}
              iconLeft={'add'}
              variant={'primary'}>
              {t('Values.buttons.addManually')}
            </Button>
            {hasAddFromCatalog && (
              <Button
                onClick={() => {
                  setAddFromCatalogOpened(true);
                }}
                iconLeft={'add-playlist'}
                variant={'primary'}
                disabled={valuesFromCatalogTotal === 0}
                count={valuesFromCatalogTotal}>
                {t('Values.buttons.addFromCatalog')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }, [hasAddFromCatalog, rowSelection, t, valuesFromCatalogTotal]);

  const filterList = useMemo<Array<Filter>>(() => {
    let list: Array<Filter> = [
      {
        id: 'id',
        name: 'id',
        label: t('Filters.id'),
      },
      {
        id: 'name',
        name: 'name',
        label: t('Filters.name'),
        maxi: true,
      },
    ];

    if (statusOptions.length > 0) {
      list = [
        ...list,
        {
          id: 'status',
          name: 'status',
          label: t('Filters.status'),
          type: 'select',
          options: statusOptions,
        },
      ];
    }

    return list;
  }, [statusOptions, t]);

  const updateValueInitialData = useMemo<
    ValuesSidebarProps['initialData']
  >(() => {
    return updateValueData
      ? {
          id: updateValueData.id,
          name: updateValueData.name,
          picture: updateValueData.picture ?? '',
          color: updateValueData.color ?? '',
          tracking_tool_id: updateValueData.tracking_tool_id ?? '',
          default_if:
            (updateValueData.default_if &&
              updateValueData.default_if.join(' ')) ??
            '',
          fallback: updateValueData.fallback ?? false,
          always_selected: updateValueData.always_selected ?? false,
          shortcut_image_provider:
            updateValueData.shortcut_image_provider ?? '',
        }
      : undefined;
  }, [updateValueData]);

  return (
    <>
      <div className="2xl:container mx-auto py-5">
        <Filters filterList={filterList} />
      </div>
      {values?.data.values && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={values.data.totalCount}
          actions={actions}
          tableOptions={{
            getRowId: originalRow => originalRow.id,
            enableRowSelection: true,
            columns,
            rowCount: values.data.totalCount,
            data: values.data.values,
            initialState: {pagination: pagination, isLoading: isLoading},
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
          }}
          tableFooter={tableFooter}
        />
      )}
      <GenericModal
        onClose={() => {
          setSuccessModalOpen(false);
        }}
        open={successModalOpen}
        content={t('Models.successModal.message')}
        iconName={'save'}
      />
      <ConfirmDeleteModal
        onConfirm={() => {
          setConfirmDeleteModalsOpen(false);
          singleDelete
            ? handleDeleteValue()
            : deleteSelectedRows(rowSelection).then(() => {
                setRowSelection({});
                queryClient.invalidateQueries({
                  queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
                  exact: false,
                  refetchType: 'all',
                });
              });
        }}
        onClose={() => setConfirmDeleteModalsOpen(false)}
        open={confirmDeleteModalsOpen}
        text={'Values.confirmDeleteModal.text'}
        primaryCtaText={'Values.confirmDeleteModal.primaryCtaText'}
        secondaryCtaText={'Values.confirmDeleteModal.secondaryCtaText'}
        primaryCtaVariant={'delete'}
      />
      {/*Success Modal on delete models*/}
      <GenericModal
        onClose={() => setSuccessDeletedModelModalOpen(false)}
        open={successDeletedModelModalOpen}
        content={t('Models.successModal.deleteSuccessMessage')}
        iconName={'delete'}
      />
      {/* Error Modal*/}
      <GenericModal
        onClose={() => setErrorModalOpen(false)}
        open={errorModalOpen}
        content={t('General.errorModal.message')}
        iconName={'error'}
      />
      <ValuesSidebar
        open={createSidebarOpened}
        onClose={() => {
          setCreateSidebarOpened(false);
          refetchValues();
        }}
        mode="create"
        currentPage={currentPage}
        onCreateValue={handleCreateValue}
      />
      <ValuesSidebar
        open={updateValueData !== undefined}
        onClose={() => {
          setUpdateValueData(undefined);
          refetchValues();
        }}
        mode="update"
        currentPage={currentPage}
        onUpdateValue={handleUpdateValue}
        onDeleteValue={() => {
          setSingleDelete(true);
          setConfirmDeleteModalsOpen(true);
        }}
        initialData={updateValueInitialData}
      />
      {hasAddFromCatalog && (
        <AddFromCatalogSidebar
          open={addFromCatalogOpened}
          onClose={() => setAddFromCatalogOpened(false)}
          customizationId={customizationId}
          currentPageId={currentPageId}
        />
      )}
    </>
  );
};

export default React.memo(ValuesTable);
