import React, {useCallback} from 'react';

import {Slide, toast} from 'react-toastify';

import {IProps} from './interfaces';

const commonProps = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  transition: Slide,
};

export const useToast = () => {
  /**
   * @brief Show error
   */
  const showErrorToast = useCallback((message: IProps) => {
    toast.error(
      <span className="toastify__icon-container">
        <>{message}</>
      </span>,
      {
        position: 'top-center',
        ...commonProps,
      },
    );
  }, []);

  /**
   * @brief Show message
   */
  const showSuccessToast = useCallback((message: IProps) => {
    toast.success(
      <span className="toastify__icon-container">
        <>{message}</>
      </span>,
      {
        position: 'top-center',
        ...commonProps,
      },
    );
  }, []);

  return {
    showErrorToast,
    showSuccessToast,
  };
};
