import {useCallback} from 'react';

import * as Sentry from '@sentry/react';
import {Page} from 'components/organisms/PageContent/definitions';

const useUtils = () => {
  const customError = (error: Error) => {
    Sentry.captureException(error);
  };
  const setStorage = useCallback((key: string, value: string) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);
  const setSessionStorage = useCallback((key: string, value: string) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, []);
  const getStorage = useCallback((key: string) => {
    return localStorage.getItem(key);
  }, []);
  const getSessionStorage = useCallback((key: string) => {
    return sessionStorage.getItem(key);
  }, []);
  const removeStorage = useCallback((key: string) => {
    localStorage.removeItem(key);
  }, []);

  const getDependencyName = useCallback(
    (fieldsList: Array<Page>, currentPage: Page) => {
      let dependencyName = '';
      fieldsList.forEach(singlePage => {
        if (singlePage.id === currentPage.dependsOn) {
          dependencyName =
            singlePage.name !== 'N/A' ? singlePage.name : singlePage.id;
        }
      });
      return dependencyName;
    },
    [],
  );

  const uploadOnClick = useCallback((allowedFiles: string = '*') => {
    return new Promise<File>(resolve => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = allowedFiles;
      input.onchange = () => {
        if (input?.files) {
          const files = Array.from(input?.files);
          resolve(files[0]);
        }
      };
      input.click();
    });
  }, []);

  const isEqualsArray = useCallback((a: Array<string>, b: Array<string>) => {
    if (!a || !b) {
      return false;
    }
    // check if the lengths are equal
    if (a.length !== b.length) {
      return false;
    }

    const map = new Map();
    for (const elem of a) {
      // increment the frequency of each element
      map.set(elem, (map.get(elem) || 0) + 1);
    }

    for (const elem of b) {
      // if the element is not in the map, the arrays are not equal
      if (!map.has(elem)) {
        return false;
      }

      // decrement the frequency of each element
      map.set(elem, map.get(elem) - 1);

      // if the frequency becomes negative, the arrays are not equal
      if (map.get(elem) < 0) {
        return false;
      }
    }

    return true;
  }, []);

  const isSelectAll = useCallback((stringToCheck: string) => {
    const splittedString = stringToCheck && stringToCheck.split('-');
    if (!splittedString) {
      return false;
    }
    let normalStringFound: boolean = false;
    splittedString.forEach(singleComponent => {
      if (singleComponent !== '*') {
        normalStringFound = true;
      }
    });
    return !normalStringFound;
  }, []);

  const hasOnlySelectInAllArray = useCallback(
    (stringToCheckArray: Array<string>) => {
      if (stringToCheckArray && stringToCheckArray.length === 0) {
        return false;
      }
      let normalStringFound: boolean = false;
      stringToCheckArray.forEach(stringToCheck => {
        const splittedString = stringToCheck.split('-');
        splittedString.forEach(singleComponent => {
          if (singleComponent !== '*') {
            normalStringFound = true;
          }
        });
      });
      return normalStringFound;
    },
    [],
  );

  const hasSelectAllInDepencyArray = useCallback(
    (stringToCheckArray: Array<string>) => {
      if (stringToCheckArray && stringToCheckArray.length === 0) {
        return false;
      }
      let isSelectAll: boolean = true;

      stringToCheckArray.forEach(stringToCheck => {
        const splittedString = stringToCheck.split('-');
        splittedString.forEach(singleComponent => {
          if (singleComponent !== '*') {
            isSelectAll = false;
          }
        });
      });
      return isSelectAll;
    },
    [],
  );

  return {
    customError: customError,
    setStorage,
    getStorage,
    removeStorage,
    getDependencyName,
    uploadOnClick,
    setSessionStorage,
    getSessionStorage,
    isEqualsArray,
    isSelectAll,
    hasOnlySelectInAllArray,
    hasSelectAllInDepencyArray,
  };
};
export default useUtils;
