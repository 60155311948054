import React, {ReactElement, useMemo} from 'react';

import {Table} from 'components/molecules/Table';

import {IProps} from './definitions';
import '../assignments.scss';

const CombinedTable = ({
  pagination,
  assignmentsListCopy,
  tableFooter,
  paginationButtons,
  noResults,
  columns,
  isLoadingGetFieldAssignments,
  rowSelection,
  setRowSelection,
  setPagination,
}: IProps): ReactElement => {
  const table = useMemo(() => {
    return (
      assignmentsListCopy &&
      columns && (
        <>
          <Table
            pageSize={pagination.pageSize}
            currentPage={pagination.pageIndex}
            totalRows={assignmentsListCopy.data.primary.totalCount}
            tableFooter={tableFooter}
            dependencyPagination={paginationButtons}
            hidePagination={noResults}
            resetColumnOrder={true}
            tableOptions={{
              getRowId: originalRow => originalRow.id,
              columns,
              data: assignmentsListCopy.data.primary.values,
              rowCount: assignmentsListCopy.data.primary.totalCount,
              initialState: {
                columnPinning: {
                  left: ['primary'],
                },
                isLoading: isLoadingGetFieldAssignments,
              },
              enableColumnPinning: true,
              enableSorting: false,
              enableStickyHeader: true,
              columnPinning: {
                left: ['primary'],
              },
              state: {
                rowSelection: rowSelection,
                pagination: pagination,
              },
              muiTableBodyCellProps: {
                align: 'center',
                sx: () => ({
                  boxShadow: 'none',
                  '&:not(:first-of-type):not(:nth-of-type(2))': {
                    borderRight: '1px solid #F6EFE9',
                    borderBottom: '1px solid #F6EFE9',
                  },

                  '&:first-of-type': {
                    borderBottom: '1px solid #D0B194',
                    borderRight: '1px solid #D0B194',
                    backgroundColor: '#F6EFE9',
                  },
                }),
              },
              onRowSelectionChange: setRowSelection,
              onPaginationChange: setPagination,
            }}></Table>
        </>
      )
    );
  }, [
    assignmentsListCopy,
    columns,
    isLoadingGetFieldAssignments,
    noResults,
    pagination,
    paginationButtons,
    rowSelection,
    setPagination,
    setRowSelection,
    tableFooter,
  ]);

  return <>{table}</>;
};

export default React.memo(CombinedTable);
