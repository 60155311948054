import React, {useCallback, useEffect, useMemo} from 'react';

import {IconSVG} from 'components/atoms/IconSVG';
import {Tooltip} from 'components/atoms/Tooltip';
import {
  MaterialReactTable,
  MRT_Icons,
  MRT_RowData,
  MRT_TableOptions,
  MRT_TablePagination,
  useMaterialReactTable,
} from 'material-react-table';
import {useTranslations} from 'vidiemme/react-i18n';

import {StyleIconProps, TableProps} from './definitions';

import './table.scss';

const CustomTable = <TData extends MRT_RowData>({
  actions,
  tableOptions,
  setRowData,
  tableFooter,
  customBottomToolbar = false,
  bottomToolbarLeft,
  bottomToolbarRight,
  dependencyPagination,
  pageSize,
  currentPage,
  totalRows,
  hidePagination,
  resetColumnOrder,
}: TableProps<TData>) => {
  const {t} = useTranslations();

  const ACTIONS_ICO_SIZE: number = 22;
  //should be memoized or stable

  const icons = useMemo<Partial<MRT_Icons>>(
    () => ({
      ArrowDownwardIcon: (props: StyleIconProps) => (
        <IconSVG icon="angle-down" size={24} {...props} />
      ),
      SyncAltIcon: (props: StyleIconProps) => (
        <IconSVG icon="angle-down" size={24} {...props} />
      ),
    }),
    [],
  );

  const renderActions = useCallback(
    (row: TData) => {
      return (
        actions &&
        actions.map(({label, icon, onClick, condition}) => {
          if (!condition || row[condition]) {
            return (
              <>
                <Tooltip placement="top" key={`${row}`} title={label}>
                  <div>
                    <span
                      className="action-ico cursor-pointer"
                      onClick={() => {
                        setRowData && setRowData(row);
                        onClick && onClick(row);
                      }}
                      key={icon}>
                      <IconSVG key={icon} icon={icon} size={ACTIONS_ICO_SIZE} />
                    </span>
                  </div>
                </Tooltip>
              </>
            );
          }
        })
      );
    },
    [actions, setRowData],
  );

  const baseConfig = useMemo<Partial<MRT_TableOptions<TData>>>(
    () => ({
      enableRowActions: actions && actions.length > 0,
      enableTopToolbar: false,
      enableExpandAll: false,
      enableExpanding: false,
      manualPagination: true,
      manualSorting: true,
      icons: icons,
      layoutMode: 'grid',
      localization: {
        rowsPerPage: t('Table.rowsPerPage'),
        expand: '',
        select: '',
      },
      muiTablePaperProps: {
        className: 'shadow-none rounded-none',
      },
      muiSelectAllCheckboxProps: {
        sx: {
          svg: {
            path: {
              fill: '#D0B194',
            },
          },
        },
      },
      muiTableBodyRowProps: {
        sx: {
          '&:hover': {
            backgroundColor: 'red',
          },
          '.Mui-TableBodyCell-DetailPanel': {
            width: '100%',
            '.MuiCollapse-vertical': {
              width: '100%',
            },
          },
        },
      },
      muiTableBodyProps: {
        sx: {
          boxShadow: 'none',
        },
      },
      muiTableBodyCellProps: {
        sx: {
          borderBottom: '1px solid #D0B194', //add a border between columns
        },
      },
      muiTableHeadCellProps: {
        sx: {
          textTransform: 'uppercase',
          fontSize: 12,
        },
      },
      displayColumnDefOptions: {
        'mrt-row-actions': {
          size: actions && actions.length * 35, //make actions column wider
          muiTableHeadCellProps: {
            align: 'right', //change head cell props
          },
          muiTableBodyCellProps: {
            align: 'right', //change head cell props
          },
        },
        'mrt-row-select': {
          maxSize: 10,
          size: 10,
        },
        'mrt-row-expand': {
          muiTableBodyCellProps: {
            align: 'right', //change head cell props
          },
        },
      },
      paginationDisplayMode: 'pages',
      positionActionsColumn: 'last',
      positionExpandColumn: 'last',
      enableMultiSort: true,
      muiPaginationProps: {
        rowsPerPageOptions: [10, 25, 50, 75, 100],
        showRowsPerPage: true,
        rowNumberDisplayMode: 'static',
        className: 'custom-pagination',
        showFirstButton: false,
        showLastButton: false,
        SelectProps: {
          className: 'custom-perpage-select',
        },
      },
      enableBottomToolbar: false, //Hide it to be added manually
      muiTableContainerProps: {
        className: customBottomToolbar ? 'has-custom-toolbar' : '',
      },
      renderRowActions: ({row}) => {
        return (
          <div className="actions-ico-wrapper gap-2">
            {renderActions(row.original)}
          </div>
        );
      },
    }),
    [actions, customBottomToolbar, icons, renderActions, t],
  );

  const table = useMaterialReactTable({
    ...baseConfig,
    ...tableOptions,
  });
  useEffect(() => {
    if (tableOptions && resetColumnOrder) {
      table.resetColumnOrder(true);
    }
  }, [tableOptions, table, resetColumnOrder]);

  const customPaginationRow = useMemo(() => {
    if (!totalRows || hidePagination) {
      return <></>;
    }
    return (
      <div
        className={`custom-table-pagination 2xl:container px-5 fixed bg-white w-full left-[50%] transform translate-x-[-50%] ${
          tableFooter === undefined ? 'bottom-[0]' : 'bottom-[85px]'
        }  flex items-center z-10`}>
        <p className="text-primary-2">Page:</p>
        <MRT_TablePagination table={table} />
        <p className="whitespace-nowrap ml-2.5 text-primary-2">{`${
          pageSize * currentPage + 1
        } - ${Math.min(
          pageSize * (currentPage + 1),
          totalRows,
        )} of ${totalRows} elements`}</p>
      </div>
    );
  }, [currentPage, hidePagination, pageSize, table, tableFooter, totalRows]);

  return customBottomToolbar ? (
    <div className="flex-1 flex flex-col">
      <div className="flex-1">
        <MaterialReactTable table={table} />
      </div>
      <div className="sticky bottom-0 left-0 bg-[#ffffff] border-solid border-t border-primary-3 mt-10 pb-10">
        <div className="custom-table-pagination flex items-center pt-5">
          <p className="text-primary-2">Page:</p>
          <MRT_TablePagination table={table} />
          {pageSize && (
            <p className="whitespace-nowrap ml-2.5 text-primary-2">{`${
              pageSize * currentPage + 1
            } - ${Math.min(
              pageSize * (currentPage + 1),
              totalRows,
            )} of ${totalRows} elements`}</p>
          )}
        </div>
        {(bottomToolbarLeft || bottomToolbarRight) && (
          <div className="flex flex-row justify-between pt-5">
            {bottomToolbarLeft && (
              <div className="flex items-center gap-5">{bottomToolbarLeft}</div>
            )}
            {bottomToolbarRight && (
              <div className="flex items-center gap-5">
                {bottomToolbarRight}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className={`2xl:container mx-auto pb-40 ${
        tableFooter === undefined ? 'no-table-footer' : 'table-footer'
      } ${dependencyPagination !== undefined ? 'relative' : ''}`}>
      {dependencyPagination && dependencyPagination}
      <MaterialReactTable table={table} />
      {/*Added Pagination here to add text labels */}
      {customPaginationRow}
      {/*End addition*/}
      {totalRows !== undefined && tableFooter}
    </div>
  );
};

export default React.memo(CustomTable) as typeof CustomTable;
