import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {Box} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {Button} from 'components/atoms/Button';
import {Checkbox} from 'components/atoms/Checkbox';
import {IconSVG} from 'components/atoms/IconSVG';
import {RowInstance} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import GenericModal from 'components/organisms/GenericModal';
import UnexpectedValueModal from 'components/organisms/UnexpectedValueModal';
import {useFilterContext} from 'contexts';
import {usePageContext} from 'contexts/page/PageContext';
import {useUtils} from 'hooks/index';
import {MRT_RowSelectionState} from 'material-react-table';
import {useAssignments} from 'store/pageSwitch/assignments';
import {
  AssignmentsQueryKeys,
  GetAssigmentsFieldsResponse,
} from 'store/pageSwitch/assignments/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {CombinedTable} from '../CombinedTable';

import {
  IProps,
  PAGINATION_DEPENDENCY_LIMIT,
  VIEW_BY_COMBINED,
} from './definitions';
import '../assignments.scss';

const AssignmentsTableCombined = ({
  customizationId,
  currentPageId,
}: IProps): ReactElement => {
  const {t} = useTranslations();

  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });
  //Pagination param
  const [dependencyPagination, setDependencyPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: PAGINATION_DEPENDENCY_LIMIT,
  });

  const primaryColumnRef = useRef(null);
  const [primaryColumnWidth, setPrimaryColumnWidth] = useState(0);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [checkboxErrorModalOpen, setCheckboxErrorModalOpen] =
    useState<boolean>(false);
  const {currentPage, dependencyFieldName} = usePageContext();
  const {isEqualsArray, isSelectAll, hasSelectAllInDepencyArray} = useUtils();

  //Filters param
  const {filters} = useFilterContext();
  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

  const {useGetFieldsAssignments, patchAssigmentsAsync} = useAssignments();
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [selectionChanged, setSelectionChanged] = useState<boolean>(false);

  const {
    data: assignmentsList,
    isLoading: isLoadingGetFieldAssignments,
    refetch: refetchAssigmentsList,
  } = useGetFieldsAssignments({
    customizationId,
    currentPageId,
    primaryPage: pagination.pageIndex + 1,
    primaryLimit: pagination.pageSize,
    dependencyPage: dependencyPagination.pageIndex + 1,
    dependencyLimit: dependencyPagination.pageSize,
    viewBy: VIEW_BY_COMBINED,
    ...(paramFilters || {}),
  });
  const [dependencyPages, setDependencyPages] = useState<number>(0);

  const [assignmentsListCopy, setAssignmentsListCopy] =
    useState(assignmentsList);
  const [assignmentSeparator, setAssignmentSeparator] = useState<string>('-');
  const queryClient = useQueryClient();

  useEffect(() => {
    //TODO set from be
    setAssignmentSeparator('-');
  }, []);
  const {qsFilters} = useFilterContext();

  const noResults = useMemo(() => {
    return (
      (assignmentsListCopy &&
        assignmentsListCopy.data.primary.totalCount === 0) ||
      (assignmentsListCopy &&
        assignmentsListCopy.data.dependency.totalCount === 0)
    );
  }, [assignmentsListCopy]);

  //refetch when filters changes
  useEffect(() => {
    if (qsFilters === '') {
      refetchAssigmentsList();
    }
  }, [qsFilters, refetchAssigmentsList]);

  useEffect(() => {
    setAssignmentsListCopy(structuredClone(assignmentsList));
  }, [assignmentsList]);

  useLayoutEffect(() => {
    if (primaryColumnRef) {
      //TODO improve code
      setPrimaryColumnWidth(
        primaryColumnRef.current?.parentElement?.parentElement?.parentElement
          ?.parentElement?.offsetWidth,
      );
    }
  }, [primaryColumnRef]);

  useEffect(() => {
    const dependencyTotalCount =
      assignmentsListCopy?.data.dependency.totalCount;
    if (dependencyTotalCount) {
      setDependencyPages(dependencyTotalCount / PAGINATION_DEPENDENCY_LIMIT);
    }
  }, [assignmentsListCopy?.data.dependency.totalCount]);

  const checkPartialMatch = useCallback(
    (assignmentsList: Array<string>, currentDependencyId: string): boolean => {
      let isPartialMatch: boolean = false;
      assignmentsList &&
        assignmentsList.forEach(singleAssignment => {
          const splittedSeparator = singleAssignment.split(assignmentSeparator);
          const splittedDependencyId =
            currentDependencyId.split(assignmentSeparator);
          isPartialMatch =
            (splittedSeparator[0] === '*' &&
              splittedSeparator[1] === splittedDependencyId[1]) ||
            (splittedSeparator[1] === '*' &&
              splittedSeparator[0] === splittedDependencyId[0]);
        });
      return isPartialMatch;
    },
    [assignmentSeparator],
  );

  const checkSelectAllAssignments = useCallback(
    (currentAssignment: string) => {
      let onlyAsterisk = currentAssignment !== undefined;
      const splittedAssignment =
        currentAssignment && currentAssignment.split(assignmentSeparator);

      splittedAssignment &&
        splittedAssignment.forEach((singleSplittedAssignment: string) => {
          if (singleSplittedAssignment !== '*') {
            onlyAsterisk = false;
          }
        });
      return onlyAsterisk;
    },
    [assignmentSeparator],
  );

  const checkboxIsCheched = useCallback(
    (rowId: string, dependencyId: string) => {
      if (!assignmentsListCopy) {
        return false;
      }
      const currentAssigments = assignmentsListCopy.data?.assignments[rowId];

      const checkedPartialMatch = checkPartialMatch(
        currentAssigments,
        dependencyId,
      );

      const selectAll: boolean =
        currentAssigments && checkSelectAllAssignments(currentAssigments[0]);
      return (
        (currentAssigments &&
          (currentAssigments.indexOf(dependencyId) > -1 ||
            currentAssigments.indexOf('*') > -1)) ||
        checkedPartialMatch ||
        selectAll
      );
    },
    [assignmentsListCopy, checkPartialMatch, checkSelectAllAssignments],
  );

  const handleOnChangeCheckboxValue = useCallback(
    (
      rowId: string,
      dependencyId: string,
      value: ChangeEvent<HTMLInputElement>,
    ) => {
      const currentDependencyId = dependencyId;
      const currentPrimaryId = rowId;
      if (assignmentsListCopy) {
        const _tempAssigmentListCopy: GetAssigmentsFieldsResponse = {
          ...assignmentsListCopy,
        };
        if (value.target.checked) {
          //selectAll case
          if (isSelectAll(currentDependencyId)) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] = [
              currentDependencyId,
            ];
            //basic select case
          } else {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.assignments[currentPrimaryId].concat(
                currentDependencyId,
              );
          }
        } else {
          //unselect all checkbox if dependency id is *
          if (isSelectAll(dependencyId)) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] = [];
          }
          //if uncheck one elem, need to uncheck * checkbox
          if (
            hasSelectAllInDepencyArray(
              assignmentsListCopy?.data.assignments[currentPrimaryId],
            )
          ) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.dependency.values.map(
                singleElem => singleElem.id,
              );
          }
          _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
            assignmentsListCopy?.data.assignments[currentPrimaryId].filter(
              currentElem => {
                return (
                  currentElem !== currentDependencyId && currentElem !== '*'
                );
              },
            );
        }
        setAssignmentsListCopy(_tempAssigmentListCopy);
        setSelectionChanged(true);
      }
    },
    [assignmentsListCopy, isSelectAll, hasSelectAllInDepencyArray],
  );

  useEffect(() => {
    const assigments = assignmentsList?.data?.assignments;
    const assigmentsCopy = assignmentsListCopy?.data?.assignments;
    let selectionHasChanged: boolean = false;
    for (const singleAssignmentsKey in assigments) {
      if (assigmentsCopy) {
        if (
          !isEqualsArray(
            assigments[singleAssignmentsKey],
            assigmentsCopy[singleAssignmentsKey],
          )
        ) {
          selectionHasChanged = true;
        }
      }
    }
    setSelectionChanged(selectionHasChanged);
  }, [assignmentsList, assignmentsListCopy, isEqualsArray]);

  const handleOnChangeCheckboxHeaderValue = useCallback(
    (dependencyId: string, value: ChangeEvent<HTMLInputElement>) => {
      if (assignmentsListCopy) {
        const _tempAssigmentListCopy: GetAssigmentsFieldsResponse = {
          ...assignmentsListCopy,
        };
        if (value.target.checked) {
          Object.keys(_tempAssigmentListCopy.data.assignments).forEach(
            singleAssigmentListId => {
              const currentAssigmentList =
                _tempAssigmentListCopy.data.assignments[singleAssigmentListId];
              if (currentAssigmentList.indexOf('*') < 0) {
                _tempAssigmentListCopy.data.assignments[
                  singleAssigmentListId
                ].push(dependencyId);
              }
            },
          );
        } else {
          Object.keys(_tempAssigmentListCopy.data.assignments).forEach(
            singleAssigmentListId => {
              const currentAssigmentList =
                _tempAssigmentListCopy.data.assignments[singleAssigmentListId];
              const dependencyIdIndex =
                currentAssigmentList.indexOf(dependencyId);
              dependencyIdIndex > -1 &&
                _tempAssigmentListCopy.data.assignments[
                  singleAssigmentListId
                ].splice(dependencyIdIndex, 1);
            },
          );
        }
        setAssignmentsListCopy(_tempAssigmentListCopy);
      }
    },
    [assignmentsListCopy],
  );

  const checkboxIsCheckedHeader = useCallback(
    (dependencyId: string) => {
      let allCheckboxSelected: boolean = true;
      if (assignmentsListCopy) {
        for (const singleAssignmentId of Object.keys(
          assignmentsListCopy.data.assignments,
        )) {
          const currentAssignment =
            assignmentsListCopy.data.assignments[singleAssignmentId];
          if (
            currentAssignment.indexOf(dependencyId) < 0 &&
            currentAssignment.indexOf('*') < 0
          ) {
            allCheckboxSelected = false;
            break;
          }
        }
      }
      return allCheckboxSelected;
    },
    [assignmentsListCopy],
  );

  const checkIsErrorCheckbox = useCallback(
    (dependencyID: string, rowId: string, checked: boolean) => {
      const currentExpectedAssignments =
        assignmentsListCopy?.data.primary.values.find(singlePrimaryValue => {
          if (singlePrimaryValue.id === dependencyID) {
            return singlePrimaryValue;
          }
        });
      if (checked) {
        return (
          currentExpectedAssignments &&
          currentExpectedAssignments?.expectedAssignments?.indexOf(rowId) === -1
        );
      } else {
        return (
          currentExpectedAssignments &&
          currentExpectedAssignments?.expectedAssignments?.indexOf(rowId) > -1
        );
      }
    },
    [assignmentsListCopy?.data.primary.values],
  );

  const checkRowHasSelectAll = useCallback(
    (rowId: string) => {
      return (
        assignmentsListCopy &&
        assignmentsListCopy.data.assignments[rowId] &&
        isSelectAll(assignmentsListCopy.data.assignments[rowId][0])
      );
    },
    [assignmentsListCopy, isSelectAll],
  );

  const columns = useMemo(() => {
    return assignmentsListCopy?.data?.dependency?.values?.map(
      singleDependency => {
        return {
          accessorKey: singleDependency.id,
          header: singleDependency.id,
          size: 160,
          Cell: ({row}: RowInstance) => {
            const checked = checkboxIsCheched(row.id, singleDependency.id);
            const isErrorCheckbox = checkIsErrorCheckbox(
              row.id,
              singleDependency.id,
              checked,
            );
            if (isErrorCheckbox) {
              setCheckboxError(true);
            }

            return (
              <div>
                <Checkbox
                  checked={checked}
                  disabled={
                    checkRowHasSelectAll(row.id) &&
                    !isSelectAll(singleDependency.id)
                  }
                  color={isErrorCheckbox === true ? 'error' : 'primary'}
                  onChange={value =>
                    handleOnChangeCheckboxValue(
                      row.id,
                      singleDependency.id,
                      value,
                    )
                  }
                />
              </div>
            );
          },
          Header: (
            <TextWithRoundedImage
              key={singleDependency.id}
              image={singleDependency.picture}
              text={
                singleDependency.id === '*'
                  ? `${singleDependency.id} ${singleDependency.name}`
                  : `${singleDependency.id}`
              }
              variant={'small'}
              checkbox={
                singleDependency.id === '*' ? undefined : (
                  <Checkbox
                    disabled={noResults}
                    checked={checkboxIsCheckedHeader(singleDependency.id)}
                    onChange={value =>
                      handleOnChangeCheckboxHeaderValue(
                        singleDependency.id,
                        value,
                      )
                    }
                  />
                )
              }
            />
          ),
        };
      },
    );
  }, [
    assignmentsListCopy?.data?.dependency?.values,
    checkIsErrorCheckbox,
    checkRowHasSelectAll,
    checkboxIsCheched,
    checkboxIsCheckedHeader,
    handleOnChangeCheckboxHeaderValue,
    handleOnChangeCheckboxValue,
    isSelectAll,
    noResults,
  ]);

  const checkboxIsCheckedPrimary = useCallback(
    (primaryId: string) => {
      setTimeout(() => {
        if (assignmentsListCopy) {
          const currentAssigmnentsList =
            assignmentsListCopy.data.assignments[primaryId];
          const currentDependencyList =
            assignmentsListCopy.data.dependency.values;
          if (
            currentAssigmnentsList &&
            currentAssigmnentsList.indexOf('*') > 0
          ) {
            return false;
          }
          if (
            currentAssigmnentsList &&
            currentAssigmnentsList.indexOf('*') < 0
          ) {
            for (const singleDependency of currentDependencyList) {
              if (currentAssigmnentsList.indexOf(singleDependency.id) < 0) {
                return false;
              }
            }
          }

          return true;
        }
      });
    },
    [assignmentsListCopy],
  );

  const handleOnChangeCheckboxPrimary = useCallback(
    (primaryId: string, value: ChangeEvent<HTMLInputElement>) => {
      if (assignmentsListCopy) {
        const _tempAssigmentListCopy: GetAssigmentsFieldsResponse = {
          ...assignmentsListCopy,
        };
        const currentAssigmnentsList =
          assignmentsListCopy.data.assignments[primaryId];
        const currentDependencyList = assignmentsListCopy.data.dependency.values
          .map(singleDependency => {
            if (singleDependency.id !== '*') {
              return singleDependency.id;
            }
          })
          .filter(singleDependency => {
            return singleDependency !== undefined;
          });
        if (value.target.checked) {
          currentAssigmnentsList && currentAssigmnentsList.indexOf('*') < 0
            ? (_tempAssigmentListCopy.data.assignments[primaryId] = Array.from(
                assignmentsListCopy.data.assignments[primaryId].concat(
                  currentDependencyList,
                ),
              ))
            : '';
        } else {
          _tempAssigmentListCopy.data.assignments[primaryId] =
            currentAssigmnentsList.filter(singleAssignment => {
              return currentDependencyList.indexOf(singleAssignment) < 0;
            });
        }
        setAssignmentsListCopy(_tempAssigmentListCopy);
      }
    },
    [assignmentsListCopy],
  );

  const columnsWithPrimary = useCallback(() => {
    if (columns && columns[0] && columns[0].accessorKey === 'primary') {
      return;
    }
    columns?.unshift({
      accessorKey: 'primary',
      header: currentPage.id,
      size: 300,
      Cell: ({row}: RowInstance) => {
        return (
          <TextWithRoundedImage
            key={row.original.id}
            image={row.original.picture}
            text={`${row.original.id} ${row.original.name}`}
            variant={'small'}
            checkbox={
              <Checkbox
                checked={checkboxIsCheckedPrimary(row.original.id)}
                onChange={value =>
                  handleOnChangeCheckboxPrimary(row.original.id, value)
                }
              />
            }
          />
        );
      },
      Header:
        currentPage && dependencyFieldName ? (
          <p
            ref={
              primaryColumnRef
            }>{`${currentPage.name}/${dependencyFieldName}`}</p>
        ) : (
          <></>
        ),
    });
  }, [
    checkboxIsCheckedPrimary,
    columns,
    currentPage,
    dependencyFieldName,
    handleOnChangeCheckboxPrimary,
  ]);

  const handleSaveDependency = useCallback(
    async (checkboxErrorReviwed?: boolean) => {
      if (!assignmentsListCopy) {
        return;
      }
      if (checkboxError && !checkboxErrorReviwed) {
        setCheckboxErrorModalOpen(true);
        return;
      }

      try {
        await patchAssigmentsAsync(
          {
            viewBy: VIEW_BY_COMBINED,
            assignments: assignmentsListCopy.data.assignments,
            customizationId: customizationId,
            fieldId: currentPageId,
          },
          {
            onSuccess: () => {
              setSuccessModalOpen(true);
              queryClient.invalidateQueries({
                queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
                exact: false,
              });
            },
          },
        );
      } catch (e) {
        setErrorModalOpen(true);
      }
    },
    [
      assignmentsListCopy,
      checkboxError,
      currentPageId,
      customizationId,
      patchAssigmentsAsync,
      queryClient,
    ],
  );

  const tableFooter = useMemo(() => {
    if (noResults) {
      return <></>;
    }
    return (
      <div className="table-footer-buttons justify-between sm:px-5 2xl:px-0  flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full z-10">
        <div className="flex flex-col mt-1 pointer-events-none align-bottom py-5">
          <div className="flex items-center">
            <Checkbox className="py-0 pl-0" color={'error'} />
            <span className="font-bold uppercase">
              {t('Assignments.Combined.suggestedInfoCombination')}
            </span>
          </div>
          <div className="flex items-center">
            <Checkbox className="py-0 pl-0" checked={true} color={'error'} />
            <span className="font-bold uppercase">
              {t('Assignments.Combined.notExistCombination')}
            </span>
          </div>
        </div>

        <div className="flex py-5 justify-end">
          <Button
            onClick={() => {
              handleSaveDependency();
            }}
            disabled={!selectionChanged}
            iconLeft={'save'}
            variant={'primary'}>
            {t('Assignments.General.save')}
          </Button>
        </div>
      </div>
    );
  }, [handleSaveDependency, noResults, t, selectionChanged]);

  const paginationDependencyNavigate = useCallback(
    (direction: 'prev' | 'next') => {
      direction === 'next'
        ? setDependencyPagination({
            pageIndex: dependencyPagination.pageIndex + 1,
            pageSize: PAGINATION_DEPENDENCY_LIMIT,
          })
        : setDependencyPagination({
            pageIndex: dependencyPagination.pageIndex - 1,
            pageSize: PAGINATION_DEPENDENCY_LIMIT,
          });
    },
    [dependencyPagination.pageIndex],
  );

  //TODO move to atoms?
  const paginationButtons = useMemo(() => {
    return assignmentsListCopy &&
      assignmentsListCopy.data.dependency.totalCount >
        PAGINATION_DEPENDENCY_LIMIT ? (
      <Box
        className="dependency-pagination right-0 top-0
        flex flex-row justify-between h-auto absolute items-center  min-h-[50px] px-[15px]"
        sx={{width: `calc(100% - ${primaryColumnWidth}px)`}}>
        <button
          className="navigation-button z-10"
          onClick={() => paginationDependencyNavigate('prev')}
          disabled={dependencyPagination.pageIndex === 0}>
          <IconSVG icon="arrow-back" size={24} />
        </button>
        <button
          className="navigation-button z-10"
          onClick={() => paginationDependencyNavigate('next')}
          disabled={dependencyPagination.pageIndex > dependencyPages - 1}>
          <IconSVG icon="arrow-forward" size={24} />
        </button>
      </Box>
    ) : (
      <></>
    );
  }, [
    assignmentsListCopy,
    dependencyPages,
    dependencyPagination.pageIndex,
    paginationDependencyNavigate,
    primaryColumnWidth,
  ]);

  const memoTable = useMemo(() => {
    return (
      <CombinedTable
        pagination={pagination}
        assignmentsListCopy={assignmentsListCopy}
        tableFooter={tableFooter}
        paginationButtons={paginationButtons}
        noResults={noResults}
        columns={columns}
        isLoadingGetFieldAssignments={isLoadingGetFieldAssignments}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        setPagination={setPagination}
      />
    );
  }, [
    assignmentsListCopy,
    columns,
    isLoadingGetFieldAssignments,
    noResults,
    pagination,
    paginationButtons,
    rowSelection,
    tableFooter,
  ]);

  const table = useMemo(() => {
    columnsWithPrimary();
    return assignmentsListCopy && columns && <>{memoTable}</>;
  }, [assignmentsListCopy, columns, columnsWithPrimary, memoTable]);

  const modals = useMemo(() => {
    return (
      assignmentsListCopy && (
        <>
          {/* Success Modal*/}
          <GenericModal
            onClose={() => setSuccessModalOpen(false)}
            open={successModalOpen}
            content={t('General.successModal.actionsCorrectlySaved')}
            iconName={'save'}
          />
          {/* Error Modal*/}
          <GenericModal
            onClose={() => setErrorModalOpen(false)}
            open={errorModalOpen}
            content={t('General.errorModal.message')}
            iconName={'error'}
          />
          <UnexpectedValueModal
            onClose={() => {
              setCheckboxErrorModalOpen(false);
            }}
            open={checkboxErrorModalOpen}
            onIgnore={() => {
              setCheckboxErrorModalOpen(false);
              handleSaveDependency(true);
            }}
          />
        </>
      )
    );
  }, [
    assignmentsListCopy,
    checkboxErrorModalOpen,
    errorModalOpen,
    handleSaveDependency,
    successModalOpen,
    t,
  ]);

  return (
    <>
      {table}
      {modals}
    </>
  );
};

export default React.memo(AssignmentsTableCombined);
