export enum SwatchesQueryKeys {
  GET_SWATCHES_QUERY_KEY = 'GET_SWATCHES_QUERY_KEY',
}

export interface PrimarySwatchValues {
  name: string;
  id: string;
  fallback: boolean;
  picture?: string;
}

export interface DependencySwatchValues {
  name: string;
  id: string;
}

export interface DependencySwatchValuesExtended extends DependencySwatchValues {
  picture?: string;
  rowId: string;
}

export interface SwatchWithDependency {
  [key: PrimarySwatchValues['id']]: {
    [key: DependencySwatchValues['id']]: string;
  };
}

export interface SwatchNoDependency {
  totalCount: number;
  values: Array<PrimarySwatchValues>;
}

export type SwatchValuesUnion = SwatchWithDependency | SwatchNoDependency;

export type GetSwatchesParamsOptions = {
  primaryId?: number;
  primaryName?: string;
  dependencyId?: string;
  dependencyName?: string;
  primaryPage?: number;
  primaryLimit?: number;
  dependencyPage?: number;
  dependencyLimit?: number;
  primarySortBy?: 'id' | 'name';
  primaryOrderBy?: 'ASC' | 'DESC';
  dependencySortBy?: 'id' | 'name';
  dependencyOrderBy?: 'ASC' | 'DESC';
  includePrimary?: boolean;
  includeDependency?: boolean;
  includeSwatches?: boolean;
};

export type GetSwatchesParams = {
  customizationId: string;
  currentPageId: string;
  options?: GetSwatchesParamsOptions;
};

export type GetSwatchesResponse<T> = {
  data: {
    primary?: {
      totalCount: number;
      values: Array<PrimarySwatchValues>;
    };
    dependency?: {
      totalCount: number;
      values: Array<DependencySwatchValues>;
    };
    swatches: T;
  };
};

export type UploadSwatchParams = {
  customizationId: string;
  currentPageId: string;
  valueId: string;
  file: File;
  dependencyId?: string;
};
