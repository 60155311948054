import {useCallback} from 'react';

import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks/index';

import {useAPI} from '../../api';

import {
  AssignmentsQueryKeys,
  CopyConfigAssigmentParams,
  GetAssigmentsFieldsResponse,
  GetFieldsAssignmentsParams,
  PatchAssigmentParams,
} from './definitions';

export const useAssignments = () => {
  const {api} = useAPI();
  const {customError} = useUtils();

  const getFieldsAssignments = useCallback(
    async ({
      customizationId,
      fieldId,
      ...params
    }: GetFieldsAssignmentsParams) => {
      try {
        const {data} = await api.get<GetAssigmentsFieldsResponse>(
          `/api/v1/customizations/${customizationId}/fields/${fieldId}/assignments`,
          {params},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const useGetFieldsAssignments = ({...params}) =>
    useQuery({
      queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY, {params}],
      queryFn: async () =>
        getFieldsAssignments({
          customizationId: params.customizationId,
          fieldId: params.currentPageId,
          ...params,
        }),
    });

  const patchAssigments = useCallback(
    async ({customizationId, fieldId, ...params}: PatchAssigmentParams) => {
      try {
        const {data} = await api.patch(
          `/api/v1/customizations/${customizationId}/fields/${fieldId}/assignments`,
          {
            ...params,
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const copyConfigAssignments = useCallback(
    async ({
      customizationId,
      fieldId,
      ...params
    }: CopyConfigAssigmentParams) => {
      try {
        const {data} = await api.put(
          `/api/v1/customizations/${customizationId}/fields/${fieldId}/assignments`,
          {
            ...params,
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  //Mutations
  const {mutateAsync: patchAssigmentsAsync} = useMutation({
    mutationFn: patchAssigments,
  });
  const {mutateAsync: copyConfigAssignmentsAsync} = useMutation({
    mutationFn: copyConfigAssignments,
  });

  return {
    useGetFieldsAssignments,
    patchAssigmentsAsync,
    copyConfigAssignmentsAsync,
  };
};
