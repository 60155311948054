import React, {useMemo} from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {IconSVG} from 'components/atoms/IconSVG';

import {CustomTabsProps, TabPanelMap} from './definitions';

import './page-content-tabs.scss';

const PageContentTabs = ({
  tabKeyList,
  tabPanelMap,
  labelInfo,
  value,
  onChange,
}: CustomTabsProps) => {
  const selectedTabKey = useMemo(() => {
    return tabKeyList[value];
  }, [tabKeyList, value]);

  const TabContent = useMemo(() => {
    const tabKeyList = Object.keys(tabPanelMap) as (keyof TabPanelMap)[];

    return tabKeyList.map(tabKey => {
      const isActive = tabKey === selectedTabKey;

      return (
        <div
          key={tabKey}
          id={`tabpanel-${tabKey}`}
          aria-labelledby={`tab-${tabKey}`}
          role="tabpanel"
          hidden={!isActive}>
          {isActive && <div>{tabPanelMap[tabKey]}</div>}
        </div>
      );
    });
  }, [selectedTabKey, tabPanelMap]);

  const TabList = useMemo(() => {
    return (
      <Tabs value={value} onChange={onChange} aria-label="tabs">
        {tabKeyList.map(tabKey => {
          return (
            <Tab
              key={tabKey}
              label={tabKey}
              id={`tab-${tabKey}`}
              aria-controls={`tabpanel-${tabKey}`}
              classes={{
                root: 'px-2.5 py-[25px] font-bold',
              }}
            />
          );
        })}
      </Tabs>
    );
  }, [onChange, tabKeyList, value]);

  return (
    <>
      <div className="wrapper-with-border -mx-5">
        <div className="2xl:container mx-auto flex items-center justify-between sm:px-5 2xl:px-0">
          {TabList}
          <div className="inline-flex items-center gap-2.5">
            <IconSVG icon="info" size={24} />
            <p>{labelInfo}</p>
          </div>
        </div>
      </div>
      <div>{TabContent}</div>
    </>
  );
};

export default React.memo(PageContentTabs);
