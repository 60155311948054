import React, {useMemo} from 'react';

import {Button} from 'components/atoms/Button';
import {ButtonProps} from 'components/atoms/Button/definitions';
import {CirclePreview} from 'components/molecules/CirclePreview';

import {PickerWithPreviewProps} from './definitions';

const PickerWithPreview = ({
  variant,
  previewLabel,
  buttonLabel,
  onChange,
  allowedFiles,
  name,
  type,
  value,
}: PickerWithPreviewProps) => {
  const preview = useMemo(() => {
    switch (variant) {
      case 'image':
        return (
          <CirclePreview variant="image" value={value} label={previewLabel} />
        );
      case 'color':
        return (
          <CirclePreview variant="color" value={value} label={previewLabel} />
        );
      case 'name':
      default:
        return (
          <p className="uppercase text-small text-primary-main">{value}</p>
        );
    }
  }, [variant, value, previewLabel]);

  const buttonProps = useMemo<Partial<ButtonProps>>(() => {
    let props;

    if (type === 'file') {
      props = {
        pickerInputProps: {
          accept: allowedFiles !== undefined ? allowedFiles : '*',
        },
      };
    }

    props = {
      ...props,
      picker: type,
      pickerInputProps: {
        ...props?.pickerInputProps,
        onChange,
        name,
      },
    };

    return props;
  }, [allowedFiles, onChange, name, type]);

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex items-center gap-2.5">{preview}</div>

      <Button variant="tertiary" iconLeft="add" {...buttonProps}>
        {buttonLabel}
      </Button>
    </div>
  );
};

export default React.memo(PickerWithPreview);
