import React, {useCallback} from 'react';

import {Stack} from '@mui/material';
import {IconSVG} from 'components/atoms/IconSVG';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {useUtils} from 'hooks';
import {DependencySwatchValuesExtended} from 'store/pageSwitch/swatches/definitions';

import {DetailPanelSwatchesProps} from './definitions';

const DetailPanelSwatches = ({
  panelData,
  panelPagination,
  handleUpload,
}: DetailPanelSwatchesProps) => {
  const {uploadOnClick} = useUtils();

  const handleClickUpload = useCallback(
    async (dep: DependencySwatchValuesExtended) => {
      const file = await uploadOnClick('image/png,image/jpeg');
      if (file) {
        await handleUpload({
          valueId: dep?.rowId,
          dependencyId: dep?.id,
          file,
        });
      }
    },
    [handleUpload, uploadOnClick],
  );

  return (
    <div
      className={
        panelPagination &&
        'details-panel-pagination grid grid-cols-[max-content,1fr,max-content] gap-1 align-center'
      }>
      {panelPagination && (
        <button
          onClick={() => panelPagination.paginationCallback('prev')}
          disabled={!panelPagination.enablePrev}>
          <IconSVG icon="arrow-back" size={24} />
        </button>
      )}
      <div className="grid grid-cols-4 gap-4 detail-panel">
        {panelData?.map(dep => {
          return (
            <Stack
              key={dep?.id}
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <TextWithRoundedImage
                image={dep?.picture}
                text={`${dep?.id} ${dep?.name}`}
              />
              <div onClick={() => handleClickUpload(dep)}>
                <IconSVG icon="upload" size={22} className="cursor-pointer" />
              </div>
            </Stack>
          );
        })}
      </div>
      {panelPagination && (
        <button
          onClick={() => panelPagination.paginationCallback('next')}
          disabled={!panelPagination.enableNext}>
          <IconSVG icon="arrow-forward" size={24} />
        </button>
      )}
    </div>
  );
};

export default React.memo(DetailPanelSwatches);
