import React from 'react';

import {Tooltip as MuiTooltip} from '@mui/material';

import {ITooltip} from './interfaces';

const Tooltip = ({disabled, ...props}: ITooltip) => {
  return (
    <>
      {disabled ? (
        props.children
      ) : (
        <MuiTooltip
          {...props}
          placement="top"
          arrow
          classes={{
            tooltip: 'bg-primary-main p-[15px] text-regular font-bold',
            arrow: 'text-primary-main',
          }}
        />
      )}
    </>
  );
};

export default React.memo(Tooltip);
