import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {IProviderProps} from 'contexts/api/type';

const CACHE_TIME_MINUTES = 10;
const STALE_TIME_MINUTES = 5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME_MINUTES * 60 * 1000,
      gcTime: CACHE_TIME_MINUTES * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    mutations: {
      gcTime: CACHE_TIME_MINUTES * 60 * 1000,
    },
  },
});

const ApiProvider = ({children}: IProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
export default ApiProvider;
