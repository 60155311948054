import React from 'react';

import {DialogProps} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {ButtonType} from 'components/atoms/Button/definitions';
import {IconNames} from 'components/atoms/IconSVG/interfaces';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

const GenericModal: React.FC<
  DialogProps & {
    onClose: () => void;
    iconName: IconNames;
    content: string;
    buttonVariant?: ButtonType;
  }
> = ({onClose, buttonVariant = 'primary', ...props}) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon={props.iconName}
      content={props.content}
      actions={[
        <Button variant={buttonVariant} key={buttonVariant} onClick={onClose}>
          {t('Models.successModal.confirm')}
        </Button>,
      ]}
    />
  );
};

export default React.memo(GenericModal);
