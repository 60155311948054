import React from 'react';

import {Button} from 'components/atoms/Button';

import {SidebarButtonsProps} from './definitions';

const SidebarButtons = ({
  leftButtonProps,
  leftButtonLabel,
  rightButtonProps,
  rightButtonLabel,
}: SidebarButtonsProps) => {
  return (
    <div className="flex gap-2.5">
      <Button
        classNames="flex-1"
        variant="secondary"
        iconColor="black"
        iconSize={24}
        {...leftButtonProps}>
        {leftButtonLabel}
      </Button>
      <Button
        classNames="flex-1"
        variant="primary"
        iconColor="white"
        iconSize={24}
        {...rightButtonProps}>
        {rightButtonLabel}
      </Button>
    </div>
  );
};

export default React.memo(SidebarButtons);
