import React, {ReactElement, useCallback, useMemo} from 'react';

import {Sidebar} from 'components/organisms/Sidebar';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslations} from 'vidiemme/react-i18n';

import {ModelForm} from '../ModelForm';
import {ModelFormFields} from '../ModelForm/definitions';

import {ModelsSidebarProps} from './definitions';

const ModelsSidebar = ({
  open,
  onClose,
  initialData,
  onUpdateValue,
  onDeleteValue,
}: ModelsSidebarProps): ReactElement => {
  const {t} = useTranslations();

  const formMethods = useForm<ModelFormFields>({
    defaultValues: {
      name: '',
      deliveryDate: '',
      description: '',
    },
    values: initialData,
  });

  const {
    formState: {isValid, isSubmitting},
    handleSubmit,
  } = useMemo(() => {
    return formMethods;
  }, [formMethods]);

  const onSubmit = useCallback<SubmitHandler<ModelFormFields>>(
    data => {
      onUpdateValue?.(data);
    },
    [onUpdateValue],
  );

  return (
    <Sidebar
      open={open}
      onClose={onClose}
      actions={{
        leftButtonLabel: t('ModelsSidebar.actions.delete'),
        leftButtonProps: {
          iconLeft: 'delete',
          onClick: onDeleteValue,
        },
        rightButtonLabel: t('ModelsSidebar.actions.save'),
        rightButtonProps: {
          iconLeft: 'save',
          disabled: !isValid || isSubmitting,
          onClick: handleSubmit(onSubmit),
        },
      }}>
      <FormProvider {...formMethods}>
        <ModelForm />
      </FormProvider>
    </Sidebar>
  );
};

export default React.memo(ModelsSidebar);
