import React, {useCallback, useMemo, useState} from 'react';

import {Button} from 'components/atoms/Button';
import {Filters} from 'components/molecules/Filters';
import {Table} from 'components/molecules/Table';
import {useFilterContext} from 'contexts';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {usePageValues} from 'store/pageSwitch/values';
import {CatalogValueItem} from 'store/pageSwitch/values/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import GenericModal from '../GenericModal';

import {AddFromCatalogTableProps} from './definitions';

const AddFromCatalogTable = ({
  customizationId,
  currentPageId,
  onCancel,
}: AddFromCatalogTableProps) => {
  const {t} = useTranslations();

  const {
    useGetValuesFromCatalog,
    callAddValuesFromCatalog,
    getFlattenedValuesFromCatalog,
  } = usePageValues();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const {filters} = useFilterContext();

  const paramFilters = useMemo<FiltersParams>(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  const paramSorting = useMemo<{
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
  }>(() => {
    const sortingParam = sorting.length > 0 && sorting[0];
    if (sortingParam) {
      return {
        sortBy: sortingParam.id,
        orderBy: sortingParam.desc ? 'DESC' : 'ASC',
      };
    } else {
      return {};
    }
  }, [sorting]);

  const {data: valuesFromCatalog} = useGetValuesFromCatalog({
    customizationId,
    currentPageId,
    options: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
      ...paramSorting,
      ...paramFilters,
    },
  });

  const filterList = useMemo(() => {
    return [
      {
        id: 'id',
        name: 'id',
        label: t('Filters.id'),
      },
      {
        id: 'name',
        name: 'name',
        label: t('Filters.name'),
        maxi: true,
      },
    ];
  }, [t]);

  const columns = useMemo<MRT_ColumnDef<CatalogValueItem>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('ValuesFromCatalog.table.id'),
      },
      {
        accessorKey: 'name',
        header: t('ValuesFromCatalog.table.name'),
      },
    ],
    [t],
  );

  const selectedRowIds = useMemo(() => {
    return Object.keys(rowSelection).filter(k => rowSelection[k]);
  }, [rowSelection]);

  const handleAdd = useCallback(() => {
    const flattenedValuesFromCatalog = getFlattenedValuesFromCatalog({
      customizationId,
      currentPageId,
    });

    const matchingValues = flattenedValuesFromCatalog.filter(item =>
      selectedRowIds.includes(item.id),
    );

    callAddValuesFromCatalog(
      {
        customizationId,
        currentPageId,
        params: {
          values: matchingValues,
        },
      },
      {
        onSuccess: () => {
          setSuccessModalOpen(true);
        },
      },
    );
  }, [
    callAddValuesFromCatalog,
    currentPageId,
    customizationId,
    getFlattenedValuesFromCatalog,
    selectedRowIds,
  ]);

  const successModal = useMemo(() => {
    return (
      <GenericModal
        onClose={() => {
          setSuccessModalOpen(false);
          onCancel();
        }}
        open={successModalOpen}
        content={t('General.successModal.actionsCorrectlySaved')}
        iconName={'save'}
      />
    );
  }, [onCancel, successModalOpen, t]);

  return (
    <>
      <div className="px-10 pt-10 flex flex-col items-stretch h-[100%]">
        <p className="text-big font-bold uppercase">
          {t('ValuesFromCatalog.title')}
        </p>
        <div className="py-5">
          <Filters filterList={filterList} />
        </div>
        {valuesFromCatalog?.data.results && (
          <Table
            pageSize={pagination.pageSize}
            currentPage={pagination.pageIndex}
            totalRows={valuesFromCatalog.data.totalCount}
            tableOptions={{
              getRowId: originalRow => originalRow.id,
              enableRowSelection: true,
              columns,
              rowCount: valuesFromCatalog.data.totalCount,
              data: valuesFromCatalog.data.results,
              initialState: {pagination},
              state: {
                sorting,
                rowSelection,
                pagination,
              },
              onSortingChange: setSorting,
              onRowSelectionChange: setRowSelection,
              onPaginationChange: setPagination,
            }}
            customBottomToolbar
            bottomToolbarLeft={
              <Button variant="tertiary" onClick={onCancel}>
                {t('ValuesFromCatalog.actions.cancel')}
              </Button>
            }
            bottomToolbarRight={
              <Button
                variant="primary"
                iconLeft="add"
                onClick={handleAdd}
                disabled={selectedRowIds.length === 0}
                count={selectedRowIds.length}>
                {t('ValuesFromCatalog.actions.add')}
              </Button>
            }
          />
        )}
      </div>
      {successModal}
    </>
  );
};

export default React.memo(AddFromCatalogTable);
