import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {IconButton} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {IconSVG} from 'components/atoms/IconSVG';
import {IconNames} from 'components/atoms/IconSVG/interfaces';
import {useFilterContext} from 'contexts';
import {AuthRoutes, useNavigation} from 'navigation';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslations} from 'vidiemme/react-i18n';

import {DinamicNavigationElem, MainNavigationProps} from './definitions';

import './mainNavigation.scss';

const MainNavigation = ({
  navigationElements,
  dinamicNavigationElements,
}: MainNavigationProps): ReactElement => {
  const {t} = useTranslations();
  const navigate = useNavigate();
  const {goToPathWithParams} = useNavigation();
  const {setPageId, currentPageId, setPageName} = useFilterContext();
  const {customizationId} = useParams();

  const currentNavigationElements = useMemo(() => {
    return navigationElements || dinamicNavigationElements;
  }, [dinamicNavigationElements, navigationElements]);

  const activeIndex = useMemo(() => {
    if (!currentNavigationElements) {
      return 0;
    }

    const foundIndex = currentNavigationElements.findIndex(
      singleNavigationElem => {
        return (
          'isActive' in singleNavigationElem && singleNavigationElem.isActive
        );
      },
    );

    return foundIndex === -1 ? 0 : foundIndex;
  }, [currentNavigationElements]);

  useEffect(() => {
    if (currentPageId === '') {
      const firstConfigurable =
        dinamicNavigationElements &&
        dinamicNavigationElements.find((singleElem: DinamicNavigationElem) => {
          return singleElem.configurable;
        });
      firstConfigurable && setPageId(firstConfigurable?.id);
      firstConfigurable && setPageName(firstConfigurable?.name);
      setValue(0);
    }
  }, [currentPageId, dinamicNavigationElements, setPageId, setPageName]);

  const [value, setValue] = useState<number>(activeIndex);

  useEffect(() => {
    setValue(activeIndex);
  }, [activeIndex]);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  const dinamicTab = useCallback(
    (singleNavigationElem: DinamicNavigationElem) => {
      return (
        <Tab
          key={singleNavigationElem.id}
          className={`navigation-elem inline-flex relative text-center cursor-pointer first:ml-5 first:2xl:ml-0 ${
            singleNavigationElem.id === currentPageId
              ? 'navigation-elem-active'
              : ''
          }`}
          component={'div'}
          onClick={() => {
            setPageId(singleNavigationElem.id);
            setPageName(singleNavigationElem.name);
            goToPathWithParams(AuthRoutes.PAGES_SWITCH, {customizationId});
          }}
          label={singleNavigationElem.name}
        />
      );
    },
    [
      currentPageId,
      customizationId,
      goToPathWithParams,
      setPageId,
      setPageName,
    ],
  );

  const TabList = useMemo(() => {
    return (
      currentNavigationElements &&
      currentNavigationElements.map((singleNavigationElem, index) => {
        if ('label' in singleNavigationElem && singleNavigationElem.label) {
          return (
            <Tab
              key={
                singleNavigationElem.url || singleNavigationElem.label + index
              }
              className={`navigation-elem inline-flex relative text-center cursor-pointer first:ml-5 first:2xl:ml-0 ${
                singleNavigationElem.isActive ? 'navigation-elem-active' : ''
              }`}
              component={'div'}
              onClick={() =>
                singleNavigationElem.url !== undefined &&
                navigate(singleNavigationElem.url)
              }
              label={singleNavigationElem.label}
            />
          );
        } else if (
          'id' in singleNavigationElem &&
          singleNavigationElem.id &&
          singleNavigationElem.configurable
        ) {
          return dinamicTab(singleNavigationElem);
        }
      })
    );
  }, [currentNavigationElements, dinamicTab, navigate]);

  const navigationIcon = useCallback((icon: IconNames, className: string) => {
    return (
      <div className={`navigation-icon ${className}`}>
        <IconSVG icon={icon} size={30} />
      </div>
    );
  }, []);

  const TabsComponent = useMemo(() => {
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={props => {
          const disabledClass = props.disabled ? 'disabled' : '';
          if (props.direction === 'left') {
            return (
              <IconButton disableRipple {...props}>
                {navigationIcon('arrow-back', disabledClass)}
              </IconButton>
            );
          } else if (props.direction === 'right') {
            return (
              <IconButton disableRipple {...props}>
                {navigationIcon('arrow-forward', disabledClass)}
              </IconButton>
            );
          } else {
            return null;
          }
        }}
        aria-label={t('Accessibility.mainNavigation')}>
        {TabList}
      </Tabs>
    );
  }, [handleChange, navigationIcon, TabList, t, value]);

  return (
    <div className="main-navigation-container -mx-5">
      <div className="2xl:container mx-auto">{TabsComponent}</div>
    </div>
  );
};

export default React.memo(MainNavigation);
