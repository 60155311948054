import React, {useEffect, useMemo, useState} from 'react';

import {Filters} from 'components/molecules/Filters';
import {Table} from 'components/molecules/Table';
import {RowAction} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {useUtils} from 'hooks';
import {MRT_ColumnDef} from 'material-react-table';
import {
  GetSwatchesResponse,
  PrimarySwatchValues,
  SwatchNoDependency,
} from 'store/pageSwitch/swatches/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {SwatchesTableProps} from './definitions';

const SwatchesTable = ({
  values,
  setPrimaryPagination,
  primaryPagination,
  setSorting,
  sorting,
  isFetching,
  isUploading,
  handleUpload,
}: SwatchesTableProps) => {
  const {t} = useTranslations();
  const {uploadOnClick} = useUtils();

  const [valuesCopy, setValuesCopy] =
    useState<GetSwatchesResponse<SwatchNoDependency>>();

  useEffect(() => {
    setValuesCopy(structuredClone(values));
  }, [values]);

  useEffect(() => {
    if (values?.data.primary && values?.data?.dependency?.totalCount == 0) {
      values.data.primary.values = [];
      values.data.primary.totalCount = 0;
    }
    setValuesCopy(values);
  }, [values, values?.data?.dependency?.totalCount, values?.data.primary]);

  const columns = useMemo<MRT_ColumnDef<PrimarySwatchValues>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('Values.table.id'),
      },
      {
        accessorKey: 'name',
        header: t('Values.table.name'),
        Cell: ({cell}) => {
          return cell.row.original.picture ? (
            <TextWithRoundedImage
              image={cell.row.original.picture}
              text={cell.getValue<string>()}
            />
          ) : (
            <p>{cell.getValue<string>()}</p>
          );
        },
      },
    ],
    [t],
  );

  const actions = useMemo<RowAction<PrimarySwatchValues>[]>(() => {
    return [
      {
        label: t('Table.upload'),
        icon: 'upload',
        onClick: async row => {
          const file = await uploadOnClick('image/png,image/jpeg');
          if (file) {
            await handleUpload({
              valueId: row?.id,
              file,
            });
          }
        },
      },
    ];
  }, [handleUpload, t, uploadOnClick]);

  useEffect(() => {
    if (values?.data?.dependency?.totalCount === 0) {
      values.data.swatches.totalCount = 0;
      values.data.swatches.values = [];
    }
    setValuesCopy(values);
  }, [values, values?.data?.dependency?.totalCount, values?.data.swatches]);

  const memoizedTable = useMemo(() => {
    return (
      valuesCopy?.data.swatches && (
        <Table
          pageSize={primaryPagination.pageSize}
          currentPage={primaryPagination.pageIndex}
          totalRows={valuesCopy.data.swatches.totalCount}
          actions={actions}
          tableOptions={{
            getRowId: originalRow => originalRow.id,
            enableRowSelection: false,
            columns,
            rowCount: valuesCopy.data.swatches.totalCount,
            data: valuesCopy.data.swatches.values,
            initialState: {pagination: primaryPagination},
            state: {
              sorting,
              pagination: primaryPagination,
              showLoadingOverlay: isFetching || isUploading,
            },
            onSortingChange: setSorting,
            onPaginationChange: setPrimaryPagination,
          }}
        />
      )
    );
  }, [
    actions,
    columns,
    isFetching,
    isUploading,
    primaryPagination,
    setPrimaryPagination,
    setSorting,
    sorting,
    valuesCopy?.data.swatches,
  ]);

  return (
    <>
      <div className="2xl:container mx-auto py-5">
        <Filters
          filterList={[
            {
              id: 'primaryId',
              name: 'primaryId',
              label: t('Filters.id'),
            },
            {
              id: 'primaryName',
              name: 'primaryName',
              label: t('Filters.name'),
              maxi: true,
            },
          ]}></Filters>
      </div>
      {memoizedTable}
    </>
  );
};

export default React.memo(SwatchesTable);
