import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Table} from 'components/molecules/Table';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {PanelElem} from 'components/organisms/DetailPanelAssignments/definitions';
import DetailPanelAssignments from 'components/organisms/DetailPanelAssignments/DetailPanelAssignments';
import GenericModal from 'components/organisms/GenericModal';
import UnexpectedValueModal from 'components/organisms/UnexpectedValueModal';
import {useFilterContext} from 'contexts';
import {useUtils} from 'hooks';
import {
  MRT_Row,
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {useAssignments} from 'store/pageSwitch/assignments';
import {
  AssignmentsQueryKeys,
  DependencyValues,
  GetAssigmentsFieldsResponse,
} from 'store/pageSwitch/assignments/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {CopyConfigSidebar} from '../../CopyConfigSidebar';
import SaveConfirmModal from '../../SaveConfirmModal';

import {
  IProps,
  PAGINATION_DEPENDENCY_LIMIT,
  VIEW_BY_PRIMARY,
} from './definitions';
import '../assignments.scss';

const AssignmentsTablePrimary = ({
  customizationId,
  currentPageId,
  currentPageName,
}: IProps): ReactElement => {
  const {t} = useTranslations();

  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });

  const initialDependencyPagination = useMemo(() => {
    return {
      pageIndex: 0,
      pageSize: PAGINATION_DEPENDENCY_LIMIT,
    };
  }, []);
  //Pagination param
  const [dependencyPagination, setDependencyPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>(initialDependencyPagination);

  //Filters param
  const {filters} = useFilterContext();
  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

  //Sorting params
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [paramSorting, setParamSorting] = useState<{
    primaryOrderBy: 'ASC' | 'DESC';
  }>();

  const {useGetFieldsAssignments, patchAssigmentsAsync} = useAssignments();
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [checkboxErrorModalOpen, setCheckboxErrorModalOpen] =
    useState<boolean>(false);
  const [saveConfirmOpen, setSaveConfirmOpen] = useState<boolean>(false);
  const [currentRowId, setCurrentRowId] = useState<string>('');
  const [previousOpenedRowId, setPreviousOpenedRowId] = useState<string>('');
  const {data: assignmentsList} = useGetFieldsAssignments({
    customizationId,
    currentPageId,
    primaryPage: pagination.pageIndex + 1,
    primaryLimit: pagination.pageSize,
    dependencyPage: dependencyPagination.pageIndex + 1,
    dependencyLimit: dependencyPagination.pageSize,
    viewBy: VIEW_BY_PRIMARY,
    ...paramSorting,
    ...(paramFilters || {}),
  });
  const [expandedRowDetails, setExpandedRowDetails] = useState<{
    expanded: {[id: number]: boolean};
  }>();
  const {isEqualsArray, isSelectAll, hasSelectAllInDepencyArray} = useUtils();
  const [currentExpandedRow, setCurrentExpandedRow] = useState<string>();
  const [dependencyPages, setDependencyPages] = useState<number>(0);

  const [assignmentsListCopy, setAssignmentsListCopy] =
    useState(assignmentsList);
  const [changedSelection, setChangedSelection] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [assignmentSeparator, setAssignmentSeparator] = useState<string>('-');
  const [copySidebarOpen, setCopySidebarOpen] = useState<boolean>(false);
  const [copySidebarStatus, setCopySidebarStatus] = useState<'FROM' | 'TO'>(
    'FROM',
  );
  const [currentCopyRow, setCurrentCopyRow] = useState<MRT_Row<MRT_RowData>>();
  useEffect(() => {
    setAssignmentSeparator('-');
  }, []);

  useEffect(() => {
    setAssignmentsListCopy(structuredClone(assignmentsList));
  }, [assignmentsList]);

  useEffect(() => {
    if (currentExpandedRow) {
      setExpandedRowDetails({
        expanded: {
          [currentExpandedRow]: true,
        },
      });
    }
  }, [assignmentsListCopy?.data.assignments, currentExpandedRow]);

  useEffect(() => {
    const dependencyTotalCount =
      assignmentsListCopy?.data.dependency.totalCount;
    if (dependencyTotalCount) {
      setDependencyPages(dependencyTotalCount / PAGINATION_DEPENDENCY_LIMIT);
    }
  }, [assignmentsListCopy?.data.dependency.totalCount]);

  //sorting
  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          primaryOrderBy: sortingParam?.desc ? 'DESC' : 'ASC',
        });
    }
  }, [sorting]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: {name: string; id: string; picture: string}) => {
          return (
            <TextWithRoundedImage
              key={row.id}
              image={row.picture}
              text={`${row.id} ${row.name}`}
              variant={'small'}
            />
          );
        },
        header: currentPageName,
      },
    ],
    [currentPageName],
  );

  const checkIsErrorCheckbox = useCallback(
    (dependencyID: string, rowId?: string, checked?: boolean) => {
      const currentExpectedAssignments =
        assignmentsListCopy?.data.primary.values.find(singlePrimaryValue => {
          if (singlePrimaryValue.id === rowId) {
            return singlePrimaryValue;
          }
        });
      if (checked) {
        return (
          currentExpectedAssignments &&
          currentExpectedAssignments?.expectedAssignments?.indexOf(
            dependencyID,
          ) === -1
        );
      } else {
        return (
          currentExpectedAssignments &&
          currentExpectedAssignments?.expectedAssignments?.indexOf(
            dependencyID,
          ) > -1
        );
      }
    },
    [assignmentsListCopy?.data.primary.values],
  );

  const checkRowHasSelectAll = useCallback(
    (rowId: string) => {
      return (
        assignmentsListCopy &&
        assignmentsListCopy.data.assignments[rowId] &&
        isSelectAll(assignmentsListCopy.data.assignments[rowId][0])
      );
    },
    [assignmentsListCopy, isSelectAll],
  );

  const detailsPanelData = useCallback(
    (dependency: Array<DependencyValues>, rowID: string) => {
      return dependency?.map(singleDependency => {
        if (assignmentsListCopy) {
          const isError = checkIsErrorCheckbox(
            singleDependency.id,
            rowID,
            singleDependency.checked,
          );

          if (isError) {
            setCheckboxError(true);
          }

          const hasSelectAll = checkRowHasSelectAll(rowID);

          const dependecyName = `${singleDependency.id}  ${singleDependency.name}`;
          return {
            id: singleDependency.id,
            image: singleDependency.picture,
            text: dependecyName,
            checked: singleDependency.checked,
            error: isError,
            primaryId: rowID,
            disabled: hasSelectAll && !isSelectAll(singleDependency.id),
          };
        }
      });
    },
    [
      assignmentsListCopy,
      checkIsErrorCheckbox,
      checkRowHasSelectAll,
      isSelectAll,
    ],
  );

  const checkSelectAllAssignments = useCallback(
    (currentAssignment: string) => {
      let onlyAsterisk = currentAssignment !== undefined;
      const splittedAssignment =
        currentAssignment && currentAssignment.split(assignmentSeparator);

      splittedAssignment &&
        splittedAssignment.forEach((singleSplittedAssignment: string) => {
          if (singleSplittedAssignment !== '*') {
            onlyAsterisk = false;
          }
        });
      return onlyAsterisk;
    },
    [assignmentSeparator],
  );

  const checkPartialMatch = useCallback(
    (assignmentsList: Array<string>, currentDependencyId: string): boolean => {
      let isPartialMatch: boolean = false;
      assignmentsList &&
        assignmentsList.forEach(singleAssignment => {
          const splittedSeparator = singleAssignment.split(assignmentSeparator);
          if (!splittedSeparator) {
            return;
          }
          const splittedDependencyId =
            currentDependencyId.split(assignmentSeparator);
          isPartialMatch =
            (splittedSeparator[0] === '*' &&
              splittedSeparator[1] === splittedDependencyId[1]) ||
            (splittedSeparator[1] === '*' &&
              splittedSeparator[0] === splittedDependencyId[0]);
        });
      return isPartialMatch;
    },
    [assignmentSeparator],
  );

  const checkedAssignments = useCallback(
    (row: MRT_Row<MRT_RowData>) => {
      return (
        assignmentsListCopy &&
        assignmentsListCopy.data?.dependency?.values.map(currentDependency => {
          const currentAssignments =
            assignmentsListCopy.data.assignments[row.id];
          const selectAll: boolean =
            currentAssignments &&
            checkSelectAllAssignments(currentAssignments[0]);
          const checkedPartialMatch = checkPartialMatch(
            currentAssignments,
            currentDependency.id,
          );
          //Exact match
          if (
            (currentAssignments &&
              currentAssignments.indexOf(currentDependency.id) > -1) ||
            //Match to all dependency
            selectAll ||
            //Partial Match
            checkedPartialMatch
          ) {
            currentDependency['checked'] = true;
          } else {
            currentDependency['checked'] = false;
          }
          return currentDependency;
        })
      );
    },
    [assignmentsListCopy, checkPartialMatch, checkSelectAllAssignments],
  );

  const handleOnChangeCheckboxValue = useCallback(
    (panel: PanelElem, value: boolean) => {
      const currentDependencyId = panel.id;
      const currentPrimaryId = panel.primaryId;
      if (assignmentsListCopy) {
        const _tempAssigmentListCopy: GetAssigmentsFieldsResponse = {
          ...assignmentsListCopy,
        };
        if (value) {
          //selectAll case
          if (isSelectAll(currentDependencyId)) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] = [
              currentDependencyId,
            ];
          } else {
            //basic case
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.assignments[currentPrimaryId].concat(
                currentDependencyId,
              );
          }
        } else {
          if (isSelectAll(currentDependencyId)) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] = [];
          }
          //if uncheck one elem, need to uncheck * checkbox
          if (
            hasSelectAllInDepencyArray(
              assignmentsListCopy?.data.assignments[currentPrimaryId],
            )
          ) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.dependency.values.map(
                singleElem => singleElem.id,
              );
          }
          _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
            assignmentsListCopy?.data.assignments[currentPrimaryId].filter(
              currentElem => {
                return (
                  currentElem !== currentDependencyId &&
                  !isSelectAll(currentElem)
                );
              },
            );
        }
        setAssignmentsListCopy(_tempAssigmentListCopy);
      }
    },
    [assignmentsListCopy, isSelectAll, hasSelectAllInDepencyArray],
  );

  useEffect(() => {
    const assigments = assignmentsList?.data?.assignments;
    const assigmentsCopy = assignmentsListCopy?.data?.assignments;
    let selectionHasChanged: boolean = false;
    for (const singleAssignmentsKey in assigments) {
      if (assigmentsCopy) {
        if (
          !isEqualsArray(
            assigments[singleAssignmentsKey],
            assigmentsCopy[singleAssignmentsKey],
          )
        ) {
          selectionHasChanged = true;
        }
      }
    }
    setChangedSelection(selectionHasChanged);
  }, [assignmentsList, assignmentsListCopy, isEqualsArray]);

  const handleSaveDependency = useCallback(
    async (checkboxErrorReviwed?: boolean) => {
      if (!assignmentsListCopy) {
        return;
      }
      setChangedSelection(false);
      if (checkboxError && !checkboxErrorReviwed) {
        setCheckboxErrorModalOpen(true);
        return;
      }
      const mutedAssigments = {
        [currentRowId]: assignmentsListCopy.data.assignments[currentRowId],
      };
      try {
        await patchAssigmentsAsync(
          {
            viewBy: VIEW_BY_PRIMARY,
            assignments: mutedAssigments,
            customizationId: customizationId,
            fieldId: currentPageId,
          },
          {
            onSuccess: () => {
              setSuccessModalOpen(true);
              setExpandedRowDetails(undefined);
              queryClient.invalidateQueries({
                queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
                exact: false,
              });
            },
          },
        );
      } catch (e) {
        setErrorModalOpen(true);
      }
    },
    [
      assignmentsListCopy,
      checkboxError,
      currentPageId,
      currentRowId,
      customizationId,
      patchAssigmentsAsync,
      queryClient,
    ],
  );

  const paginationDependency = useCallback(
    (rowId: number) => {
      if (
        assignmentsListCopy &&
        assignmentsListCopy.data.dependency.totalCount >
          PAGINATION_DEPENDENCY_LIMIT
      ) {
        return {
          enablePrev: dependencyPagination.pageIndex !== 0,
          enableNext: dependencyPagination.pageIndex < dependencyPages - 1,
          paginationCallback: (direction: 'prev' | 'next'): void => {
            setCurrentExpandedRow(rowId.toString());
            direction === 'next'
              ? setDependencyPagination({
                  pageIndex: dependencyPagination.pageIndex + 1,
                  pageSize: PAGINATION_DEPENDENCY_LIMIT,
                })
              : setDependencyPagination({
                  pageIndex: dependencyPagination.pageIndex - 1,
                  pageSize: PAGINATION_DEPENDENCY_LIMIT,
                });
          },
        };
      } else {
        return undefined;
      }
    },
    [assignmentsListCopy, dependencyPages, dependencyPagination.pageIndex],
  );

  const detailPanelByRow = useCallback(
    (row: MRT_Row<MRT_RowData>) => {
      const panelList = detailsPanelData(checkedAssignments(row)!, row.id);
      return (
        panelList !== undefined && (
          <DetailPanelAssignments
            onChangeCheckboxValue={handleOnChangeCheckboxValue}
            panelList={panelList}
            primaryButtonDisabled={!changedSelection}
            primaryButtonActionTrigger={() => {
              handleSaveDependency();
            }}
            currentRow={row}
            copyToButtonActionTrigger={currentRow => {
              setCurrentCopyRow(currentRow);
              setCopySidebarStatus('TO');
              setTimeout(() => {
                setCopySidebarOpen(true);
              });
            }}
            copyFromButtonActionTrigger={currentRow => {
              setCopySidebarStatus('FROM');
              setCurrentCopyRow(currentRow);
              setTimeout(() => {
                setCopySidebarOpen(true);
              });
            }}
            paginationDependency={paginationDependency(Number(row.id))}
          />
        )
      );
    },
    [
      changedSelection,
      checkedAssignments,
      detailsPanelData,
      handleOnChangeCheckboxValue,
      handleSaveDependency,
      paginationDependency,
    ],
  );

  const checkIsChangedSelection = useCallback(() => {
    if (changedSelection) {
      setSaveConfirmOpen(true);
    }
  }, [changedSelection]);

  const table = useMemo(() => {
    //TODO miss error on dependencies in be api
    return (
      assignmentsListCopy && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={assignmentsListCopy.data.primary.totalCount}
          tableOptions={{
            getRowId: originalRow => originalRow.id,
            columns,
            data: assignmentsListCopy.data.primary.values,
            rowCount: assignmentsListCopy.data.primary.totalCount,
            initialState: expandedRowDetails,
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
            renderDetailPanel({row}) {
              return detailPanelByRow(row.original);
            },

            muiExpandButtonProps: ({row, table}) => ({
              onClick: () => {
                setCurrentRowId(row.id);
                setDependencyPagination(initialDependencyPagination);
                if (row.getIsExpanded()) {
                  checkIsChangedSelection();
                  setPreviousOpenedRowId(row.id);
                }
                table.setExpanded({[row.id]: !row.getIsExpanded()});
              },
            }),
            muiTableBodyRowProps: ({isDetailPanel, row, table}) => ({
              onClick: () => {
                setCurrentRowId(row.id);
                !isDetailPanel &&
                  table.setExpanded({[row.id]: !row.getIsExpanded()});
              },
              sx: {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'red',
                },
                '.Mui-TableBodyCell-DetailPanel': {
                  width: '100%',
                  '.MuiCollapse-vertical': {
                    width: '100%',
                  },
                },
              },
            }),
          }}></Table>
      )
    );
  }, [
    assignmentsListCopy,
    checkIsChangedSelection,
    columns,
    detailPanelByRow,
    expandedRowDetails,
    initialDependencyPagination,
    pagination,
    rowSelection,
    sorting,
  ]);

  const resetChanges = useCallback(() => {
    setAssignmentsListCopy(structuredClone(assignmentsList));
  }, [assignmentsList]);

  const modals = useMemo(() => {
    return (
      assignmentsListCopy && (
        <>
          {/* Success Modal*/}
          <GenericModal
            onClose={() => setSuccessModalOpen(false)}
            open={successModalOpen}
            content={t('General.successModal.correctlySaved')}
            iconName={'save'}
          />
          {/* Error Modal*/}
          <GenericModal
            onClose={() => setErrorModalOpen(false)}
            open={errorModalOpen}
            content={t('General.errorModal.message')}
            iconName={'error'}
          />
          <UnexpectedValueModal
            onClose={() => {
              setCheckboxErrorModalOpen(false);
            }}
            open={checkboxErrorModalOpen}
            onIgnore={() => {
              setCheckboxErrorModalOpen(false);
              handleSaveDependency(true);
            }}
          />
          {/* Save Confirm */}
          <SaveConfirmModal
            onClickPrimary={() => {
              setSaveConfirmOpen(false);
              setCurrentExpandedRow(previousOpenedRowId);
            }}
            onClickSecondary={() => {
              setSaveConfirmOpen(false);
              resetChanges();
            }}
            open={saveConfirmOpen}
          />
        </>
      )
    );
  }, [
    assignmentsListCopy,
    successModalOpen,
    t,
    errorModalOpen,
    checkboxErrorModalOpen,
    saveConfirmOpen,
    handleSaveDependency,
    previousOpenedRowId,
    resetChanges,
  ]);

  const copySidebar = useMemo(() => {
    return (
      currentCopyRow && (
        <CopyConfigSidebar
          customizationId={customizationId}
          currentPageId={currentPageId}
          open={copySidebarOpen}
          copySidebarStatus={copySidebarStatus}
          isPrimary={true}
          valueName={currentPageName}
          currentOriginalCopyRow={currentCopyRow}
          onClose={() => setCopySidebarOpen(false)}></CopyConfigSidebar>
      )
    );
  }, [
    copySidebarOpen,
    copySidebarStatus,
    currentCopyRow,
    currentPageId,
    currentPageName,
    customizationId,
  ]);

  return (
    <>
      {table}
      {modals}
      {copySidebar}
    </>
  );
};

export default React.memo(AssignmentsTablePrimary);
