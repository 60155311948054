import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Button} from 'components/atoms/Button';
import {Table} from 'components/molecules/Table';
import {RowInstance} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {PanelElem} from 'components/organisms/DetailPanelAssignments/definitions';
import DetailPanelAssignments from 'components/organisms/DetailPanelAssignments/DetailPanelAssignments';
import GenericModal from 'components/organisms/GenericModal';
import {ValueFormFields} from 'components/organisms/ValueForm/definitions';
import {ValuesSidebar} from 'components/organisms/ValuesSidebar';
import {useFilterContext} from 'contexts';
import {usePageContext} from 'contexts/page/PageContext';
import {useUtils} from 'hooks';
import {
  MRT_Row,
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {useAssignments} from 'store/pageSwitch/assignments';
import {
  AssignmentsQueryKeys,
  DependencyValues,
  GetAssigmentsFieldsResponse,
} from 'store/pageSwitch/assignments/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {usePageValues} from 'store/pageSwitch/values';
import {CreateValueParams} from 'store/pageSwitch/values/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {CopyConfigSidebar} from '../../CopyConfigSidebar';

import {
  IProps,
  PAGINATION_DEPENDENCY_LIMIT,
  VIEW_BY_DEPENDENCY,
} from './definitions';
import '../assignments.scss';

const AssignmentsTableDependency = ({
  customizationId,
  currentPageId,
  dependencyName,
}: IProps): ReactElement => {
  const {t} = useTranslations();

  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: PAGINATION_DEPENDENCY_LIMIT,
  });
  //Pagination param
  const [dependencyPagination, setDependencyPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });

  //Filters param
  const {filters} = useFilterContext();
  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

  //Sorting params
  const [sorting, setSorting] = useState<MRT_SortingState | []>([]);
  const [paramSorting, setParamSorting] = useState<{
    primaryOrderBy: 'ASC' | 'DESC';
  }>();

  const {useGetFieldsAssignments, patchAssigmentsAsync} = useAssignments();
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [successActionModalOpen, setSuccessActionModalOpen] =
    useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const {isEqualsArray} = useUtils();
  const [selectionHasChanged, setSelectionHasChanged] =
    useState<boolean>(false);
  const [currentCopyRow, setCurrentCopyRow] = useState<MRT_Row<MRT_RowData>>();

  const {data: assignmentsList, refetch: refetchFieldsAssignments} =
    useGetFieldsAssignments({
      customizationId,
      currentPageId,
      primaryPage: pagination.pageIndex + 1,
      primaryLimit: pagination.pageSize,
      dependencyPage: dependencyPagination.pageIndex + 1,
      dependencyLimit: dependencyPagination.pageSize,
      viewBy: VIEW_BY_DEPENDENCY,
      ...paramSorting,
      ...(paramFilters || {}),
    });

  const {callCreateValue} = usePageValues();
  const {currentPage} = usePageContext();

  const [expandedRowDetails, setExpandedRowDetails] = useState<{
    expanded: {[id: number]: boolean};
  }>();
  const [currentExpandedRow, setCurrentExpandedRow] = useState<number>();
  const [currentExpandedRowId, setCurrentExpandedRowId] = useState<string>();
  const [dependencyPages, setDependencyPages] = useState<number>(0);

  const [assignmentsListCopy, setAssignmentsListCopy] =
    useState(assignmentsList);
  const [assignmentSeparator, setAssignmentSeparator] = useState<string>('-');
  const [createSidebarOpened, setCreateSidebarOpened] =
    useState<boolean>(false);
  const queryClient = useQueryClient();
  const [copySidebarOpen, setCopySidebarOpen] = useState<boolean>(false);
  const [copySidebarStatus, setCopySidebarStatus] = useState<'FROM' | 'TO'>(
    'FROM',
  );

  useEffect(() => {
    setAssignmentSeparator('-');
  }, []);

  useEffect(() => {
    setAssignmentsListCopy(structuredClone(assignmentsList));
  }, [assignmentsList]);

  useEffect(() => {
    if (currentExpandedRow) {
      setExpandedRowDetails({
        expanded: {
          [currentExpandedRow]: true,
        },
      });
    }
  }, [assignmentsListCopy?.data.assignments, currentExpandedRow]);

  useEffect(() => {
    const dependencyTotalCount =
      assignmentsListCopy?.data.dependency.totalCount;
    if (dependencyTotalCount) {
      setDependencyPages(dependencyTotalCount / PAGINATION_DEPENDENCY_LIMIT);
    }
  }, [assignmentsListCopy?.data.dependency.totalCount]);

  //sorting
  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          primaryOrderBy: sortingParam?.desc ? 'DESC' : 'ASC',
        });
    }
  }, [sorting]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: {name: string; id: string; picture: string}) => {
          return (
            <TextWithRoundedImage
              key={row.id}
              image={row.picture}
              text={`${row.id} ${row.name}`}
              variant={'small'}
            />
          );
        },
        header: dependencyName,
      },
    ],
    [dependencyName],
  );

  const detailsPanelData = useCallback(
    (dependency: Array<DependencyValues>, rowID: string) => {
      return dependency?.map(singleDependency => {
        const dependecyName = `${singleDependency.id}  ${singleDependency.name}`;
        return {
          id: singleDependency.id,
          image: singleDependency.picture,
          text: dependecyName,
          checked: singleDependency.checked,
          error: singleDependency.error,
          primaryId: rowID,
          disabled: singleDependency.disabled,
        };
      });
    },
    [],
  );

  const checkSelectAllAssignments = useCallback(
    (currentAssignment: string) => {
      //TODO get dinamic separator from be
      let onlyAsterisk = currentAssignment !== undefined;
      const splittedAssignment =
        currentAssignment && currentAssignment.split(assignmentSeparator);

      splittedAssignment &&
        splittedAssignment.forEach((singleSplittedAssignment: string) => {
          if (singleSplittedAssignment !== '*') {
            onlyAsterisk = false;
          }
        });
      return onlyAsterisk;
    },
    [assignmentSeparator],
  );

  const checkPartialMatch = useCallback(
    (assignmentsList: Array<string>, currentDependencyId: string): boolean => {
      let isPartialMatch: boolean = false;
      assignmentsList &&
        assignmentsList.forEach(singleAssignment => {
          const splittedSeparator = singleAssignment.split(assignmentSeparator);
          const splittedDependencyId =
            currentDependencyId.split(assignmentSeparator);
          isPartialMatch =
            (splittedSeparator[0] === '*' &&
              splittedSeparator[1] === splittedDependencyId[1]) ||
            (splittedSeparator[1] === '*' &&
              splittedSeparator[0] === splittedDependencyId[0]);
        });
      return isPartialMatch;
    },
    [assignmentSeparator],
  );

  const haveStarArray = useCallback(
    (
      currentPrimaryId: string,
      currentAssignments: {[key: string]: string[]},
    ) => {
      const assignmentsKeys = Object.keys(currentAssignments);
      const starArrayIndex = assignmentsKeys && assignmentsKeys.indexOf('*');
      if (starArrayIndex <= -1) {
        return false;
      } else {
        const starArray = currentAssignments['*'];
        return starArray && starArray.indexOf(currentPrimaryId) > -1;
      }
    },
    [],
  );

  const checkedAssignments = useCallback(
    (row: MRT_Row<MRT_RowData>) => {
      return (
        assignmentsListCopy &&
        assignmentsListCopy.data?.primary?.values.map(currentPrimary => {
          const currentAssignments =
            assignmentsListCopy.data.assignments[row.id];
          const isStarArray = haveStarArray(
            currentPrimary.id,
            assignmentsListCopy.data.assignments,
          );
          //No checked checkbox
          if (!currentAssignments && !isStarArray) {
            currentPrimary['checked'] = false;
            return currentPrimary;
          }

          const selectAll: boolean =
            currentAssignments &&
            checkSelectAllAssignments(currentAssignments[0]);
          const checkedPartialMatch = checkPartialMatch(
            currentAssignments,
            currentPrimary.id,
          );
          if (
            haveStarArray(
              currentPrimary.id,
              assignmentsListCopy.data.assignments,
            )
          ) {
            currentPrimary.disabled = true;
          }
          //Exact match
          if (
            (currentAssignments &&
              currentAssignments.indexOf(currentPrimary.id) > -1) ||
            //Match to all dependency
            selectAll ||
            //Partial Match
            checkedPartialMatch ||
            isStarArray
          ) {
            currentPrimary['checked'] = true;
          } else {
            currentPrimary['checked'] = false;
          }
          return currentPrimary;
        })
      );
    },
    [
      assignmentsListCopy,
      checkPartialMatch,
      checkSelectAllAssignments,
      haveStarArray,
    ],
  );

  const checkCurrentSelectionHasChanged = useCallback(
    (updatedAssignmentsList: Array<string>) => {
      if (!assignmentsList) {
        return;
      }
      if (
        currentExpandedRowId &&
        isEqualsArray(
          assignmentsList.data.assignments[currentExpandedRowId],
          updatedAssignmentsList,
        )
      ) {
        setSelectionHasChanged(false);
      } else {
        setSelectionHasChanged(true);
      }
    },
    [assignmentsList, currentExpandedRowId, isEqualsArray],
  );

  const handleOnChangeCheckboxValue = useCallback(
    (panel: PanelElem, value: boolean) => {
      const currentDependencyId = panel.id;
      const currentPrimaryId = panel.primaryId;
      if (assignmentsListCopy) {
        const _tempAssigmentListCopy: GetAssigmentsFieldsResponse = {
          ...assignmentsListCopy,
        };
        if (value) {
          //selectAll case
          if (_tempAssigmentListCopy.data.assignments[currentPrimaryId]) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.assignments[currentPrimaryId].concat(
                currentDependencyId,
              );
          } else {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] = [
              currentDependencyId,
            ];
          }
        } else {
          //if uncheck one elem, need to uncheck * checkbox
          if (
            assignmentsListCopy?.data.assignments[currentPrimaryId].indexOf(
              '*',
            ) > -1
          ) {
            _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
              assignmentsListCopy?.data.dependency.values.map(
                singleElem => singleElem.id,
              );
          }
          _tempAssigmentListCopy.data.assignments[currentPrimaryId] =
            assignmentsListCopy?.data.assignments[currentPrimaryId].filter(
              currentElem => {
                return (
                  currentElem !== currentDependencyId && currentElem !== '*'
                );
              },
            );
        }
        setAssignmentsListCopy(_tempAssigmentListCopy);
        currentExpandedRowId &&
          checkCurrentSelectionHasChanged(
            _tempAssigmentListCopy.data.assignments[currentExpandedRowId],
          );
      }
    },
    [
      assignmentsListCopy,
      currentExpandedRowId,
      checkCurrentSelectionHasChanged,
    ],
  );

  const handleSaveDependency = useCallback(
    async (rowId: string) => {
      if (!assignmentsListCopy) {
        return;
      }
      const mutedAssigments = {
        [rowId]: assignmentsListCopy.data.assignments[rowId],
      };
      try {
        await patchAssigmentsAsync(
          {
            viewBy: VIEW_BY_DEPENDENCY,
            assignments: mutedAssigments,
            customizationId: customizationId,
            fieldId: currentPageId,
          },
          {
            onSuccess: () => {
              setSuccessModalOpen(true);
              queryClient.invalidateQueries({
                queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
                exact: false,
              });
            },
          },
        );
      } catch (e) {
        setErrorModalOpen(true);
      }
    },
    [
      assignmentsListCopy,
      currentPageId,
      customizationId,
      patchAssigmentsAsync,
      queryClient,
    ],
  );

  const paginationDependency = useCallback(
    (rowId: number) => {
      if (
        assignmentsListCopy &&
        assignmentsListCopy.data.primary.totalCount >
          PAGINATION_DEPENDENCY_LIMIT
      ) {
        return {
          enablePrev: pagination.pageIndex !== 0,
          enableNext: pagination.pageIndex < dependencyPages - 1,
          paginationCallback: (direction: 'prev' | 'next'): void => {
            setCurrentExpandedRow(rowId);
            direction === 'next'
              ? setPagination({
                  pageIndex: pagination.pageIndex + 1,
                  pageSize: PAGINATION_DEPENDENCY_LIMIT,
                })
              : setPagination({
                  pageIndex: pagination.pageIndex - 1,
                  pageSize: PAGINATION_DEPENDENCY_LIMIT,
                });
          },
        };
      } else {
        return undefined;
      }
    },
    [assignmentsListCopy, dependencyPages, pagination.pageIndex],
  );

  const secondaryButtonTpl = useMemo(() => {
    return (
      <Button
        iconLeft={'add'}
        variant="secondary"
        size="small"
        onClick={() => {
          setCreateSidebarOpened(true);
        }}>
        {t('Values.buttons.addManually')}
      </Button>
    );
  }, [t]);

  const detailPanelByRow = useCallback(
    (row: MRT_Row<MRT_RowData>) => {
      return (
        secondaryButtonTpl && (
          <DetailPanelAssignments
            onChangeCheckboxValue={handleOnChangeCheckboxValue}
            panelList={detailsPanelData(checkedAssignments(row)!, row.id)}
            primaryButtonActionTrigger={() => handleSaveDependency(row.id)}
            paginationDependency={paginationDependency(Number(row.id))}
            secondaryButton={secondaryButtonTpl}
            primaryButtonDisabled={!selectionHasChanged}
            copyFromButtonActionTrigger={currentRow => {
              setCopySidebarStatus('FROM');
              setCurrentCopyRow(currentRow);
              setTimeout(() => {
                setCopySidebarOpen(true);
              });
            }}
            copyToButtonActionTrigger={currentRow => {
              setCopySidebarStatus('TO');
              setCurrentCopyRow(currentRow);
              setTimeout(() => {
                setCopySidebarOpen(true);
              });
            }}
            currentRow={row}
          />
        )
      );
    },
    [
      checkedAssignments,
      detailsPanelData,
      handleOnChangeCheckboxValue,
      handleSaveDependency,
      paginationDependency,
      secondaryButtonTpl,
      selectionHasChanged,
    ],
  );

  const handleCreateValue = useCallback(
    (data: ValueFormFields) => {
      const filteredData: CreateValueParams['params'] = {...data};

      if (filteredData.color === '') {
        delete filteredData.color;
      }

      callCreateValue(
        {
          customizationId,
          currentPageId,
          params: filteredData,
        },
        {
          onSuccess: () => {
            setSuccessActionModalOpen(true);
            setCreateSidebarOpened(false);
            refetchFieldsAssignments();
            queryClient.invalidateQueries({
              queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
              exact: false,
              refetchType: 'all',
            });
          },
          onError: () => {
            setErrorModalOpen(true);
          },
        },
      );
    },
    [
      callCreateValue,
      currentPageId,
      customizationId,
      queryClient,
      refetchFieldsAssignments,
    ],
  );

  const table = useMemo(() => {
    //TODO miss error on dependencies in be api
    return (
      assignmentsListCopy && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={assignmentsListCopy.data.dependency.totalCount}
          tableOptions={{
            getRowId: (originalRow: any) => originalRow.id,
            columns,
            data: assignmentsListCopy.data.dependency.values,
            rowCount: assignmentsListCopy.data.dependency.totalCount,
            initialState: expandedRowDetails,
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: dependencyPagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setDependencyPagination,
            renderDetailPanel({row}: RowInstance) {
              return detailPanelByRow(row.original);
            },
            muiExpandButtonProps: ({row, table}) => ({
              onClick: () => {
                setCurrentExpandedRowId(row.id);
                table.setExpanded({[row.id]: !row.getIsExpanded()});
              },
            }),
            muiTableBodyRowProps: ({isDetailPanel, row, table}) => ({
              onClick: () => {
                setCurrentExpandedRowId(row.id);
                !isDetailPanel &&
                  table.setExpanded({[row.id]: !row.getIsExpanded()});
              },
              sx: {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'red',
                },
                '.Mui-TableBodyCell-DetailPanel': {
                  width: '100%',
                  '.MuiCollapse-vertical': {
                    width: '100%',
                  },
                },
              },
            }),
          }}></Table>
      )
    );
  }, [
    assignmentsListCopy,
    columns,
    dependencyPagination,
    detailPanelByRow,
    expandedRowDetails,
    pagination.pageIndex,
    pagination.pageSize,
    rowSelection,
    sorting,
  ]);

  const copySidebar = useMemo(() => {
    return (
      currentCopyRow && (
        <CopyConfigSidebar
          customizationId={customizationId}
          currentPageId={currentPageId}
          open={copySidebarOpen}
          copySidebarStatus={copySidebarStatus}
          isPrimary={false}
          valueName={dependencyName}
          onClose={() => setCopySidebarOpen(false)}
          currentOriginalCopyRow={currentCopyRow}></CopyConfigSidebar>
      )
    );
  }, [
    currentCopyRow,
    copySidebarOpen,
    copySidebarStatus,
    currentPageId,
    customizationId,
    dependencyName,
  ]);

  const modals = useMemo(() => {
    return (
      assignmentsListCopy && (
        <>
          {/* Success Modal*/}
          <GenericModal
            onClose={() => setSuccessModalOpen(false)}
            open={successModalOpen}
            content={t('General.successModal.correctlySaved')}
            iconName={'save'}
          />
          {/* Success Modal*/}
          <GenericModal
            onClose={() => setSuccessActionModalOpen(false)}
            open={successActionModalOpen}
            content={t('General.successModal.actionsCorrectlySaved')}
            iconName={'save'}
          />
          {/* Error Modal*/}
          <GenericModal
            onClose={() => setErrorModalOpen(false)}
            open={errorModalOpen}
            content={t('General.errorModal.message')}
            iconName={'error'}
          />
          <ValuesSidebar
            open={createSidebarOpened}
            onClose={() => {
              setCreateSidebarOpened(false);
            }}
            mode="create"
            currentPage={currentPage}
            onCreateValue={handleCreateValue}
          />
        </>
      )
    );
  }, [
    assignmentsListCopy,
    createSidebarOpened,
    currentPage,
    errorModalOpen,
    handleCreateValue,
    successActionModalOpen,
    successModalOpen,
    t,
  ]);

  return (
    <>
      {table}
      {modals}
      {copySidebar}
    </>
  );
};

export default React.memo(AssignmentsTableDependency);
