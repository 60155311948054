import React, {ReactElement, useMemo} from 'react';

import {Breadcrumb} from 'components/atoms/Breadcrumb';
import {MainNavigation} from 'components/molecules/MainNavigation';
import {PageContent} from 'components/organisms/PageContent';
import {Base} from 'components/templates/Base';
import {useFilterContext} from 'contexts';
import {useUtils} from 'hooks';
import {useParams} from 'react-router-dom';
import {usePageSwitch} from 'store/pageSwitch';
import {useTranslations} from 'vidiemme/react-i18n';

const PagesSwitch = (): ReactElement => {
  const {customizationId} = useParams();
  const {getDependencyName} = useUtils();
  const {useGetCustomizationFields} = usePageSwitch();
  const {currentPageId, currentPageName} = useFilterContext();
  const {getStorage} = useUtils();
  const {t} = useTranslations();

  const {data: pageList} = useGetCustomizationFields(customizationId!);

  const currentTemplateName = useMemo(() => {
    const storageName = getStorage('currentTemplateName');
    return (
      (storageName && JSON.parse(JSON.parse(storageName)).currentName) || ''
    );
  }, [getStorage]);

  const BreadcrumbsElement = useMemo(() => {
    return (
      currentTemplateName &&
      currentPageName && (
        <Breadcrumb
          breadcrumb={[
            {
              label: t('Navigation.template'),
              link: '/',
            },
            {label: currentTemplateName},
            {label: currentPageName},
          ]}
        />
      )
    );
  }, [currentTemplateName, currentPageName, t]);

  const MainNavigationElement = useMemo(() => {
    return (
      pageList?.data && (
        <MainNavigation dinamicNavigationElements={pageList.data.fields} />
      )
    );
  }, [pageList?.data]);

  const currentPage = useMemo(() => {
    return pageList && currentPageId !== ''
      ? pageList.data?.fields.find(singlePage => {
          return singlePage.id === currentPageId;
        })
      : undefined;
  }, [currentPageId, pageList]);

  const PageContentElement = useMemo(() => {
    if (!customizationId || !currentPage || !pageList) {
      return undefined;
    }

    const hasDependencyField = Boolean(currentPage.dependsOn);
    const dependencyFieldName = getDependencyName(
      pageList.data.fields,
      currentPage,
    );

    return (
      <PageContent
        key={`${customizationId}-${currentPage.id}`}
        customizationId={customizationId}
        currentPage={currentPage}
        hasDependencyField={hasDependencyField}
        dependencyFieldName={dependencyFieldName}
      />
    );
  }, [currentPage, customizationId, getDependencyName, pageList]);

  return (
    <Base>
      {BreadcrumbsElement}
      {MainNavigationElement}
      {PageContentElement}
    </Base>
  );
};

export default React.memo(PagesSwitch);
