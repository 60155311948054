import React, {ReactElement, useCallback, useState} from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {AxiosError} from 'axios';
import {Button} from 'components/atoms/Button';
import {IconSVG} from 'components/atoms/IconSVG';
import {Input} from 'components/atoms/Input';
import {ForgottenPasswordModal} from 'components/organisms/ForgottenPasswordModal';
import {InvalidCredentialsModal} from 'components/organisms/InvalidCredentialsModal';
import {AuthRoutes, useNavigation} from 'navigation';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useAuthentication} from 'store/authentication';
import {useTranslations} from 'vidiemme/react-i18n';
import * as yup from 'yup';

import {GoToForgotPasswordModal} from '../../organisms/GoToForgotPasswordModal';

import {LoginInputs} from './interfaces';

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

const Login = (): ReactElement => {
  const {t} = useTranslations();
  const {goToPath} = useNavigation();

  const formMethods = useForm<LoginInputs>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: {isValid},
  } = formMethods;

  const {login, authClient} = useAuthentication();

  const [openForgotPwdModal, setOpenForgotPwdModal] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [resetPasswordUrl, setResetPasswordUrl] = useState<string>('');

  const onSubmit: SubmitHandler<LoginInputs> = async (data: LoginInputs) => {
    try {
      await login(
        {...data},
        {
          onSuccess: () => {
            goToPath(AuthRoutes.MAIN);
          },
          onError: e => {
            e.code == 123 && e.resetURL
              ? setResetPasswordUrl(e.resetURL)
              : setErrorMessage(e.message);
          },
        },
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        setErrorMessage(e.message);
      }
    }
  };

  const loginWithOkta = useCallback(() => {
    authClient.token
      .getWithRedirect({
        responseType: ['token', 'id_token'],
        state: 'defaultrandomstring',
        scopes: ['openid', 'profile', 'email', 'offline_access'],
      })
      .catch(function (err) {
        // eslint-disable-next-line
        console.error('Error OKTA login redirect', err);
      });
  }, [authClient.token]);

  return (
    <div className="flex flex-col bg-secondary-main min-h-screen pt-[100px] items-center text-white">
      <FormProvider {...formMethods}>
        <form
          className="flex flex-col gap-20 items-center"
          onSubmit={handleSubmit(onSubmit)}>
          <IconSVG icon="logo" className="w-[228px] h-[62px]" />
          <div className="container w-[478px] flex flex-col gap-5">
            <Button
              classNames="w-full"
              variant="secondary"
              onClick={() => loginWithOkta()}>
              {t('Login.sso')}
            </Button>
            <p className="text-small self-center uppercase py-5">
              {t('Login.or') as string}
            </p>
            <Input
              variant="secondary"
              size="big"
              name="username"
              placeholder={t('Login.usernamePlaceholder')}
            />
            <Input
              variant="secondary"
              name="password"
              size="big"
              type="password"
              placeholder={t('Login.passwordPlaceholder')}
            />
            <Button
              classNames="self-end"
              variant="logout"
              onClick={() => setOpenForgotPwdModal(true)}>
              {t('Login.forgotPassword') as string}
            </Button>
            <Button
              classNames="w-full"
              variant="secondary"
              type="submit"
              disabled={!isValid}>
              {t('Login.login') as string}
            </Button>

            <InvalidCredentialsModal
              open={errorMessage !== ''}
              message={errorMessage}
              onClose={() => {
                setErrorMessage('');
              }}
            />
            <GoToForgotPasswordModal
              open={resetPasswordUrl !== ''}
              url={resetPasswordUrl}
            />
          </div>
        </form>
      </FormProvider>
      <ForgottenPasswordModal
        open={openForgotPwdModal}
        onClose={() => setOpenForgotPwdModal(false)}
      />
    </div>
  );
};

export default React.memo(Login);
