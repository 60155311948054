import {useCallback} from 'react';

import {keepPreviousData, useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks';
import {useAPI} from 'store/api';

import {
  GetSwatchesParams,
  GetSwatchesResponse,
  SwatchesQueryKeys,
  SwatchValuesUnion,
  UploadSwatchParams,
} from './definitions';

export const usePageSwatches = () => {
  const {api} = useAPI();
  const {customError} = useUtils();

  // Api

  const getSwatches = useCallback(
    async ({customizationId, currentPageId, options}: GetSwatchesParams) => {
      try {
        const {data} = await api.get<GetSwatchesResponse<SwatchValuesUnion>>(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/swatches`,
          {params: options},
        );

        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const useGetSwatches = (params: GetSwatchesParams) =>
    useQuery({
      queryKey: [SwatchesQueryKeys.GET_SWATCHES_QUERY_KEY, params],
      queryFn: async () => getSwatches(params),
      placeholderData: keepPreviousData,
    });

  const uploadPrimarySwatch = useCallback(
    async ({
      customizationId,
      currentPageId,
      valueId,
      file,
    }: UploadSwatchParams) => {
      try {
        const {data} = await api.put(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/swatches/${valueId}`,
          {picture: file},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const useUploadPrimarySwatch = () =>
    useMutation({
      mutationFn: uploadPrimarySwatch,
    });

  const uploadDependencySwatch = useCallback(
    async ({
      customizationId,
      currentPageId,
      valueId,
      dependencyId,
      file,
    }: UploadSwatchParams) => {
      try {
        const {data} = await api.put(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/swatches/${valueId}/${dependencyId}`,
          {picture: file},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const useUploadDependencySwatch = () =>
    useMutation({
      mutationFn: uploadDependencySwatch,
    });

  return {
    useGetSwatches,
    useUploadPrimarySwatch,
    useUploadDependencySwatch,
  };
};
