import React from 'react';

import {Stack} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {PickerWithPreview} from 'components/molecules/PickerWithPreview';
import {useTranslations} from 'vidiemme/react-i18n';

import {ITemplateModal} from './interfaces';

const ImportTemplateModal = ({
  onClose,
  onConfirm,
  confirmDisabled,
  fileName,
  handleFileSelected,
  ...props
}: ITemplateModal) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      title={t('Template.importModal.title')}
      content={
        <Stack width="100%" gap={4}>
          <p>{t('Template.importModal.description')}</p>
          <div className="bg-tan-3 p-5">
            <PickerWithPreview
              buttonLabel={t('Template.importModal.chooseFile')}
              value={fileName}
              icon="add"
              variant="name"
              onChange={handleFileSelected}
              allowedFiles=".xlsx"
              type="file"
            />
          </div>
        </Stack>
      }
      actions={
        <Stack width="100%" flexDirection="row" justifyContent="space-between">
          <Button variant="tertiary" onClick={onClose}>
            {t('Template.importModal.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={confirmDisabled}>
            {t('Template.importModal.confirm')}
          </Button>
        </Stack>
      }
    />
  );
};

export default React.memo(ImportTemplateModal);
