import React from 'react';

import {Stack} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {IModelsModal} from './interfaces';

const ConfirmDeleteModal = ({
  onConfirm,
  onClose,
  text = 'Models.confirmDeleteModal.subTitle',
  primaryCtaVariant = 'primary',
  primaryCtaText = 'Models.confirmDeleteModal.confirm',
  secondaryCtaText = 'Models.confirmDeleteModal.back',
  ...props
}: IModelsModal) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      title={t('Models.confirmDeleteModal.title')}
      content={
        <Stack width="100%" flexDirection="row" alignItems="flex-start">
          <p className="text-left">{t(text)}</p>
        </Stack>
      }
      actions={[
        <Stack
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
          key="actions">
          <Button variant="tertiary" onClick={onClose}>
            {t(secondaryCtaText)}
          </Button>
          <Button variant={primaryCtaVariant} onClick={onConfirm}>
            {t(primaryCtaText)}
          </Button>
        </Stack>,
      ]}
    />
  );
};

export default React.memo(ConfirmDeleteModal);
