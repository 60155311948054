import React from 'react';

import {CircularProgress} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {ChangeAllVisibilityModalProps} from './definition';

const ChangeAllVisibilityModal = ({
  onCancel,
  onConfirm,
  isSaving = false,
  ...props
}: ChangeAllVisibilityModalProps) => {
  const {t} = useTranslations();

  return (
    <Modal
      headerIcon="error"
      content={
        isSaving ? (
          <CircularProgress />
        ) : (
          <p>{t('ChangeAllVisibilityModal.content')}</p>
        )
      }
      actions={
        <div className="w-full flex flex-row items-center justify-between">
          <Button variant="tertiary" onClick={onCancel} disabled={isSaving}>
            {t('ChangeAllVisibilityModal.actions.cancel')}
          </Button>
          <Button variant="primary" onClick={onConfirm} disabled={isSaving}>
            {t('ChangeAllVisibilityModal.actions.confirm')}
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default React.memo(ChangeAllVisibilityModal);
