export const en = {
  General: {
    logout: 'logout',
    save: 'save',
    cancel: 'cancel',
    ok: 'ok',
    404: {
      title: '404',
      subtitle: 'Page not found',
    },
    successModal: {
      correctlySaved: 'Item saved successfully',
      actionsCorrectlySaved: 'Actions correctly saved',
    },
    errorModal: {
      message: 'Ops, something went wrong',
    },
  },
  ErrorSizeModal: {
    text: 'The correct display of the tool is not guaranteed with this device size',
  },
  Table: {
    rowsPerPage: 'Items per page:',
    clone: 'Clone Template',
    models: 'Models',
    settings: 'Settings',
    upload: 'Upload',
    download: 'Download',
    unexpected: 'Might have assignment issues',
    unassigned: 'Not assigned to any combination',
    edit: 'Edit',
  },
  Filters: {
    id: 'ID',
    nameId: 'ID {{name}}',
    all: 'ALL',
    material: 'Material',
    color: 'Color',
    filter: 'Filter',
    model: 'Model',
    clear: 'clear',
    name: 'name',
    status: 'status',
    visibility: 'Visibility',
    size: 'Size',
  },
  Login: {
    sso: 'Login with SSO',
    login: 'Login with email and password',
    or: 'or',
    modalPasswordExpired: {
      content: 'Password expired',
      updatePassword: 'Update Password',
    },

    usernamePlaceholder: 'Username*',
    passwordPlaceholder: 'Password*',
    forgotPassword: 'Forgot Password?',
    modalForgetPassword: {
      title: 'Forget password?',
      content:
        'Enter your email address below. If that’s a valid account you will receive an email with a link for resetting your password.',
      cancel: 'Cancel',
      confirm: 'Confirm',
      placeholder: 'Email*',
    },
    modalEmailSent: {
      content: 'An email has been sent to the address you provided.',
      confirm: 'Ok',
    },
    modalInvalidCredentials: {
      content: 'Invalid login: {{error}}',
      confirm: 'Ok',
    },
  },
  Template: {
    buttonClear: 'Clear',
    buttons: {
      downloadButton: 'Download selection',
    },
    cloneModal: {
      title: 'Clone template',
      cancel: 'Cancel',
      confirm: 'Confirm',
      inputs: {
        name: 'Name*',
        department: 'Department*',
        sub_departments: 'Sub Departments',
        season: 'Season*',
        event: 'Event*',
      },
      successModal: {
        message: 'Template correctly cloned',
        confirm: 'Ok',
      },
      errorModal: {
        message: 'Template not cloned',
        confirm: 'Ok',
      },
    },
    importModal: {
      title: 'Import template',
      description: 'Upload a template from your computer.',
      cancel: 'Cancel',
      confirm: 'Upload',
      chooseFile: 'Choose file',
      successModal: {
        message: 'Template correctly uploaded',
        confirm: 'Ok',
      },
      errorModal: {
        message: 'Template not uploaded',
        confirm: 'Ok',
      },
    },
    table: {
      identifier: 'Identifier',
      name: 'Name',
      department: 'Department',
      departmentShort: 'Dept.',
      subDepartment: 'Sub department',
      subDepartmentShort: 'Sub Dept.',
      season: 'Season',
      event: 'Event',
      catalog: 'Catalog',
      catalogues: 'Catalogs',
    },
  },
  Options: {
    table: {
      id: 'id',
      name: 'name',
    },
  },
  Models: {
    table: {
      codMod: 'Cod. Mod.',
      name: 'name',
      desMod: 'Des. Mod.',
      dept: 'Dept.',
      subDept: 'Sub Dept.',
      season: 'Season',
      event: 'event',
      deliveryDate: 'deliveryDate',
    },
    confirmDeleteModal: {
      title: 'Confirmation Required',
      subTitle: 'Some models have been removed. Proceed?',
      back: 'back',
      confirm: 'Confirm',
      singleDeleteSubtitle: 'The value will be removed. Proceed?',
      multipleDeleteSubtitle: 'Some models have been removed. Proceed?',
    },
    successModal: {
      message: 'Actions correctly saved',
      confirm: 'ok',
      deleteSuccessMessage: 'Selected records deleted',
    },
    buttons: {
      add: 'add',
      remove: 'remove',
    },
  },
  Sidebar: {
    info: {
      title: 'Info',
      id: 'Id*',
      name: 'Name*',
      placeholder: 'Insert value',
      description: 'description',
    },
    appearance: {
      title: 'Appearance',
      imageLabel: 'Image',
      colorLabel: 'Color',
      button: {
        upload: 'Upload',
        choose: 'Choose',
      },
    },
    advancedSettings: {
      title: 'Advanced settings',
      label: 'Tracking tool Id',
      placeholder: 'Insert value',
      checkbox: {
        default: 'Default',
        fallback: 'Fallback',
        selected: 'Always selected',
      },
    },
    button: {
      deleteValue: 'Delete value',
      saveChanges: 'Save changes',
    },
  },
  ModelsSidebar: {
    actions: {
      delete: 'Delete Value',
      save: 'Save Changes',
    },
    info: 'Info',
    fields: {
      name: {
        label: 'Name*',
        placeholder: 'Insert value',
      },
      deliveryDate: {
        label: 'Delivery date (weeks)',
        placeholder: 'Insert value',
      },
      description: {
        label: 'Description',
        placeholder: 'Insert value',
      },
    },
  },
  ValuesSidebar: {
    actions: {
      save: 'Save Changes',
      add: 'Add',
      cancel: 'Cancel',
      delete: 'Delete Value',
    },
    info: 'Info',
    appearance: 'Appearance',
    advancedSettings: 'Advanced settings',
    fields: {
      id: {
        label: 'Id*',
        placeholder: 'Insert value',
      },
      name: {
        label: 'Name*',
        placeholder: 'Insert value',
      },
      tracking: {
        label: 'Tracking tool Id',
        placeholder: 'Insert value',
      },
      image: {
        label: 'Upload',
        previewLabel: 'Image',
      },
      color: {
        label: 'Choose',
        swatchesLabel: 'Swatches',
        previewLabel: 'Color',
        shortcutImageProvider: 'Shortcut Image Provider',
      },
      default: {
        label: 'Default If',
      },
      fallback: {
        label: 'Fallback',
      },
      selected: {
        label: 'Always selected',
      },
    },
  },
  Accessibility: {
    mainNavigation: 'Main Navigation',
  },
  Materials: {
    viewBy: 'View by',
  },
  Navigation: {
    currentModels: 'Current Models',
    proposedModels: 'Proposed Models',
    template: 'Template',
    models: 'models',
  },
  Values: {
    buttons: {
      addFromCatalog: 'add from catalog',
      addManually: 'add manually',
      delete: 'delete selection',
    },
    status: {
      all: 'All',
      unassigned: 'Not associated',
      unexpected: 'Not in catalog',
    },
    table: {
      id: 'id',
      name: 'name',
      values: 'values',
    },
    confirmDeleteModal: {
      text: 'Delete selected records?',
      secondaryCtaText: 'Cancel',
      primaryCtaText: 'Delete',
    },
  },
  Assignments: {
    General: {
      save: 'save',
    },
    Combined: {
      suggestedInfoCombination: 'Suggested product/catalog combination',
      notExistCombination: 'product/catalog combination might not exist',
    },
    UnexpectedValueModal: {
      text: 'There are unexpected or empty combinations',
      ignore: 'ignore',
      review: 'review',
    },
    SaveConfirmModal: {
      title: 'Confirmation required',
      subTitle: 'Do you want to save your changes?',
      primaryCta: 'Back to edits',
      secondaryCta: 'Continue without saving',
    },
    copyFrom: 'Copy From',
    copyTo: 'Copy To',
    copyFromSecondaryLabel: '(It is possible to select only one element)',
  },
  ValuesFromCatalog: {
    title: 'Add from catalog',
    actions: {
      cancel: 'Cancel',
      add: 'Add',
    },
    table: {
      id: 'id',
      name: 'name',
    },
  },
  Visibility: {
    buttons: {
      changeVisibility: 'Change selection visibility',
    },
    visibilityOptions: {
      all: 'All',
      visible: 'Visible',
      notVisible: 'Not visible',
    },
    onForAll: 'Visibility ON for all (*)',
    offForAll: 'Visibility OFF for all (*)',
    table: {
      id: 'id',
      name: 'name',
      visibility: 'Visibility',
    },
  },
  ChangeVisibilityModal: {
    content: 'Change visibility for your selection',
    actions: {
      switchOff: 'Switch OFF',
      switchOn: 'Switch ON',
    },
  },
  ChangeAllVisibilityModal: {
    content: 'Change visibility for all. Proceed?',
    actions: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
  },
  Tabs: {
    noDependencyLabel:
      '{{primary}} - Depends on Nothing, changes will apply to all models in this template',
    dependsOnLabel: '{{primary}} depends on {{dependency}}',
  },
  Swatches: {
    uploadModals: {
      successModal: {
        message: 'File correctly uploaded',
        confirm: 'Ok',
      },
      errorModal: {
        message: 'File not uploaded',
        confirm: 'Ok',
      },
    },
  },
};
