export interface IProps {
  customizationId: string;
  currentPageId: string;
  dependencyName: string;
}

export const PAGINATION_DEPENDENCY_LIMIT: number = 100;

export const VIEW_BY_COMBINED: string = 'combined';

export interface CellProps {
  id: string;
  getValue: () => any;
  renderValue: () => any;
  renderedCellValue: any;
}
