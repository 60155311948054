import React, {ReactElement, useCallback, useEffect, useState} from 'react';

import {Backdrop, CircularProgress} from '@mui/material';
import {AxiosError} from 'axios';
import {InvalidCredentialsModal} from 'components/organisms/InvalidCredentialsModal';
import {Base} from 'components/templates/Base';
import {useUtils} from 'hooks';
import {AnonymousRoutes, AuthRoutes, useNavigation} from 'navigation';
import {useAuthentication} from 'store/authentication';

const Sso = (): ReactElement => {
  const {ssoLoginAsync, authClient, ssoLogout} = useAuthentication();
  const {goToPath} = useNavigation();
  const {customError} = useUtils();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const loginWithSso = useCallback(
    async (token: string) => {
      try {
        await ssoLoginAsync(
          {token: token},
          {
            onSuccess: () => {
              goToPath(AuthRoutes.MAIN);
            },
            onError: e => {
              setErrorMessage(e.message);
            },
          },
        );
      } catch (e) {
        if (e instanceof AxiosError) {
          setErrorMessage(e.message);
        }
      }
    },
    [goToPath, ssoLoginAsync],
  );

  const getAccessToken = useCallback(() => {
    authClient.token
      .parseFromUrl()
      .then(async function (res) {
        // manage token or tokens
        const tokens = res.tokens;

        // Setting token in token manager to get fetch access token from SDK
        authClient.tokenManager.setTokens(tokens);
        const token = authClient.tokenManager.getTokensSync();
        const {accessToken} = token;
        const authToken = accessToken && accessToken.accessToken;
        authToken && (await loginWithSso(authToken));
      })
      .catch(function (e) {
        customError(e);
      });
  }, [authClient.token, authClient.tokenManager, customError, loginWithSso]);

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  return (
    <Base>
      <Backdrop
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <InvalidCredentialsModal
        open={errorMessage !== ''}
        message={errorMessage}
        onClose={async () => {
          setErrorMessage('');
          goToPath(AnonymousRoutes.LOGIN);
          await ssoLogout();
        }}
      />
    </Base>
  );
};

export default React.memo(Sso);
