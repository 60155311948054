import React from 'react';

import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {InvalidCredentialsModalProps} from './interfaces';

const InvalidCredentialsModal = ({...props}: InvalidCredentialsModalProps) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon="error"
      content={t('Login.modalInvalidCredentials.content', {
        error: props.message,
      })}
      actions={
        <Button variant="primary" onClick={props.onClose}>
          {t('Login.modalEmailSent.confirm')}
        </Button>
      }
    />
  );
};

export default React.memo(InvalidCredentialsModal);
