import React from 'react';

import IcomoonReact from 'icomoon-react';
import iconSet from 'themes/base/assets/selection.json'; // @TODO: support multiple themes

import {IProps} from './interfaces';

const IconSVG = ({className, icon, size, color}: IProps) => (
  <IcomoonReact
    className={className}
    iconSet={iconSet}
    color={color}
    size={size}
    icon={icon}
  />
);

export default React.memo(IconSVG);
