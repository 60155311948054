import React, {createContext} from 'react';

interface ProvidedValue {
  currentTheme?: string;
  setTheme: (theme: string) => void;
}

const Context = createContext<ProvidedValue>({
  currentTheme: undefined,
  setTheme: () => {
    console.log('ThemeProvider is not rendered!');
  },
});

interface Props {
  theme: string;
  children?: React.ReactNode;
}

const STORED_THEME_KEY = 'data-theme';

// eslint-disable-next-line react/display-name,react/prop-types
export const ThemeProvider = React.memo<Props>(({children}) => {
  //const {scheme} = useColorScheme();
  const initialTheme = 'light';
  /*    localStorage.getItem(STORED_THEME_KEY) ||
    (scheme === 'dark' ? 'dark' : theme);*/
  const [currentTheme, setCurrentTheme] = React.useState<string>(initialTheme);

  const applyThemeChanges = React.useCallback((theme: string) => {
    document.documentElement.setAttribute('data-theme', theme);
  }, []);

  React.useEffect(() => {
    applyThemeChanges(currentTheme);
  }, [applyThemeChanges, currentTheme]);

  const setThemeCallback = React.useCallback(
    (theme: string, remember = false) => {
      applyThemeChanges(theme);
      setCurrentTheme(theme);
      if (remember) {
        localStorage.setItem(STORED_THEME_KEY, theme);
      }
    },
    [applyThemeChanges],
  );

  const MemoizedValue = React.useMemo(() => {
    const value: ProvidedValue = {
      currentTheme,
      setTheme: setThemeCallback,
    };

    return value;
  }, [currentTheme, setThemeCallback]);

  return <Context.Provider value={MemoizedValue}>{children}</Context.Provider>;
});

export const useTheme = () => React.useContext(Context);
