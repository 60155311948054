import React from 'react';

import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {IEmailSentModal} from './interfaces';

const EmailSentModal = ({...props}: IEmailSentModal) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon="email"
      content={t('Login.modalEmailSent.content')}
      actions={
        <Button variant="primary" onClick={props.onClose}>
          {t('Login.modalEmailSent.confirm')}
        </Button>
      }
    />
  );
};

export default React.memo(EmailSentModal);
