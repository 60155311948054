import React from 'react';

import {Drawer} from '@mui/material';
import {AddFromCatalogTable} from 'components/organisms/AddFromCatalogTable';
import {FiltersProvider} from 'contexts';

import {AddFromCatalogSidebarProps} from './definitions';

const AddFromCatalogSidebar = ({
  customizationId,
  currentPageId,
  ...props
}: AddFromCatalogSidebarProps) => {
  return (
    <Drawer
      anchor="right"
      transitionDuration={500}
      classes={{
        paper: 'w-[1224px] max-w-full',
      }}
      {...props}>
      <FiltersProvider>
        <AddFromCatalogTable
          customizationId={customizationId}
          currentPageId={currentPageId}
          onCancel={props.onClose}
        />
      </FiltersProvider>
    </Drawer>
  );
};

export default React.memo(AddFromCatalogSidebar);
