import {createContext, useContext} from 'react';

import {PageContextProps, PageProviderProps} from './definitions';

const PageContext = createContext<PageContextProps | undefined>(undefined);

export const PageProvider = ({value, children}: PageProviderProps) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  const context = useContext(PageContext);

  if (!context) {
    throw new Error(
      '[Page Context] usePageContext must be inside a Provider with a value',
    );
  }

  return context;
};
