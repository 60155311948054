export enum TemplateQueryKeys {
  TEMPLATES_QUERY_KEY = 'TEMPLATES_QUERY_KEY',
  TEMPLATES_QUERY_FILTERS_KEY = 'TEMPLATES_QUERY_FILTERS_KEY',
  TEMPLATES_QUERY_EXPORT_KEY = 'TEMPLATES_QUERY_EXPORT_KEY',
}

export interface FiltersParams {
  departments?: number | string;
  subDepartments?: number | string;
  season?: number | string;
  event?: string;
  identifier?: number | string;
  name?: string;
}

export interface CustomizationsParams extends FiltersParams {
  page: number;
  limit?: number;
  sortBy?: string;
  orderBy?: string | 'ASC' | 'DESC';
}
