export enum AssignmentsQueryKeys {
  ASSIGNMENTS_FIELDS_QUERY_KEY = 'ASSIGNMENTS_FIELDS_QUERY_KEY',
}

export interface GetFieldsAssignmentsParams {
  customizationId: string;
  fieldId: string;
}

export interface PrimaryValues {
  name: string;
  id: string;
  unassigned: boolean;
  picture: string;
  unexpected: boolean;
  checked?: boolean;
  expectedAssignments: Array<string>;
  disabled?: boolean;
}

export interface DependencyValues {
  name: string;
  id: string;
  picture?: string;
  checked?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export interface GetAssigmentsFieldsResponse {
  data: {
    primary: {
      totalCount: number;
      values: Array<PrimaryValues>;
    };
    dependency: {
      totalCount: number;
      values: Array<DependencyValues>;
    };
    assignments: {[key: string]: Array<string>};
  };
}

export interface PatchAssigmentParams {
  viewBy: string;
  assignments: {[key: string]: Array<string>};
  customizationId: string;
  fieldId: string;
}

export interface CopyConfigAssigmentParams {
  viewBy: string;
  copyFrom: string;
  copyTo: Array<string>;
  customizationId: string;
  fieldId: string;
}
