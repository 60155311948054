import React from 'react';

import {Navigate, Outlet} from 'react-router-dom';
import {useAuthentication} from 'store/authentication';

import {AnonymousRoutes} from '../routes';

const Authenticated = () => {
  const {isLoggedIn} = useAuthentication();

  if (isLoggedIn) {
    return <Outlet />;
  } else {
    return <Navigate to={AnonymousRoutes.LOGIN} replace />;
  }
};

export default React.memo(Authenticated);
