import React, {useMemo} from 'react';

import {Button} from 'components/atoms/Button';
import {IconNames} from 'components/atoms/IconSVG/interfaces';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {UploadOutcomeModalProps} from './definitions';

const UploadOutcomeModal = ({
  onClose,
  uploadOutcome,
  ...props
}: UploadOutcomeModalProps) => {
  const {t} = useTranslations();

  const outcomeProps = useMemo(() => {
    let icon: IconNames;
    let content: string;
    let label: string;

    switch (uploadOutcome) {
      case 'success':
        icon = 'upload';
        content = t('Swatches.uploadModals.successModal.message');
        label = t('Swatches.uploadModals.successModal.confirm');
        break;
      case 'error':
        icon = 'error';
        content = t('Swatches.uploadModals.errorModal.message');
        label = t('Swatches.uploadModals.errorModal.confirm');
        break;
    }

    return {
      icon,
      content,
      label,
    };
  }, [t, uploadOutcome]);

  return (
    <Modal
      {...props}
      headerIcon={outcomeProps.icon}
      content={outcomeProps.content}
      actions={[
        <Button
          key={`upload_${outcomeProps}_modal`}
          variant="primary"
          onClick={onClose}>
          {outcomeProps.label}
        </Button>,
      ]}
    />
  );
};

export default UploadOutcomeModal;
