import React, {useCallback, useMemo, useState} from 'react';

import {FormControlLabel} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Checkbox} from 'components/atoms/Checkbox';
import {Switch} from 'components/atoms/Switch';
import {Filters} from 'components/molecules/Filters';
import {FiltersProps} from 'components/molecules/Filters/definitions';
import {Table} from 'components/molecules/Table';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {ChangeAllVisibilityModal} from 'components/organisms/ChangeAllVisibilityModal';
import {ChangeVisibilityModal} from 'components/organisms/ChangeVisibilityModal';
import {useFilterContext} from 'contexts';
import {usePageContext} from 'contexts/page/PageContext';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {useVisibility} from 'store/pageSwitch/visibility';
import {useTranslations} from 'vidiemme/react-i18n';

import {VisibilityRow, VisibilityTableProps} from './definitions';

const VisibilityTable = ({customizationId}: VisibilityTableProps) => {
  const {t} = useTranslations();

  const {
    currentPage: {id: currentPageId},
  } = usePageContext();

  const [changeVisibilityModalOpened, setChangeVisibilityModalOpened] =
    useState<boolean>(false);

  const [makeAllVisible, setMakeAllVisible] = useState<boolean | undefined>();

  const {
    useGetVisibleIdList,
    useGetVisbility,
    useUpdateAllVisibility,
    useUpdateVisibility,
  } = useVisibility();

  const {filters} = useFilterContext();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const selectedRows = useMemo(() => {
    return Object.keys(rowSelection);
  }, [rowSelection]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const paramFilters = useMemo(() => {
    if (filters) {
      const parsedFilters = JSON.parse(filters);
      if ('visibility' in parsedFilters) {
        const map: {[key: string]: boolean} = {
          visible: true,
          notVisible: false,
        };
        parsedFilters.visibility = map[parsedFilters.visibility];
      }
      return parsedFilters;
    }

    return {};
  }, [filters]);

  const paramSorting = useMemo<{
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
  }>(() => {
    const sortingParam = sorting.length > 0 && sorting[0];
    if (sortingParam) {
      return {
        sortBy: sortingParam.id,
        orderBy: sortingParam.desc ? 'DESC' : 'ASC',
      };
    } else {
      return {};
    }
  }, [sorting]);

  const {
    data: visibleIdListResponse,
    isLoading: isLoadingVisibleIdLiat,
    isFetched,
  } = useGetVisibleIdList({
    customizationId,
    currentPageId,
  });

  const visibleIdList = useMemo(() => {
    return visibleIdListResponse?.data.visibility ?? [];
  }, [visibleIdListResponse?.data.visibility]);

  const allVisible = useMemo(() => {
    return visibleIdList[0] === '*';
  }, [visibleIdList]);

  const {data: visibilityValuesResponse, isLoading: isLoadingVisibility} =
    useGetVisbility(
      {
        customizationId,
        currentPageId,
        options: {
          includeVisibility: false,
          page: pagination.pageIndex + 1,
          limit: pagination.pageSize,
          ...paramSorting,
          ...paramFilters,
        },
      },
      {enabled: isFetched && !allVisible},
    );

  const totalVisibilityValues = useMemo(() => {
    return visibilityValuesResponse?.data.values.totalCount ?? 0;
  }, [visibilityValuesResponse?.data.values.totalCount]);

  const visibilityValues = useMemo<Array<VisibilityRow>>(() => {
    if (!visibilityValuesResponse) {
      return [];
    }

    return visibilityValuesResponse.data.values.values.map(item => {
      return {
        ...item,
        visible:
          visibleIdList[0] === '*' ? true : visibleIdList.includes(item.id),
      };
    });
  }, [visibilityValuesResponse, visibleIdList]);

  const {
    mutateAsync: callUpdateAllVisibility,
    isPending: isUpdatingAllVisibility,
  } = useUpdateAllVisibility();

  const {mutateAsync: callUpdateVisibility, isPending: isUpdatingVisibility} =
    useUpdateVisibility();

  const handleUpdateVisibility = useCallback(
    (idList: Array<string>, makeVisible: boolean) => {
      const newVisibleIdList = [...visibleIdList];

      idList.forEach(id => {
        const itemIndex = newVisibleIdList.indexOf(id);
        if (makeVisible) {
          if (itemIndex === -1) {
            newVisibleIdList.push(id);
          }
        } else {
          if (itemIndex !== -1) {
            newVisibleIdList.splice(itemIndex, 1);
          }
        }
      });

      callUpdateVisibility({
        customizationId,
        currentPageId,
        params: {
          visibility: newVisibleIdList,
        },
      });
    },
    [callUpdateVisibility, currentPageId, customizationId, visibleIdList],
  );

  const handleVisibilityChange = useCallback(
    (id: string, makeVisible: boolean) => {
      handleUpdateVisibility([id], makeVisible);
    },
    [handleUpdateVisibility],
  );

  const handleBulkVisibilityChange = useCallback(
    (makeVisible: boolean) => {
      setChangeVisibilityModalOpened(false);
      handleUpdateVisibility(selectedRows, makeVisible);
      setRowSelection({});
    },
    [handleUpdateVisibility, selectedRows],
  );

  const handleAllVisibilityChange = useCallback(() => {
    if (makeAllVisible !== undefined) {
      callUpdateAllVisibility({
        customizationId,
        currentPageId,
        makeAllVisible,
      }).finally(() => setMakeAllVisible(undefined));
    }
  }, [callUpdateAllVisibility, currentPageId, customizationId, makeAllVisible]);

  const filterList = useMemo<FiltersProps['filterList']>(() => {
    return [
      {
        id: 'id',
        name: 'id',
        label: t('Filters.id'),
      },
      {
        id: 'name',
        name: 'name',
        label: t('Filters.name'),
      },
      {
        id: 'visibility',
        name: 'visibility',
        label: t('Filters.visibility'),
        type: 'select',
        options: [
          {
            value: 'visible',
            label: t('Visibility.visibilityOptions.visible'),
          },
          {
            value: 'notVisible',
            label: t('Visibility.visibilityOptions.notVisible'),
          },
        ],
      },
    ];
  }, [t]);

  const columns = useMemo<MRT_ColumnDef<VisibilityRow>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('Visibility.table.id'),
      },
      {
        accessorKey: 'name',
        header: t('Visibility.table.name'),
        Cell: ({cell}) => {
          return (
            <TextWithRoundedImage
              image={cell.row.original.image}
              imageFromSketch={cell.row.original.id}
              text={cell?.getValue<string>()}
            />
          );
        },
      },
      {
        accessorKey: 'visible',
        header: t('Visibility.table.visibility'),
        enableSorting: false,
        Cell: ({cell}) => {
          return (
            <Switch
              checked={cell.getValue<boolean>()}
              onChange={(_, checked) =>
                handleVisibilityChange(cell.row.original.id, checked)
              }
            />
          );
        },
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
    ],
    [handleVisibilityChange, t],
  );

  const tableFooter = useMemo(() => {
    return (
      <div className="table-footer-buttons  sm:px-5 2xl:px-0  flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full">
        <div className="flex justify-between py-5 w-full">
          <div className="flex items-center gap-5" />
          <div className="flex items-center gap-5">
            <Button
              onClick={() => setChangeVisibilityModalOpened(true)}
              iconLeft={'add'}
              variant={'primary'}
              disabled={selectedRows.length === 0}
              count={selectedRows.length}>
              {t('Visibility.buttons.changeVisibility')}
            </Button>
          </div>
        </div>
      </div>
    );
  }, [selectedRows.length, t]);

  const table = useMemo(() => {
    return (
      <Table
        pageSize={pagination.pageSize}
        currentPage={pagination.pageIndex}
        totalRows={totalVisibilityValues}
        tableOptions={{
          getRowId: originalRow => originalRow.id,
          enableRowSelection: true,
          columns,
          rowCount: totalVisibilityValues,
          data: visibilityValues,
          state: {
            sorting,
            rowSelection,
            pagination,
            isLoading: isLoadingVisibleIdLiat || isLoadingVisibility,
            showLoadingOverlay: isUpdatingVisibility,
          },
          onSortingChange: setSorting,
          onRowSelectionChange: setRowSelection,
          onPaginationChange: setPagination,
        }}
        tableFooter={tableFooter}
      />
    );
  }, [
    columns,
    isLoadingVisibility,
    isLoadingVisibleIdLiat,
    isUpdatingVisibility,
    pagination,
    rowSelection,
    sorting,
    tableFooter,
    totalVisibilityValues,
    visibilityValues,
  ]);

  return (
    <>
      <div className="2xl:container mx-auto py-5">
        <div className="flex flex-row gap-[30px] items-end w-full">
          <FormControlLabel
            className="m-0 min-h-[40px]"
            slotProps={{
              typography: {
                className: 'text-regular',
              },
            }}
            label={t('Visibility.onForAll')}
            checked={allVisible}
            onChange={(_, checked) => {
              setMakeAllVisible(checked);
            }}
            control={<Checkbox />}
          />
          {!allVisible && (
            <div className="flex-1 max-w-[750px]">
              <Filters filterList={filterList} />
            </div>
          )}
        </div>
      </div>
      {!allVisible && visibilityValues && (
        <>
          {table}
          <ChangeVisibilityModal
            open={changeVisibilityModalOpened}
            onClose={() => setChangeVisibilityModalOpened(false)}
            onSwitchOff={() => handleBulkVisibilityChange(false)}
            onSwitchOn={() => handleBulkVisibilityChange(true)}
          />
        </>
      )}
      <ChangeAllVisibilityModal
        open={makeAllVisible !== undefined}
        onClose={(_, reason) => {
          if (
            (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
            isUpdatingAllVisibility
          ) {
            return;
          }
          setMakeAllVisible(undefined);
        }}
        onCancel={() => setMakeAllVisible(undefined)}
        onConfirm={handleAllVisibilityChange}
        isSaving={isUpdatingAllVisibility}
      />
    </>
  );
};

export default React.memo(VisibilityTable);
