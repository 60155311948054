import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useQuery} from '@tanstack/react-query';
import {Button} from 'components/atoms/Button';
import {Tooltip} from 'components/atoms/Tooltip';
import {Filters} from 'components/molecules/Filters';
import {MainNavigation} from 'components/molecules/MainNavigation';
import {Table} from 'components/molecules/Table';
import {RowAction} from 'components/molecules/Table/definitions';
import {CloneModal} from 'components/organisms/CloneModal';
import CloneErrorModal from 'components/organisms/CloneModal/CloneErrorModal';
import CloneSuccessModal from 'components/organisms/CloneModal/CloneSuccessModal';
import {FormInputs, Inputs} from 'components/organisms/CloneModal/interfaces';
import {ImportTemplateModal} from 'components/organisms/ImportTemplateModal';
import UploadErrorModal from 'components/organisms/ImportTemplateModal/UploadErrorModal';
import UploadSuccessModal from 'components/organisms/ImportTemplateModal/UploadSuccessModal';
import {SORTING} from 'components/pages/Template/definitions';
import {Base} from 'components/templates/Base';
import {useFilterContext} from 'contexts/filter/FiltersContext';
import {useUtils} from 'hooks';
import {MRT_RowData, MRT_RowSelectionState} from 'material-react-table';
import {AuthRoutes, useNavigation} from 'navigation';
import {FormProvider, useForm} from 'react-hook-form';
import {useTemplates} from 'store/templates';
import {FiltersParams, TemplateQueryKeys} from 'store/templates/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

const Template = (): ReactElement => {
  const {t} = useTranslations();

  //Rows Data
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available
  const [rowData, setRowData] = useState<MRT_RowData>({});

  //useForm hook
  const formMethods = useForm<FormInputs>();
  const {
    handleSubmit,
    formState: {isValid, isSubmitting},
    watch,
    reset: resetCloneForm,
  } = formMethods;

  const [fileUpload, setFileUpload] = useState<File[] | undefined>();

  const {qsFilters, resetFilters, filters} = useFilterContext();
  const {goToPathWithParams} = useNavigation();
  const {setStorage} = useUtils();

  //Get data - Call Api
  const {
    useGetTemplatesQuery,
    useGetFiltersQuery,
    cloneTemplateAsync,
    getExportValues,
    uploadTemplateAsync,
  } = useTemplates();

  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });

  //Sorting params
  const [sorting, setSorting] = useState<
    Array<{id: string; desc: boolean}> | []
  >([
    {id: 'season', desc: true},
    {id: 'identifier', desc: false},
  ]);
  const initialSorting = {
    sortBy: ['season', 'identifier'],
    orderBy: [SORTING.DESC, SORTING.ASC],
  };
  const [paramSorting, setParamSorting] = useState<{
    sortBy: Array<string>;
    orderBy: Array<SORTING.ASC | SORTING.DESC>;
  }>(initialSorting);

  const [enableRowDownload, setEnableRowDownload] = useState<boolean>(false);
  const [downloadIds, setDownloadIds] = useState<Array<string>>([]);

  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  const {data: templates, refetch} = useGetTemplatesQuery({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    ...paramSorting,
    ...(paramFilters || {}),
  });
  const {data: templatesFilters} = useGetFiltersQuery();

  const refetchTemplates = useCallback(async () => {
    await refetch();
  }, [refetch]);

  useEffect(() => {
    refetchTemplates();
  }, [qsFilters, refetchTemplates]);

  const multipleDownload = useCallback(() => {
    Object.keys(rowSelection).length > 0 &&
      setDownloadIds(Object.keys(rowSelection));
  }, [rowSelection]);

  useEffect(() => {
    rowData._id !== undefined &&
      enableRowDownload &&
      setDownloadIds([rowData._id]);
  }, [enableRowDownload, rowData]);

  const {refetch: refetchDownload, isSuccess: isSuccessDownload} = useQuery({
    queryKey: [
      TemplateQueryKeys.TEMPLATES_QUERY_EXPORT_KEY,
      JSON.stringify(downloadIds),
    ],
    queryFn: async () => getExportValues(downloadIds),
    enabled: downloadIds.length > 0,
  });

  useEffect(() => {
    if (isSuccessDownload) {
      setRowSelection({});
      setEnableRowDownload(false);
    }
  }, [isSuccessDownload]);

  useEffect(() => {
    if (downloadIds.length > 0) {
      refetchDownload();
    }
  }, [downloadIds.length, refetchDownload]);

  //Clone Modal
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false);
  const [openCloneSuccessModal, setOpenCloneSuccessModal] =
    useState<boolean>(false);
  const [openCloneErrorModal, setOpenCloneErrorModal] =
    useState<boolean>(false);

  const subDepartments = watch('subDepartments');
  const arraySubDepartments: string[] = useMemo(() => {
    return subDepartments
      ? String(subDepartments).split(' ')
      : [subDepartments];
  }, [subDepartments]);

  const initialValuesClone: FormInputs = useMemo(() => {
    return {
      name: '',
      department: '',
      subDepartments: [],
      season: '',
      event: '',
      clone: '',
    };
  }, []);

  const onSubmit = useCallback(
    async (data: FormInputs) => {
      try {
        await cloneTemplateAsync(
          {
            ...data,
            clone: rowData._id,
            subDepartments: arraySubDepartments,
          },
          {
            onSuccess: () => {
              setOpenCloneSuccessModal(true);
              setOpenCloneModal(false);
              resetCloneForm(initialValuesClone);
            },
            onError: () => {
              setOpenCloneModal(false);
              setOpenCloneErrorModal(true);
              resetCloneForm(initialValuesClone);
            },
          },
        );
      } catch (e) {
        setOpenCloneModal(false);
        setOpenCloneErrorModal(true);
        resetCloneForm(initialValuesClone);
      }
    },
    [
      cloneTemplateAsync,
      rowData._id,
      arraySubDepartments,
      resetCloneForm,
      initialValuesClone,
    ],
  );

  const inputs: Inputs[] = useMemo(() => {
    return [
      {
        name: 'name',
        placeholder: t('Template.cloneModal.inputs.name'),
        required: true,
      },
      {
        name: 'department',
        placeholder: t('Template.cloneModal.inputs.department'),
        required: true,
        type: 'number',
      },
      {
        name: 'subDepartments',
        placeholder: t('Template.cloneModal.inputs.sub_departments'),
        required: false,
      },
      {
        name: 'season',
        placeholder: t('Template.cloneModal.inputs.season'),
        required: true,
      },
      {
        name: 'event',
        placeholder: t('Template.cloneModal.inputs.event'),
        required: true,
      },
    ];
  }, [t]);

  //Upload Modal
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openUploadSuccessModal, setOpenUploadSuccessModal] =
    useState<boolean>(false);
  const [openUploadErrorModal, setOpenUploadErrorModal] =
    useState<boolean>(false);

  const handleConfirm = useCallback(async () => {
    try {
      fileUpload &&
        (await uploadTemplateAsync(
          {
            customizationId: rowData._id,
            file: fileUpload[0],
          },
          {
            onSuccess: () => {
              setFileUpload(undefined);
              setOpenUploadModal(false);
              setOpenUploadSuccessModal(true);
            },
          },
        ));
    } catch (e) {
      setOpenUploadModal(false);
      setOpenUploadErrorModal(true);
    }
  }, [fileUpload, rowData._id, uploadTemplateAsync]);

  const actions: RowAction<any>[] = useMemo(() => {
    return [
      {
        label: t('Table.clone'),
        icon: 'copy',
        onClick: () => setOpenCloneModal(true),
      },
      {
        icon: 'add-check',
        label: t('Table.models'),
        onClick: currentRow => {
          setStorage(
            'currentTemplateName',
            JSON.stringify({currentName: currentRow?.name}),
          );
          goToPathWithParams(AuthRoutes.MODELS, {
            customizationId: String(currentRow?._id),
          });
        },
      },
      {
        label: t('Table.settings'),
        icon: 'settings',
        onClick: currentRow => {
          setStorage(
            'currentTemplateName',
            JSON.stringify({currentName: currentRow?.name}),
          );
          goToPathWithParams(AuthRoutes.PAGES_SWITCH, {
            customizationId: String(currentRow?._id),
          });
        },
      },
      {
        label: t('Table.download'),
        icon: 'download',
        onClick: () => {
          setEnableRowDownload(true);
        },
      },
      {
        label: t('Table.upload'),
        icon: 'upload',
        onClick: () => setOpenUploadModal(true),
      },
    ];
  }, [goToPathWithParams, setStorage, t]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'identifier', //access nested data with dot notation
        header: t('Template.table.identifier'),
        title: undefined,
        Cell: ({renderedCellValue}: {renderedCellValue: string[]}) => (
          <p className="font-bold">{renderedCellValue}</p>
        ),
      },
      {
        accessorKey: 'name',
        header: t('Template.table.name'),
        minSize: 300,
      },
      {
        accessorKey: 'departments', //normal accessorKey
        header: t('Template.table.departmentShort'),
        maxSize: 100,
      },
      {
        accessorKey: 'subDepartments',
        header: t('Template.table.subDepartmentShort'),
        Cell: ({renderedCellValue}: {renderedCellValue: string[]}) => {
          return renderedCellValue.length > 3 ? (
            <Tooltip
              placement="top"
              key={`${renderedCellValue}`}
              title={renderedCellValue.join(' ')}>
              <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
                {renderedCellValue.join(' ')}
              </p>
            </Tooltip>
          ) : (
            <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis">
              {renderedCellValue.join(' ')}
            </p>
          );
        },
      },
      {
        accessorKey: 'season',
        header: t('Template.table.season'),
        maxSize: 140,
      },
      {
        accessorKey: 'event',
        header: t('Template.table.event'),
        maxSize: 100,
      },
      {
        accessorKey: 'catalogues',
        header: t('Template.table.catalogues'),
        enableSorting: false, // disable sorting for this column
        // @ts-expect-error no type from doc
        Cell: ({renderedCellValue}) => {
          return (
            <div>
              {renderedCellValue.map(
                (singleValue: {_id: string; name: string}) => {
                  return <p key={singleValue._id}>{singleValue.name}</p>;
                },
              )}
            </div>
          );
        },
      },
    ];
  }, [t]);

  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          sortBy: sorting.map(singleSorting => {
            return singleSorting.id;
          }),
          orderBy: sorting.map(singleSorting => {
            return singleSorting.desc ? SORTING.DESC : SORTING.ASC;
          }),
        });
    }
  }, [sorting]);

  const handleFileSelected = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: {files},
      } = e;
      files?.length && setFileUpload(Array.from(files));
    },
    [],
  );

  const tableFooter = useMemo(() => {
    return (
      <div className="table-footer-buttons  sm:px-5 2xl:px-0 flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full">
        <div className="flex justify-end py-5 w-full">
          <Button
            onClick={() => {
              multipleDownload();
            }}
            iconLeft={'copy'}
            variant={'primary'}
            disabled={Object.keys(rowSelection).length === 0}
            count={Object.keys(rowSelection).length}>
            {t('Template.buttons.downloadButton')}
          </Button>
        </div>
      </div>
    );
  }, [multipleDownload, rowSelection, t]);

  return (
    <Base>
      <MainNavigation
        navigationElements={[{label: t('Navigation.template'), isActive: true}]}
      />
      {templatesFilters && (
        <div className="2xl:container mx-auto py-5">
          <Filters
            makeResetRequest={() => {
              resetFilters();
              setTimeout(() => {
                refetch();
              });
            }}
            filterList={[
              {
                id: 'identifier',
                name: 'identifier',
                label: t('Template.table.identifier'),
              },
              {
                id: 'name',
                name: 'name',
                label: t('Template.table.name'),
              },
              {
                id: 'departments',
                name: 'departments',
                label: t('Template.table.department'),
                type: 'select',
                options: templatesFilters.data.filters.departments,
              },
              {
                id: 'subDepartments',
                name: 'subDepartments',
                label: t('Template.table.subDepartment'),
                type: 'select',
                options: templatesFilters.data.filters.subDepartments,
              },
              {
                id: 'season',
                name: 'season',
                label: t('Template.table.season'),
                type: 'select',
                options: templatesFilters.data.filters.seasons,
              },
              {
                id: 'event',
                name: 'event',
                label: t('Template.table.event'),
                type: 'select',
                options: templatesFilters.data.filters.event,
              },
              {
                id: 'catalogueId',
                name: 'catalogueId',
                label: t('Template.table.catalogues'),
                type: 'select',
                options: templatesFilters.data.filters.catalogues,
              },
            ]}
          />
        </div>
      )}
      {templates && templates.data && (
        <Table
          actions={actions}
          setRowData={setRowData}
          tableFooter={tableFooter}
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={templates.data.totalCount}
          tableOptions={{
            getRowId: (originalRow: any) => originalRow._id,
            enableRowSelection: true,
            columns,
            rowCount: templates.data.totalCount,
            data: templates?.data?.customizations,
            manualSorting: true,
            initialState: {pagination: pagination},
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
          }}
        />
      )}
      <FormProvider {...formMethods}>
        <CloneModal
          onClose={() => {
            setOpenCloneModal(false);
            resetCloneForm(initialValuesClone);
          }}
          onConfirm={handleSubmit(onSubmit)}
          inputs={inputs}
          open={openCloneModal}
          confirmDisabled={!isValid || isSubmitting}
        />
      </FormProvider>
      <CloneSuccessModal
        open={openCloneSuccessModal}
        onClose={() => setOpenCloneSuccessModal(false)}
      />
      <CloneErrorModal
        open={openCloneErrorModal}
        onClose={() => setOpenCloneErrorModal(false)}
      />
      {/* Upload Modals: */}
      <ImportTemplateModal
        handleFileSelected={handleFileSelected}
        open={openUploadModal}
        onClose={() => {
          setOpenUploadModal(false);
          setFileUpload(undefined);
        }}
        onConfirm={handleConfirm}
        confirmDisabled={!fileUpload}
        fileName={(fileUpload && fileUpload[0].name) || ''}
      />
      <UploadSuccessModal
        open={openUploadSuccessModal}
        onClose={() => setOpenUploadSuccessModal(false)}
      />
      <UploadErrorModal
        open={openUploadErrorModal}
        onClose={() => setOpenUploadErrorModal(false)}
      />
    </Base>
  );
};

export default React.memo(Template);
