import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  FiltersContextProps,
  FiltersProviderProps,
} from 'contexts/filter/interfaces';

export const FiltersContext = createContext<FiltersContextProps>({
  filters: '',
  qsFilters: '',
  setFilters: () => undefined,
  resetFilters: () => undefined,
  setPageId: () => undefined,
  currentPageId: '',
  setPageName: () => undefined,
  currentPageName: '',
});

export const FiltersProvider = memo(({children}: FiltersProviderProps) => {
  const [filters, setFilters] = useState<string>('');
  const [qsFilters, setQsFilters] = useState<string>('');
  const [currentPageId, setCurrentPageId] = useState<string>('');
  const [currentPageName, setCurrentPageName] = useState<string>('');

  const setQsFilterCallback = useCallback((newFilter: string) => {
    setFilters(newFilter);
  }, []);

  const setCurrentPageIdCallback = useCallback((newPageId: string) => {
    setCurrentPageId(newPageId);
  }, []);

  const setCurrentPageNameCallback = useCallback((newPageName: string) => {
    setCurrentPageName(newPageName);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters('');
    setQsFilters('');
  }, []);

  useEffect(() => {
    const filterParsed = filters && JSON.parse(filters);
    filterParsed && setQsFilters(new URLSearchParams(filterParsed).toString());
  }, [filters]);

  const memoizedValue = useMemo<FiltersContextProps>(
    () => ({
      filters,
      qsFilters,
      setFilters: setQsFilterCallback,
      resetFilters,
      setPageId: setCurrentPageIdCallback,
      currentPageId,
      currentPageName,
      setPageName: setCurrentPageNameCallback,
    }),
    [
      currentPageId,
      currentPageName,
      filters,
      qsFilters,
      resetFilters,
      setCurrentPageIdCallback,
      setCurrentPageNameCallback,
      setQsFilterCallback,
    ],
  );

  FiltersProvider.displayName = 'FiltersProvider';
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <FiltersContext.Provider value={memoizedValue}>
      {children}
    </FiltersContext.Provider>
  );
});
export const useFilterContext = (): FiltersContextProps =>
  useContext(FiltersContext);
