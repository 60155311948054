import {useCallback} from 'react';

import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {FormInputs} from 'components/organisms/CloneModal/interfaces';
import {useUtils} from 'hooks/index';

import {useAPI} from '../api';

import {CustomizationsParams, TemplateQueryKeys} from './definitions';

export const useTemplates = () => {
  const {api} = useAPI();
  const {customError} = useUtils();

  const getTemplates = useCallback(
    async ({...params}: CustomizationsParams) => {
      try {
        const {data} = await api.get('/api/v1/customizations', {
          params,
        });
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getFilters = useCallback(async () => {
    try {
      const {data} = await api.get('/api/v1/customizations/filters');
      return data;
    } catch (error) {
      customError(error as Error);
      if (error instanceof AxiosError && error?.response) {
        throw error;
      }
    }
  }, [api, customError]);

  const uploadTemplate = useCallback(
    async (params: {customizationId: string; file: File}) => {
      try {
        const {data} = await api.put(
          `/api/v1/customizations/${params.customizationId}/values`,
          {values: params.file},
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getExportValues = useCallback(
    async (idList: Array<string>) => {
      const idListQs = idList.map(singleId => {
        return `&customizationId[]=${singleId}`;
      });
      try {
        const {data, headers} = await api.get(
          `/api/v1/customizations/export?data=values${idListQs.join('')}`,
          {
            responseType: 'blob',
            headers: {Accept: '*'},
          },
        );

        const url = URL.createObjectURL(
          new Blob([data], {type: headers['content-type']}),
        );
        const link = document.createElement('a');
        link.href = url;
        // Extract filename from content-disposition
        const fileNameRegex = /filename="(?<filename>.*)"/;
        link.download =
          fileNameRegex.exec(headers['content-disposition'])?.groups
            ?.filename ?? 'File';
        link.click();
        /**
         * The URL.revokeObjectURL() static method releases an existing object URL which was previously created by calling URL.createObjectURL().
         * Call this method when you've finished using an object URL to let the browser know not to keep the reference to the file any longer.
         */
        URL.revokeObjectURL(url);
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const cloneTemplate = useCallback(
    async (params: FormInputs) => {
      try {
        const {data} = await api.post('/api/v1/customizations', {
          ...params,
        });
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  //Queries
  const useGetTemplatesQuery = ({...params}: CustomizationsParams) =>
    useQuery({
      queryKey: [TemplateQueryKeys.TEMPLATES_QUERY_KEY, params],
      queryFn: async () => getTemplates({...params}),
    });
  const useGetFiltersQuery = () =>
    useQuery({
      queryKey: [TemplateQueryKeys.TEMPLATES_QUERY_FILTERS_KEY],
      queryFn: async () => getFilters(),
    });

  //Mutations
  const {mutateAsync: cloneTemplateAsync, isPending: isLoadingCloneTemplate} =
    useMutation({mutationFn: cloneTemplate});

  const {mutateAsync: uploadTemplateAsync} = useMutation({
    mutationFn: uploadTemplate,
  });

  return {
    useGetTemplatesQuery,
    useGetFiltersQuery,
    cloneTemplateAsync,
    isLoadingCloneTemplate,
    getExportValues,
    uploadTemplateAsync,
  };
};
