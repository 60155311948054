import {useCallback} from 'react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks';
import {useAPI} from 'store/api';

import {
  GetVisibilityParams,
  GetVisibilityResponse,
  GetVisibleIdListParams,
  GetVisibleIdListResponse,
  UpdateVisibilityParams,
  VisibilityQueryKeys,
} from './definitions';

export const useVisibility = () => {
  const {api} = useAPI();
  const {customError} = useUtils();
  const queryClient = useQueryClient();

  const getVisibleIdList = useCallback(
    async ({customizationId, currentPageId}: GetVisibleIdListParams) => {
      try {
        const {data} = await api.get<GetVisibleIdListResponse>(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/visibility`,
          {
            params: {
              includeValues: false,
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getVisibility = useCallback(
    async ({customizationId, currentPageId, options}: GetVisibilityParams) => {
      try {
        const {data} = await api.get<GetVisibilityResponse>(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/visibility`,
          {params: options},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const updateVisibility = useCallback(
    async ({
      customizationId,
      currentPageId,
      params,
    }: UpdateVisibilityParams) => {
      try {
        const {data} = await api.patch(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/visibility`,
          params,
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const useGetVisibleIdList = (params: GetVisibleIdListParams) =>
    useQuery({
      queryKey: [VisibilityQueryKeys.GET_VISIBLE_ID_LIST_QUERY_KEY, params],
      queryFn: async () => getVisibleIdList(params),
    });

  const useGetVisbility = (
    params: GetVisibilityParams,
    options?: {enabled?: boolean},
  ) =>
    useQuery({
      queryKey: [VisibilityQueryKeys.GET_VISIBILITY_QUERY_KEY, params],
      queryFn: async () => getVisibility(params),
      ...options,
    });

  const useUpdateAllVisibility = () =>
    useMutation({
      mutationFn: ({
        customizationId,
        currentPageId,
        makeAllVisible,
      }: Pick<UpdateVisibilityParams, 'customizationId' | 'currentPageId'> & {
        makeAllVisible: boolean;
      }) =>
        updateVisibility({
          customizationId,
          currentPageId,
          params: {
            visibility: makeAllVisible ? ['*'] : [],
          },
        }),
      mutationKey: [VisibilityQueryKeys.UPDATE_VISIBILITY_KEY],
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries({
          queryKey: [
            VisibilityQueryKeys.GET_VISIBLE_ID_LIST_QUERY_KEY,
            {
              customizationId: variables.customizationId,
              currentPageId: variables.currentPageId,
            },
          ],
        });
      },
    });

  const useUpdateVisibility = () =>
    useMutation({
      mutationFn: updateVisibility,
      mutationKey: [VisibilityQueryKeys.UPDATE_VISIBILITY_KEY],
      onMutate: async variables => {
        const visibleIdListQueryKey = [
          VisibilityQueryKeys.GET_VISIBLE_ID_LIST_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ];

        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: visibleIdListQueryKey,
        });

        const previousData = queryClient.getQueryData<GetVisibleIdListResponse>(
          visibleIdListQueryKey,
        );

        // Optimistically update to the new value
        queryClient.setQueryData<GetVisibleIdListResponse>(
          visibleIdListQueryKey,
          {
            data: {
              visibility: variables.params.visibility,
            },
          },
        );

        // Return a context object with the snapshotted value
        return {previousData};
      },
      onError: (err, variables, context) => {
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        if (context?.previousData) {
          queryClient.setQueryData<GetVisibleIdListResponse>(
            [
              VisibilityQueryKeys.GET_VISIBLE_ID_LIST_QUERY_KEY,
              {
                customizationId: variables.customizationId,
                currentPageId: variables.currentPageId,
              },
            ],
            context.previousData,
          );
        }
      },
      onSettled: (data, error, variables) => {
        // Refresh values (only current query since is coming from an update)
        queryClient.invalidateQueries({
          queryKey: [
            VisibilityQueryKeys.GET_VISIBLE_ID_LIST_QUERY_KEY,
            {
              customizationId: variables.customizationId,
              currentPageId: variables.currentPageId,
            },
          ],
        });
      },
    });

  return {
    useGetVisibleIdList,
    useGetVisbility,
    useUpdateAllVisibility,
    useUpdateVisibility,
  };
};
