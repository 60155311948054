import React from 'react';

import {createTheme, ThemeProvider as MuiTheme} from '@mui/material';
import * as Sentry from '@sentry/react';
import {IconSVG} from 'components/atoms/IconSVG';
import {Navigation} from 'navigation';
import {createRoot} from 'react-dom/client';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reset-css';
import {ThemeProvider} from 'themes';
import {en} from 'translations';
import {TranslationsProvider} from 'vidiemme/react-i18n';

import {ApiProvider, FiltersProvider} from './contexts';
import './hooks/toast/Toast.scss';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing(),
      //ts-expected-error
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        blockAllMedia: true,
        maskAllText: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const theme = createTheme({
  typography: {
    fontFamily: 'Gucci Sans Book',
    fontSize: 14,
  },
  palette: {
    primary: {
      main: '#4B000A',
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:hover': {
            backgroundColor: '#F6EFE9',
          },
          '& .MuiTableRow-root:hover td': {
            backgroundColor: '#F6EFE9',
          },
        },
      },
    },
    MuiBackdrop: {
      defaultProps: {
        color: '#F6EFE9',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
        icon: <IconSVG icon="unchecked-checkbox" size={24} />,
        checkedIcon: <IconSVG icon="checked-checkbox" size={24} />,
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
        icon: <IconSVG icon="unchecked-checkbox" size={24} />,
        checkedIcon: <IconSVG icon="checked-checkbox" size={24} />,
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          width: 48,
          height: 24,
          '.MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(24px)',
          },
        },
        track: {
          backgroundColor: '#FFF',
          opacity: '1 !important',
          border: '2px solid #808080',
          borderRadius: 20,
        },
        thumb: {
          backgroundColor: '#BFBFBF',
          border: '1px solid #FFF',
          boxShadow: 'none',
          width: '18px !important',
          height: '18px !important',
        },
        switchBase: {
          padding: '3px !important',
          height: '100%',
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ApiProvider>
      <MuiTheme theme={createTheme(theme)}>
        <ThemeProvider theme="light">
          <FiltersProvider>
            <TranslationsProvider
              params={{
                resources: {
                  en: {
                    translation: en,
                  },
                },
              }}>
              <Navigation />
              <ToastContainer />
            </TranslationsProvider>
          </FiltersProvider>
        </ThemeProvider>
      </MuiTheme>
    </ApiProvider>
  </React.StrictMode>,
);

if (process.env.REACT_APP_WEB_VITALS === 'true') {
  reportWebVitals();
}
