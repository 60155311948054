import {Model} from 'components/pages/Models/ProposedModels/definitions';

export enum ModelsQueryKeys {
  CURRENT_MODELS_QUERY_KEY = 'CURRENT_MODELS_QUERY_KEY',
  PROPOSED_MODELS_QUERY_KEY = 'PROPOSED_MODELS_QUERY_KEY',
  GET_SKETCH_QUERY_KEY = 'GET_SKETCH_QUERY_KEY',
}

export interface FiltersParams {
  departments?: number | string;
  subDepartments?: number | string;
  season?: number | string;
  event?: string;
  identifier?: number | string;
  name?: string;
}

export interface CustomizationsParams extends FiltersParams {
  page: number;
  limit?: number;
  sortBy?: string;
  orderBy?: string | 'ASC' | 'DESC';
  customizationId: string;
}

export interface CustomizationParams {
  customizationId: string;
  models: Array<string>;
}

export interface AddCustomizationParams {
  customizationId: string;
  models: Array<Partial<Model>>;
}

export interface EditCustomizationParams {
  customizationId: string;
  modelCode: string;
  name: string;
  deliveryDate: string;
  description: string;
}

export type ModelItem = {
  delivery_date: string;
  depCod: string;
  description: string;
  event: string;
  id: string;
  modCod: string;
  modDes: string;
  name: string;
  season: string;
  subDepCod: string;
  unexpected: boolean;
};
