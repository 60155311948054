import React, {ReactElement, useCallback, useState} from 'react';

import {Filters} from 'components/molecules/Filters';
import {RoundedTab} from 'components/molecules/RoundedTab';
import AssignmentsTableCombined from 'components/organisms/AssignmentsTable/AssigmentsTableCombined/AssignmentsTableCombined';
import {AssignmentsTableDependency} from 'components/organisms/AssignmentsTable/AssigmentsTableDependency';
import {AssignmentsTablePrimary} from 'components/organisms/AssignmentsTable/AssigmentsTablePrimary';
import {useFilterContext} from 'contexts';

import './assignments.scss';

import {IProps} from './definitions';

const AssignmentsTable = ({
  customizationId,
  currentPageId,
  currentPage,
  dependencyName,
}: IProps): ReactElement => {
  const [visibleTable, setVisibleTable] = useState<number | undefined>(0);
  const {resetFilters} = useFilterContext();

  const onChangeTabCallback = useCallback(
    (newTabValue: number) => {
      setVisibleTable(undefined);
      resetFilters();
      setTimeout(() => {
        setVisibleTable(newTabValue);
      });
    },
    [resetFilters],
  );

  return (
    <>
      <div className="2xl:container mx-auto py-5">
        <div className="flex flex-row">
          <div className="flex flex-col md:basis-1/2">
            {visibleTable !== undefined && (
              <Filters
                filterList={[
                  {
                    id: 'primary',
                    name: 'primary',
                    label: currentPage.name,
                  },
                  {
                    id: 'dependency',
                    name: 'dependency',
                    label: dependencyName,
                  },
                ]}
                makeResetRequest={(): void => {
                  resetFilters();
                }}
              />
            )}
          </div>
          <div className="flex flex-col md:basis-1/2 justify-end">
            <RoundedTab
              tabList={[currentPage.name, dependencyName, 'combined']}
              onChangeTab={onChangeTabCallback}
            />
          </div>
        </div>
      </div>
      {visibleTable === 0 && (
        <AssignmentsTablePrimary
          customizationId={customizationId}
          currentPageId={currentPageId}
          currentPageName={currentPage.name}
        />
      )}
      {visibleTable === 1 && (
        <AssignmentsTableDependency
          customizationId={customizationId}
          currentPageId={currentPageId}
          dependencyName={dependencyName}
        />
      )}

      {visibleTable === 2 && (
        <AssignmentsTableCombined
          customizationId={customizationId}
          currentPageId={currentPageId}
          dependencyName={dependencyName}
        />
      )}
    </>
  );
};

export default React.memo(AssignmentsTable);
