import React from 'react';

import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {Button} from '../../atoms/Button';

import {ChangeVisibilityModalProps} from './definitions';

const ChangeVisibilityModal = ({
  onSwitchOn,
  onSwitchOff,
  ...props
}: ChangeVisibilityModalProps) => {
  const {t} = useTranslations();

  return (
    <Modal
      headerIcon="eye"
      content={
        <p className="text-left">{t('ChangeVisibilityModal.content')}</p>
      }
      actions={
        <div className="w-full flex flex-row items-center justify-between">
          <Button
            variant="tertiary"
            iconLeft="toggle-off"
            iconSize={24}
            onClick={onSwitchOff}>
            {t('ChangeVisibilityModal.actions.switchOff')}
          </Button>
          <Button
            variant="primary"
            iconLeft="toggle-on"
            iconSize={24}
            onClick={onSwitchOn}>
            {t('ChangeVisibilityModal.actions.switchOn')}
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default React.memo(ChangeVisibilityModal);
