import {ReportHandler} from 'web-vitals';

const logWebVitals: ReportHandler = ({
  name,
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  entries,
  ...restOnPerfEntry
}) => {
  if (
    (name === 'FID' && value > 100) ||
    (name === 'LCP' && value > 2500) ||
    (name === 'CLS' && value > 250) ||
    (name === 'FCP' && value > 1800) ||
    (name === 'TTFB' && value > 600)
  ) {
    console.log('PERFORMANCE WARNING!!!');
    console.table({name, value, entries, ...restOnPerfEntry});
  }
};

const reportWebVitals = () => {
  console.table({
    FID: 'First Input Delay > 100ms',
    LCP: 'Largest Contentful Paint > 2500ms',
    CLS: 'Cumulative Layout Shift > 250ms',
    FCP: 'First Contentful Paint > 1800ms',
    TTFB: 'Time To First Byte > 600ms',
  });

  import('web-vitals').then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
    getCLS(logWebVitals);
    getFID(logWebVitals);
    getFCP(logWebVitals);
    getLCP(logWebVitals);
    getTTFB(logWebVitals);
  });
};

export default reportWebVitals;
