import React from 'react';

import {Input} from 'components/atoms/Input';
import {useTranslations} from 'vidiemme/react-i18n';

import {InfoProps} from './definitions';

const Info = ({disableIdField}: InfoProps) => {
  const {t} = useTranslations();

  return (
    <div className="pb-[30px] border-b border-solid border-tan-0 flex flex-col gap-2.5">
      <p className="uppercase font-bold text-primary-main">
        {t('ValuesSidebar.info')}
      </p>

      <Input
        name="id"
        label={t('ValuesSidebar.fields.id.label')}
        placeholder={t('ValuesSidebar.fields.id.placeholder')}
        required={true}
        disabled={disableIdField}
      />

      <Input
        name="name"
        label={t('ValuesSidebar.fields.name.label')}
        placeholder={t('ValuesSidebar.fields.name.placeholder')}
        required={true}
      />
    </div>
  );
};

export default React.memo(Info);
