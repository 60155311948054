import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Breadcrumb} from 'components/atoms/Breadcrumb';
import {Button} from 'components/atoms/Button';
import {Filters} from 'components/molecules/Filters';
import {MainNavigation} from 'components/molecules/MainNavigation';
import {Table} from 'components/molecules/Table';
import {CellProps, RowAction} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {ConfirmDeleteModal} from 'components/organisms/ConfirmDeleteModal';
import GenericModal from 'components/organisms/GenericModal';
import {ModelFormFields} from 'components/organisms/ModelForm/definitions';
import {ModelsSidebar} from 'components/organisms/ModelsSidebar';
import {ModelsSidebarProps} from 'components/organisms/ModelsSidebar/definitions';
import {Base} from 'components/templates/Base';
import {useFilterContext} from 'contexts';
import {useUtils} from 'hooks';
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {useParams} from 'react-router-dom';
import {useModels} from 'store/models';
import {ModelItem, ModelsQueryKeys} from 'store/models/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {ModelsValues} from '../definitions';

const Models = (): ReactElement => {
  const {t} = useTranslations();

  const {customizationId} = useParams();

  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });

  //Sorting params
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [paramSorting, setParamSorting] = useState<{
    sortBy: string;
    orderBy: 'ASC' | 'DESC';
  }>();

  //Rows Data
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

  const [confirmDeleteModalsOpen, setConfirmDeleteModalsOpen] =
    useState<boolean>(false);

  const [singleDelete, setSingleDelete] = useState<boolean>(false);

  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [successModalOpenSaved, setSuccessModalOpenSaved] =
    useState<boolean>(false);

  const queryClient = useQueryClient();

  const {
    useGetCurrentModels,
    useGetCurrentModelsFilters,
    removeModelsFromCurrentModelsAsync,
    editCurrentModelAsync,
  } = useModels();

  const {filters, resetFilters} = useFilterContext();

  const [updateModelData, setUpdateModelData] = useState<ModelItem | undefined>(
    undefined,
  );

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 100,
    });
    setParamSorting({sortBy: 'codMod', orderBy: 'DESC'});
  }, []);

  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  const {data: models, refetch} = useGetCurrentModels({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    customizationId: customizationId!, //TODO check if exist customizationID
    ...paramSorting,
    ...(paramFilters || {}),
  });

  const {getStorage} = useUtils();

  const currentName = useMemo(() => {
    const storageName = getStorage('currentTemplateName');
    return (
      (storageName && JSON.parse(JSON.parse(storageName)).currentName) || ''
    );
  }, [getStorage]);

  const {data: filtersModels} = useGetCurrentModelsFilters(customizationId!);

  const actions = useMemo<RowAction<ModelsValues>[]>(() => {
    return [
      {
        label: t('Table.unexpected'),
        icon: 'error',
        condition: 'unexpected',
        onClick: () => {},
      },
      {
        icon: 'edit',
        onClick: row => {
          setUpdateModelData(row as ModelItem);
        },
      },
    ];
  }, [t]);

  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          sortBy: sortingParam.id!,
          orderBy: sortingParam?.desc ? 'DESC' : 'ASC',
        });
    }
  }, [sorting]);

  const columns = useMemo<MRT_ColumnDef<ModelsValues>[]>(() => {
    return [
      {
        accessorKey: 'modCod',
        header: t('Models.table.codMod'),
        size: 130,
        grow: false,
      },
      {
        accessorKey: 'nameElements',
        header: t('Models.table.name'),
        minSize: 300,
        Cell: ({cell}) => {
          return (
            <>
              {
                <TextWithRoundedImage
                  imageFromSketch={cell.row.id}
                  text={cell.row.original.name}
                />
              }
            </>
          );
        },
      },
      {
        accessorKey: 'modDes',
        header: t('Models.table.desMod'),
      },
      {
        accessorKey: 'depCod',
        header: t('Models.table.dept'),
        size: 100,
        grow: false,
      },
      {
        accessorKey: 'subDepCod',
        header: t('Models.table.subDept'),
        size: 120,
        grow: false,
      },
      {
        accessorKey: 'season',
        header: t('Models.table.season'),
        size: 120,
        grow: false,
      },
      {
        accessorKey: 'event',
        header: t('Models.table.event'),
        size: 100,
        grow: false,
      },
      {
        accessorKey: 'delivery_date',
        header: t('Models.table.deliveryDate'),
        Cell: (cell: CellProps) => {
          return (
            <p>{`${cell.renderedCellValue ? cell.renderedCellValue : ''} ${
              cell.renderedCellValue ? 'weeks' : '-'
            }   `}</p>
          );
        },
      },
    ];
  }, [t]);

  const deleteSelectedRows = useCallback(
    (selectedRows: Record<string, boolean>) => {
      return removeModelsFromCurrentModelsAsync(
        {
          customizationId: customizationId!,
          models: Object.keys(selectedRows),
        },
        {
          onSuccess: () => {
            setSuccessModalOpen(true);
            setUpdateModelData(undefined);

            queryClient.invalidateQueries({
              queryKey: [ModelsQueryKeys.CURRENT_MODELS_QUERY_KEY],
              exact: false,
              refetchType: 'all',
            });
          },
          onError: () => {
            setErrorModalOpen(true);
          },
        },
      );
    },
    [customizationId, queryClient, removeModelsFromCurrentModelsAsync],
  );

  const handleUpdateModel = (data: ModelFormFields) => {
    if (updateModelData === undefined) {
      return;
    }

    editCurrentModelAsync(
      {
        customizationId: customizationId!,
        modelCode: updateModelData.id,
        ...data,
      },
      {
        onSuccess: () => {
          setSuccessModalOpenSaved(true);
          setUpdateModelData(undefined);

          queryClient.invalidateQueries({
            queryKey: [ModelsQueryKeys.CURRENT_MODELS_QUERY_KEY],
            exact: false,
          });
        },
      },
    );
  };

  const handleDeleteModel = useCallback(() => {
    if (!updateModelData) {
      return;
    }

    deleteSelectedRows({
      [updateModelData.id]: true,
    }).then(() => {
      setUpdateModelData(undefined);
    });
  }, [deleteSelectedRows, updateModelData]);

  const navigationElements = useMemo(() => {
    return [
      {label: t('Navigation.currentModels'), isActive: true},
      {
        label: t('Navigation.proposedModels'),
        url: `/proposed_models/${customizationId}`,
      },
    ];
  }, [customizationId, t]);

  const mainNavigationElem = useMemo(() => {
    return <MainNavigation navigationElements={navigationElements} />;
  }, [navigationElements]);

  const updateModelInitialData = useMemo<
    ModelsSidebarProps['initialData']
  >(() => {
    return updateModelData
      ? {
          name: updateModelData.name,
          deliveryDate: updateModelData.delivery_date,
          description: updateModelData.description,
        }
      : undefined;
  }, [updateModelData]);

  const tableFooter = useMemo(() => {
    return (
      <div className="table-footer-buttons px-5 flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full">
        <div className="flex justify-start py-5 w-full">
          <Button
            onClick={() => {
              setConfirmDeleteModalsOpen(true);
            }}
            iconLeft={'delete'}
            variant={'outline'}
            disabled={Object.keys(rowSelection).length === 0}
            count={Object.keys(rowSelection).length}>
            {t('Models.buttons.remove')}
          </Button>
        </div>
      </div>
    );
  }, [rowSelection, t]);

  const table = useMemo(() => {
    return (
      models && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={models?.data.totalCount}
          actions={actions}
          tableFooter={tableFooter}
          tableOptions={{
            getRowId: (originalRow: any) => originalRow.modCod,
            enableRowSelection: true,
            columns,
            rowCount: models?.data.totalCount,
            data: models?.data.models,
            manualSorting: true,
            initialState: {pagination: pagination},
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
          }}
        />
      )
    );
  }, [
    actions,
    columns,
    models,
    pagination,
    rowSelection,
    sorting,
    tableFooter,
  ]);

  return (
    <Base>
      <Breadcrumb
        breadcrumb={[
          {
            label: t('Navigation.template'),
            link: '/',
          },
          {label: currentName},
          {label: t('Navigation.models')},
        ]}
      />
      {mainNavigationElem}
      {filtersModels && (
        <div className="2xl:container mx-auto py-5">
          <Filters
            makeResetRequest={() => {
              resetFilters();
              setTimeout(() => {
                refetch();
              });
            }}
            filterList={[
              {
                id: 'modCod',
                name: 'modCod',
                label: t('Models.table.codMod'),
              },
              {
                id: 'modDes',
                name: 'modDes',
                label: t('Models.table.desMod'),
              },
              {
                id: 'subDepCod',
                name: 'subDepCod',
                label: t('Models.table.subDept'),
                type: 'select',
                options: filtersModels.data.filters.subDepCod,
              },
            ]}
          />
        </div>
      )}
      {table}
      <ConfirmDeleteModal
        onConfirm={() => {
          setConfirmDeleteModalsOpen(false);
          singleDelete
            ? handleDeleteModel()
            : deleteSelectedRows(rowSelection).then(() => setRowSelection({}));
        }}
        onClose={() => setConfirmDeleteModalsOpen(false)}
        open={confirmDeleteModalsOpen}
        text={
          singleDelete
            ? t('Models.confirmDeleteModal.singleDeleteSubtitle')
            : t('Models.confirmDeleteModal.multipleDeleteSubtitle')
        }
      />

      <GenericModal
        onClose={() => setSuccessModalOpen(false)}
        open={successModalOpen}
        content={t('Models.successModal.deleteSuccessMessage')}
        iconName={'delete'}
      />

      <GenericModal
        onClose={() => setSuccessModalOpenSaved(false)}
        open={successModalOpenSaved}
        content={t('Models.successModal.message')}
        iconName={'save'}
      />
      {/* Error Modal*/}
      <GenericModal
        onClose={() => setErrorModalOpen(false)}
        open={errorModalOpen}
        content={t('General.errorModal.message')}
        iconName={'error'}
      />

      <ModelsSidebar
        open={updateModelData !== undefined}
        onClose={() => {
          setUpdateModelData(undefined);
          setSingleDelete(false);
        }}
        onUpdateValue={handleUpdateModel}
        onDeleteValue={() => {
          setSingleDelete(true);
          setConfirmDeleteModalsOpen(true);
        }}
        initialData={updateModelInitialData}
      />
    </Base>
  );
};

export default React.memo(Models);
