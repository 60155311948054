import React, {ReactElement, useMemo} from 'react';

import {useModels} from 'store/models';

import {IProps} from './definitions';
import './textWithRoundedImage.scss';

const TextWithRoundedImage = ({
  image,
  text,
  checkbox,
  error,
  variant = 'regular',
  imageFromSketch,
}: IProps): ReactElement => {
  const {useGetSketch} = useModels();
  const {data: sketchUrl} = useGetSketch(imageFromSketch);
  const placeholder = useMemo(() => {
    return (
      <div
        className="w-full h-full rounded-[100%] bg-tan-3 overflow-hidden"
        style={{
          background:
            'linear-gradient(to top right, transparent calc(50% - 1px), #D0B194, transparent calc(50% + 1px) )',
        }}
      />
    );
  }, []);
  return (
    <div
      className={`flex flex-row items-center text-with-rounded-image w-full ${
        error ? 'text-with-rounded-image--error' : ''
      }`}>
      {checkbox && checkbox}

      <div
        className={`img-border rounded-full flex-0 relative  ${
          variant === 'small' ? 'img-border--small' : 'img-border--regular'
        }`}>
        {image || sketchUrl ? (
          <img
            className="rounded-full w-full p-1 h-full"
            src={image || sketchUrl}
            alt={text}
          />
        ) : (
          placeholder
        )}
      </div>
      <p className="text-left line-clamp-3">{text}</p>
    </div>
  );
};

export default React.memo(TextWithRoundedImage);
