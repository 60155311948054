import {useCallback, useMemo} from 'react';

import {OktaAuth} from '@okta/okta-auth-js';
import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {LoginInputs} from 'components/pages/Login/interfaces';
import {useUtils} from 'hooks/index';
import {AnonymousRoutes, useNavigation} from 'navigation';
import config from 'oktaConfig';

import {useAPI} from '../api';

import {ssoLoginParams} from './definitions';

export const useAuthentication = () => {
  const {api, oldServerApi} = useAPI();
  const {customError, setStorage, getStorage, removeStorage} = useUtils();
  const {goToPath} = useNavigation();

  const authClient = useMemo(() => {
    return new OktaAuth(config.oidc);
  }, []);

  const ssoLogout = useCallback(async () => {
    await authClient.revokeAccessToken();
    await authClient.signOut();
  }, [authClient]);

  const isLoggedIn = useMemo(() => {
    return getStorage('user');
  }, [getStorage]);

  const logout = useCallback(async () => {
    removeStorage('user');
    const isAuthenticated = await authClient.isAuthenticated();
    if (isAuthenticated) {
      await ssoLogout();
    } else {
      goToPath(AnonymousRoutes.LOGIN);
    }
  }, [authClient, goToPath, removeStorage, ssoLogout]);

  const token = useMemo(() => {
    const user = isLoggedIn;
    return user && JSON.parse(user)?.data?.token;
  }, [isLoggedIn]);

  const getSsoAuth = useCallback(
    async (params: ssoLoginParams) => {
      try {
        const {data} = await api.post('/api/v1/auth/token', {
          ...params,
          ...{provider: 'okta'},
        });
        setStorage('user', data);
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error?.response.data;
        }
      }
    },
    [api, customError, setStorage],
  );

  const getAuth = useCallback(
    async (params: LoginInputs) => {
      try {
        const {data} = await api.post('/api/v1/auth/token', {
          ...params,
          ...{provider: 'usernamePassword'},
        });
        setStorage('user', data);
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error?.response.data;
        }
      }
    },
    [api, customError, setStorage],
  );

  const forgotPassword = useCallback(
    async (params: {email: string}) => {
      try {
        const {data} = await oldServerApi.post('/api/v1/auth/forgotPassword', {
          ...params,
        });
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [customError, oldServerApi],
  );

  const {mutateAsync: loginAsync, isPending: isLoadingLogin} = useMutation({
    mutationFn: getAuth,
  });

  const {mutateAsync: ssoLoginAsync, isPending: isLoadingLoginSso} =
    useMutation({
      mutationFn: getSsoAuth,
    });

  const {mutateAsync: forgotPasswordAsync, isPending: isLoadingForgotPassword} =
    useMutation({mutationFn: forgotPassword});

  return {
    isLoggedIn: isLoggedIn,
    login: loginAsync,
    isLoadingLogin,
    logout,
    token: token,
    forgotPasswordAsync,
    isLoadingForgotPassword,
    ssoLoginAsync,
    isLoadingLoginSso,
    authClient,
    ssoLogout,
  };
};
