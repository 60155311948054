import {useCallback} from 'react';

import {generatePath, useNavigate} from 'react-router-dom';

import {AnonymousRoutes, AuthRoutes} from './routes';

export const useNavigation = () => {
  const navigate = useNavigate();

  const goToPath = useCallback(
    (path: AuthRoutes | AnonymousRoutes) => {
      navigate(path);
    },
    [navigate],
  );

  const goToPathWithParams = useCallback(
    (path: AuthRoutes | AnonymousRoutes, {...params}) => {
      const navigationPath = generatePath(path, {
        customizationId: params.customizationId,
      });
      navigate(navigationPath);
    },
    [navigate],
  );

  const goToMain = useCallback(() => {
    navigate(AuthRoutes.MAIN);
  }, [navigate]);

  return {
    goToPath,
    goToMain,
    goToPathWithParams,
  };
};
