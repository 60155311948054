import React from 'react';

import {DialogProps} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

const CloneSuccessModal: React.FC<DialogProps & {onClose: () => void}> = ({
  onClose,
  ...props
}) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon="copy"
      content={t('Template.cloneModal.successModal.message')}
      actions={
        <Button variant="primary" onClick={onClose}>
          {t('Template.cloneModal.successModal.confirm')}
        </Button>
      }
    />
  );
};

export default React.memo(CloneSuccessModal);
