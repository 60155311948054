export enum VisibilityQueryKeys {
  GET_VISIBLE_ID_LIST_QUERY_KEY = 'GET_VISIBLE_ID_LIST_QUERY_KEY',
  GET_VISIBILITY_QUERY_KEY = 'GET_VISIBILITY_QUERY_KEY',
  UPDATE_VISIBILITY_KEY = 'UPDATE_VISIBILITY_KEY',
}

export type VisibilityItem = {
  id: string;
  name: string;
  visible: boolean;
  image: string;
};

export type GetVisibleIdListParams = {
  customizationId: string;
  currentPageId: string;
};

export type GetVisibleIdListResponse = {
  data: {
    visibility: Array<string>;
  };
};

export type GetVisibilityParams = {
  customizationId: string;
  currentPageId: string;
  options?: {
    id?: string;
    name?: string;
    visibility?: boolean;
    page?: number;
    limit?: number;
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
    includeValues?: boolean;
    includeVisibility?: boolean;
  };
};

export type GetVisibilityResponse = {
  data: {
    values: {
      totalCount: number;
      values: Array<VisibilityItem>;
    };
    visibility: Array<string>;
  };
};

export type UpdateVisibilityParams = {
  customizationId: string;
  currentPageId: string;
  params: {
    visibility: Array<string>;
  };
};
