import React, {ReactElement} from 'react';

import Select from 'react-select';

import './select.scss';
import {SelectProps} from './definitions';

const CustomSelect = (props: SelectProps): ReactElement => {
  return (
    <div className="flex-col flex text-primary-main text-small">
      <label className="font-book uppercase whitespace-nowrap xl:whitespace-normal">{props.label}</label>
      <Select {...props} className="custom-select" classNamePrefix="select" />
    </div>
  );
};

export default React.memo(CustomSelect);
