import React, {ReactElement} from 'react';

import {Drawer} from '@mui/material';

import {FiltersProvider} from '../../../contexts';
import {CopyConfigTable} from '../CopyConfigTable';

import {CopyConfigSidebarProps} from './definitions';

const CopyConfigSidebar = ({
  customizationId,
  currentPageId,
  onClose,
  copySidebarStatus,
  isPrimary = false,
  valueName,
  currentOriginalCopyRow,
  ...props
}: CopyConfigSidebarProps): ReactElement => {
  return (
    <Drawer
      onClose={onClose}
      anchor="right"
      transitionDuration={500}
      classes={{
        paper: 'w-[1224px] max-w-full',
      }}
      {...props}>
      <FiltersProvider>
        <CopyConfigTable
          customizationId={customizationId}
          currentPageId={currentPageId}
          currentOriginalCopyRow={currentOriginalCopyRow}
          onCancel={onClose}
          copySidebarStatus={copySidebarStatus}
          isPrimary={isPrimary}
          valueName={valueName}
        />
      </FiltersProvider>
    </Drawer>
  );
};

export default React.memo(CopyConfigSidebar);
