export interface IProps {
  customizationId: string;
  currentPageId: string;
  currentPageName: string;
}

export const PAGINATION_DEPENDENCY_LIMIT: number = 40;

export const VIEW_BY_PRIMARY: string = 'primary';

export interface CellProps {
  id: string;
  getValue: () => any;
  renderValue: () => any;
  renderedCellValue: any;
}
