import React, {ReactElement} from 'react';

import {useTranslations} from '../../../vidiemme/react-i18n';
import {IconSVG} from '../../atoms/IconSVG';
import {Base} from '../../templates/Base';

const FourZeroFour = (): ReactElement => {
  const {t} = useTranslations();

  return (
    <Base>
      <div className="h-[50vh] flex align-middle text-center flex-col justify-center items-center">
        <IconSVG icon="alert" size={100} />
        <p className="font-bold mt-1">{t('General.404.title')}</p>
        <p className="font-black">{t('General.404.subtitle')}</p>
      </div>
    </Base>
  );
};

export default React.memo(FourZeroFour);
