import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useQueryClient} from '@tanstack/react-query';
import {Filters} from 'components/molecules/Filters';
import {Table} from 'components/molecules/Table';
import {useFilterContext} from 'contexts';
import {
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
} from 'material-react-table';
import {useAssignments} from 'store/pageSwitch/assignments';
import {AssignmentsQueryKeys} from 'store/pageSwitch/assignments/definitions';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {Button} from '../../atoms/Button';
import {TextWithRoundedImage} from '../../molecules/TextWithRoundedImage';
import {VIEW_BY_DEPENDENCY} from '../AssignmentsTable/AssigmentsTableDependency/definitions';
import {VIEW_BY_PRIMARY} from '../AssignmentsTable/AssigmentsTablePrimary/definitions';
import GenericModal from '../GenericModal';

import {CopyConfigTableProps} from './definitions';

const CopyConfigTable = ({
  customizationId,
  currentPageId,
  onCancel,
  copySidebarStatus,
  isPrimary = false,
  valueName,
  currentOriginalCopyRow,
}: CopyConfigTableProps) => {
  const {t} = useTranslations();

  const {useGetFieldsAssignments, copyConfigAssignmentsAsync} =
    useAssignments();
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [paramSorting, setParamSorting] = useState<{
    primaryOrderBy: 'ASC' | 'DESC';
  }>();

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

  const {filters} = useFilterContext();

  const paramFilters = useMemo<FiltersParams>(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  const {data: assignmentsList} = useGetFieldsAssignments({
    customizationId,
    currentPageId,
    primaryPage: isPrimary ? pagination.pageIndex + 1 : 1,
    primaryLimit: isPrimary ? pagination.pageSize : 1,
    dependencyPage: isPrimary ? 1 : pagination.pageIndex + 1,
    dependencyLimit: isPrimary ? 1 : pagination.pageSize,
    viewBy: isPrimary ? VIEW_BY_PRIMARY : VIEW_BY_DEPENDENCY,
    ...paramSorting,
    ...(paramFilters || {}),
  });

  const [assignmentsListCopy, setAssignmentsListCopy] = useState();

  //sorting
  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          primaryOrderBy: sortingParam?.desc ? 'DESC' : 'ASC',
        });
    }
  }, [sorting]);

  useEffect(() => {
    setAssignmentsListCopy(structuredClone(assignmentsList));
  }, [assignmentsList]);

  const filterList = useMemo(() => {
    return [
      isPrimary
        ? {
            id: 'primary',
            name: 'primary',
            label: valueName,
          }
        : {
            id: 'dependency',
            name: 'dependency',
            label: valueName,
          },
    ];
  }, [isPrimary, valueName]);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row: {name: string; id: string; picture: string}) => {
          return (
            <TextWithRoundedImage
              key={row.id}
              image={row.picture}
              text={`${row.id} ${row.name}`}
              variant={'small'}
            />
          );
        },
        header: valueName,
      },
    ],
    [valueName],
  );

  const submitCopy = useCallback(async () => {
    try {
      await copyConfigAssignmentsAsync(
        {
          viewBy: isPrimary ? 'primary' : 'dependency',
          copyFrom:
            copySidebarStatus === 'TO'
              ? currentOriginalCopyRow.id
              : Object.keys(rowSelection)[0],
          copyTo:
            copySidebarStatus === 'TO'
              ? Object.keys(rowSelection)
              : [currentOriginalCopyRow.id],
          customizationId: customizationId,
          fieldId: currentPageId,
        },
        {
          onSuccess: () => {
            setSuccessModalOpen(true);
            queryClient.invalidateQueries({
              queryKey: [AssignmentsQueryKeys.ASSIGNMENTS_FIELDS_QUERY_KEY],
              exact: false,
            });
          },
          onError: () => {
            setErrorModalOpen(true);
          },
        },
      );
    } catch (e) {
      setErrorModalOpen(true);
    }
  }, [
    copySidebarStatus,
    copyConfigAssignmentsAsync,
    currentOriginalCopyRow.id,
    currentPageId,
    customizationId,
    isPrimary,
    queryClient,
    rowSelection,
  ]);

  const dataObject = useMemo(() => {
    if (!assignmentsListCopy) {
      return;
    }
    assignmentsListCopy.data.primary = {
      values: assignmentsListCopy.data.primary.values.filter(singleValue => {
        return singleValue.id !== currentOriginalCopyRow.id;
      }),
      totalCount: assignmentsListCopy.data.primary.totalCount,
    };
    assignmentsListCopy.data.dependency = {
      values: assignmentsListCopy.data.dependency.values.filter(singleValue => {
        return singleValue.id !== currentOriginalCopyRow.id;
      }),
      totalCount: assignmentsListCopy.data.dependency.totalCount,
    };
    console.log(assignmentsList);
    return isPrimary
      ? assignmentsListCopy.data.primary
      : assignmentsListCopy.data.dependency;
  }, [assignmentsListCopy, assignmentsList, isPrimary, currentOriginalCopyRow]);

  const table = useMemo(() => {
    return (
      assignmentsListCopy?.data &&
      dataObject && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={dataObject.totalCount}
          tableOptions={{
            getRowId: originalRow => originalRow.id,
            enableRowSelection: true,
            columns,
            rowCount: dataObject.totalCount,
            data: dataObject.values,
            enableMultiRowSelection: copySidebarStatus === 'TO',
            initialState: {pagination},
            state: {
              sorting,
              rowSelection,
              pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
          }}
          customBottomToolbar
          bottomToolbarLeft={
            <Button variant="tertiary" onClick={onCancel}>
              {t('General.cancel')}
            </Button>
          }
          bottomToolbarRight={
            <Button
              iconLeft={'save'}
              disabled={Object.keys(rowSelection).length === 0}
              onClick={async () => {
                await submitCopy();
              }}>
              {t('General.save')}
            </Button>
          }
        />
      )
    );
  }, [
    assignmentsListCopy?.data,
    dataObject,
    pagination,
    columns,
    copySidebarStatus,
    sorting,
    rowSelection,
    onCancel,
    t,
    submitCopy,
  ]);

  const successModal = useMemo(() => {
    return (
      <GenericModal
        onClose={() => {
          setSuccessModalOpen(false);
          onCancel();
        }}
        open={successModalOpen}
        content={t('General.successModal.actionsCorrectlySaved')}
        iconName={'save'}
      />
    );
  }, [successModalOpen, t, onCancel]);

  const errorModal = useMemo(() => {
    return (
      <GenericModal
        onClose={() => {
          setErrorModalOpen(false);
          onCancel();
        }}
        open={errorModalOpen}
        content={t('General.errorModal.message')}
        iconName={'error'}
      />
    );
  }, [errorModalOpen, t, onCancel]);

  return (
    <>
      <div className="px-10 pt-10 flex flex-col items-stretch h-[100%]">
        <p className="text-big font-bold uppercase flex items-center">
          {copySidebarStatus === 'FROM'
            ? `${t('Assignments.copyFrom')}`
            : t('Assignments.copyTo')}
          <span className="text-small font-light lowercase ml-1">
            {copySidebarStatus === 'FROM'
              ? t('Assignments.copyFromSecondaryLabel')
              : ''}
          </span>
        </p>

        <div className="py-5 w-1/2">
          <Filters filterList={filterList} />
        </div>
        {table}
        {successModal}
        {errorModal}
      </div>
    </>
  );
};

export default React.memo(CopyConfigTable);
