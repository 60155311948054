import React, {useCallback, useMemo, useState} from 'react';

import {UploadOutcomeModalProps} from 'components/molecules/UploadOutcomeModal/definitions';
import UploadOutcomeModal from 'components/molecules/UploadOutcomeModal/UploadOutcomeModal';
import {SwatchesTable} from 'components/organisms/SwatchesTable';
import {useFilterContext} from 'contexts';
import {MRT_SortingState} from 'material-react-table';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {usePageSwatches} from 'store/pageSwitch/swatches';
import {
  GetSwatchesParamsOptions,
  GetSwatchesResponse,
  SwatchNoDependency,
  SwatchWithDependency,
  UploadSwatchParams,
} from 'store/pageSwitch/swatches/definitions';

import {SwatchesCombinedTable} from '../SwatchesCombinedTable';
import {
  SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
  SwatchesCombinedTableProps,
} from '../SwatchesCombinedTable/definitions';
import {SwatchesTableProps} from '../SwatchesTable/definitions';

import {SwatchesProps} from './definitions';

const Swatches = ({
  customizationId,
  currentPageId,
  currentPage,
  dependencyName,
}: SwatchesProps) => {
  const {useGetSwatches, useUploadPrimarySwatch, useUploadDependencySwatch} =
    usePageSwatches();

  const [uploadOutcome, setUploadOutcome] = useState<
    UploadOutcomeModalProps['uploadOutcome'] | undefined
  >(undefined);

  //Filters param
  const {filters} = useFilterContext();

  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  //Pagination param
  const [primaryPagination, setPrimaryPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });
  //Pagination param
  const [dependencyPagination, setDependencyPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
  });

  //Sorting params
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const paramSorting = useMemo<{
    primarySortBy?: GetSwatchesParamsOptions['primarySortBy'];
    primaryOrderBy?: GetSwatchesParamsOptions['primaryOrderBy'];
  }>(() => {
    const sortingParam = sorting.length > 0 && sorting[0];
    if (sortingParam) {
      return {
        primarySortBy:
          sortingParam.id as GetSwatchesParamsOptions['primarySortBy'],
        primaryOrderBy: sortingParam.desc ? 'DESC' : 'ASC',
      };
    } else {
      return {};
    }
  }, [sorting]);

  // Table values
  const {
    data: values,
    isFetching,
    refetch,
  } = useGetSwatches({
    customizationId: customizationId,
    currentPageId: currentPageId,
    options: {
      primaryPage: primaryPagination.pageIndex + 1,
      primaryLimit: primaryPagination.pageSize,
      dependencyPage: dependencyPagination.pageIndex + 1,
      dependencyLimit: dependencyPagination.pageSize,
      ...paramSorting,
      ...paramFilters,
    },
  });

  // Handle upload primary swatch
  const {
    mutateAsync: UploadPrimarySwatch,
    isPending: UploadPrimarySwatchLoading,
  } = useUploadPrimarySwatch();

  // Handle upload dependency swatch
  const {
    mutateAsync: UploadDependencySwatch,
    isPending: UploadDependencySwatchLoading,
  } = useUploadDependencySwatch();

  const handleUpload = useCallback(
    async ({
      ...params
    }: Pick<UploadSwatchParams, 'valueId' | 'dependencyId' | 'file'>) => {
      const mutationFunc = params?.dependencyId
        ? UploadDependencySwatch
        : UploadPrimarySwatch;
      await mutationFunc(
        {
          ...params,
          customizationId,
          currentPageId,
        },
        {
          onSuccess: () => setUploadOutcome('success'),
          onError: () => setUploadOutcome('error'),
          onSettled: () => refetch(),
        },
      );
    },
    [
      UploadDependencySwatch,
      UploadPrimarySwatch,
      currentPageId,
      customizationId,
      refetch,
    ],
  );

  const hasDependsOnForSwatches = useMemo(() => {
    return Boolean(currentPage.dependsOnForSwatches);
  }, [currentPage]);

  const componentProps = useMemo(() => {
    return {
      values,
      isFetching,
      isUploading: UploadPrimarySwatchLoading || UploadDependencySwatchLoading,
      setPrimaryPagination,
      primaryPagination,
      ...(hasDependsOnForSwatches && {
        setDependencyPagination,
        dependencyPagination,
      }),
      setSorting,
      sorting,
      dependencyName,
      primaryName: currentPage?.name,
      handleUpload,
    };
  }, [
    UploadDependencySwatchLoading,
    UploadPrimarySwatchLoading,
    currentPage?.name,
    dependencyName,
    dependencyPagination,
    handleUpload,
    hasDependsOnForSwatches,
    isFetching,
    primaryPagination,
    sorting,
    values,
  ]);

  const renderContent = useMemo(() => {
    if (hasDependsOnForSwatches) {
      return (
        <SwatchesCombinedTable
          {...(componentProps as SwatchesCombinedTableProps)}
          values={values as GetSwatchesResponse<SwatchWithDependency>}
        />
      );
    } else {
      return (
        <SwatchesTable
          {...(componentProps as SwatchesTableProps)}
          values={values as GetSwatchesResponse<SwatchNoDependency>}
        />
      );
    }
  }, [componentProps, hasDependsOnForSwatches, values]);

  return (
    <>
      {renderContent}
      {uploadOutcome && (
        <UploadOutcomeModal
          open={true}
          uploadOutcome={uploadOutcome}
          onClose={() => setUploadOutcome(undefined)}
        />
      )}
    </>
  );
};

export default React.memo(Swatches);
