import React from 'react';

import {Checkbox as MuiCheckbox} from '@mui/material';
import './checkbox.scss';

import {CheckboxProps} from './definitions';

const CustomCheckbox = ({...props}: CheckboxProps) => {
  return <MuiCheckbox {...props} />;
};

export default React.memo(CustomCheckbox);
