export enum ValuesQueryKeys {
  GET_VALUES_QUERY_KEY = 'GET_VALUES_QUERY_KEY',
  DELETE_VALUES_KEY = 'DELETE_VALUES_KEY',
  CREATE_VALUE_KEY = 'CREATE_VALUE_KEY',
  UPDATE_VALUE_KEY = 'UPDATE_VALUE_KEY',
  GET_VALUES_FROM_CATALOG_QUERY_KEY = 'GET_VALUES_FROM_CATALOG_QUERY_KEY',
  ADD_VALUES_FROM_CATALOG_QUERY_KEY = 'ADD_VALUES_FROM_CATALOG_QUERY_KEY',
}

type ValueData = {
  id: string;
  name: string;
  color?: string;
  tracking_tool_id?: string;
  default_if?: Array<string>;
  fallback?: boolean;
  always_selected?: boolean;
};

export type ValueItem = ValueData & {
  picture?: string;
  unassigned: boolean;
  unexpected: boolean;
  shortcut_image_provider: string;
};

export type GetValuesParams = {
  customizationId: string;
  currentPageId: string;
  options?: {
    id?: string;
    name?: string;
    status?: 'unexpected' | 'unassigned';
    page?: number;
    limit?: number;
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
  };
};

export type GetValuesResponse = {
  data: {
    totalCount: number;
    values: Array<ValueItem>;
  };
};

export type DeleteFieldValuesParams = {
  customizationId: string;
  currentPageId: string;
  params: {
    valueIds?: Array<string>;
  };
};

export type ValueParams = ValueData & {
  picuture?: File;
};

export type CreateValueParams = {
  customizationId: string;
  currentPageId: string;
  params: ValueParams;
};

export type UpdateValueParams = {
  customizationId: string;
  currentPageId: string;
  valueId: string;
  params: Partial<ValueParams>;
};

export type GetValuesFromCatalogParams = {
  customizationId: string;
  currentPageId: string;
  options?: {
    onlyCount?: boolean;
    id?: string;
    name?: string;
    page?: number;
    limit?: number;
    sortBy?: string;
    orderBy?: 'ASC' | 'DESC';
  };
};

export type CatalogValueItem = {
  id: string;
  name: string;
  given: string[];
};

export type GetValuesFromCatalogResponse = {
  data: {
    results: Array<CatalogValueItem>;
    totalCount: number;
  };
};

export type AddValuesFromCatalogParams = {
  customizationId: string;
  currentPageId: string;
  params: {
    values: Array<CatalogValueItem>;
  };
};
