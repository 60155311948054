import {useCallback} from 'react';

import {useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks/index';
import {useAPI} from 'store/api';

import {
  GetCustomizationFieldsResponse,
  PageSwitchQueryKeys,
} from './definitions';

export const usePageSwitch = () => {
  const {api} = useAPI();
  const {customError} = useUtils();

  const getCustomizationFields = useCallback(
    async (customizazionId: string) => {
      try {
        const {data} = await api.get<GetCustomizationFieldsResponse>(
          `/api/v1/customizations/${customizazionId}/fields`,
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  //Queries
  const useGetCustomizationFields = (customizationId: string) =>
    useQuery({
      queryKey: [
        PageSwitchQueryKeys.CUSTOMIZATION_FIELDS_QUERY_KEY,
        customizationId,
      ],
      queryFn: async () => getCustomizationFields(customizationId),
    });

  return {
    useGetCustomizationFields,
  };
};
