import React, {ReactElement, useEffect, useLayoutEffect, useState} from 'react';

import {Breadcrumb} from 'components/atoms/Breadcrumb';
import {Header} from 'components/molecules/Header';
import GenericModal from 'components/organisms/GenericModal';
import {useUtils} from 'hooks';
import {useTranslations} from 'vidiemme/react-i18n';

import {IProps} from './interfaces';

const Base = ({children, breadcrumb}: IProps): ReactElement => {
  const {setSessionStorage, getSessionStorage} = useUtils();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const {t} = useTranslations();
  const [errorSizeModalOpen, setErrorSizeModalOpen] = useState<boolean>(false);
  const minWindowSize = 1024;

  const userAcceptedBadSize = getSessionStorage('userAcceptBadSize');

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    windowSize <= minWindowSize && !userAcceptedBadSize
      ? setErrorSizeModalOpen(true)
      : setErrorSizeModalOpen(false);
  }, [userAcceptedBadSize, windowSize]);

  return (
    <div className="flex flex-col gap-[30px] wrapper-external">
      <Header />
      <div className="sm:px-5 relative">
        {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}
        <div>{children}</div>
      </div>
      <GenericModal
        onClose={() => {
          setErrorSizeModalOpen(false);
          setSessionStorage('userAcceptBadSize', 'true');
        }}
        open={errorSizeModalOpen}
        content={t('ErrorSizeModal.text')}
        iconName={'error'}
      />
    </div>
  );
};

export default React.memo(Base);
