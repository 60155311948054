import React from 'react';

import {Switch as MuiSwitch} from '@mui/material';

import {SwitchProps} from './definitions';

const Switch = (props: SwitchProps) => {
  return <MuiSwitch {...props} />;
};

export default React.memo(Switch);
