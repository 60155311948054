import React, {useCallback, useMemo} from 'react';

import {Button} from 'components/atoms/Button';
import {Checkbox} from 'components/atoms/Checkbox';
import {IconSVG} from 'components/atoms/IconSVG';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {useTranslations} from 'vidiemme/react-i18n';

import './detailPanelAssignments.scss';
import {IProps, PanelElem} from './definitions';

const DetailPanelAssignments = ({
  panelList,
  onChangeCheckboxValue,
  primaryButtonActionTrigger,
  paginationDependency,
  secondaryButton,
  primaryButtonDisabled,
  copyFromButtonActionTrigger,
  copyToButtonActionTrigger,
  currentRow,
}: IProps) => {
  const {t} = useTranslations();
  const checkbox = useCallback(
    (singlePanelElem: PanelElem) => {
      return (
        <Checkbox
          color={singlePanelElem.error === true ? 'error' : 'primary'}
          checked={singlePanelElem.checked}
          disabled={singlePanelElem.disabled}
          onChange={event =>
            onChangeCheckboxValue(singlePanelElem, event.target.checked)
          }
        />
      );
    },
    [onChangeCheckboxValue],
  );

  const panelListTpl = useMemo(() => {
    return panelList.map((singlePanelElem: PanelElem) => {
      return (
        <TextWithRoundedImage
          key={singlePanelElem.text}
          image={singlePanelElem.image || singlePanelElem.picture}
          text={singlePanelElem.text}
          checkbox={checkbox(singlePanelElem)}
          error={singlePanelElem.error}
        />
      );
    });
  }, [checkbox, panelList]);

  //TODO move to atoms
  const paginationButtons = useMemo(() => {
    return paginationDependency !== undefined ? (
      <div className="details-panel-pagination left-0 w-full flex flex-row justify-between h-full absolute items-center">
        <button
          onClick={() => paginationDependency.paginationCallback('prev')}
          disabled={!paginationDependency.enablePrev}>
          <IconSVG icon="arrow-back" size={24} />
        </button>
        <button
          onClick={() => paginationDependency.paginationCallback('next')}
          disabled={!paginationDependency.enableNext}>
          <IconSVG icon="arrow-forward" size={24} />
        </button>
      </div>
    ) : (
      <></>
    );
  }, [paginationDependency]);
  return (
    <div
      className={`${
        paginationDependency !== undefined ? 'relative px-7' : ''
      } `}>
      {paginationButtons}
      <div className="grid-cols-4 grid gap-4 detail-panel">{panelListTpl}</div>
      <div className="flex row justify-between pt-2">
        <div className="flex">
          {secondaryButton && <div className="mx-2">{secondaryButton}</div>}
          <div>
            <Button
              size="small"
              variant="secondary"
              onClick={() => copyToButtonActionTrigger(currentRow)}>
              {t('Assignments.copyTo')}
            </Button>
          </div>
          <div className="mx-2">
            <Button
              size="small"
              variant="secondary"
              onClick={() => copyFromButtonActionTrigger(currentRow)}>
              {t('Assignments.copyFrom')}
            </Button>
          </div>
        </div>
        <Button
          iconLeft={'save'}
          size="small"
          disabled={primaryButtonDisabled}
          onClick={primaryButtonActionTrigger}>
          {t('General.save')}
        </Button>
      </div>
    </div>
  );
};
export default React.memo(DetailPanelAssignments);
