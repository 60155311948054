import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Filters} from 'components/molecules/Filters';
import {Table} from 'components/molecules/Table';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import {MRT_ColumnDef} from 'material-react-table';
import {
  DependencySwatchValuesExtended,
  GetSwatchesResponse,
  PrimarySwatchValues,
  SwatchValuesUnion,
} from 'store/pageSwitch/swatches/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

import {DetailPanelSwatches} from '../DetailPanelSwatches';

import {
  SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
  SwatchesCombinedTableProps,
} from './definitions';

const SwatchesCombinedTable = ({
  values,
  setPrimaryPagination,
  primaryPagination,
  setDependencyPagination,
  dependencyPagination,
  setSorting,
  sorting,
  primaryName,
  dependencyName,
  isFetching,
  isUploading,
  handleUpload,
}: SwatchesCombinedTableProps) => {
  const {t} = useTranslations();

  const [expandedRowDetails, setExpandedRowDetails] = useState<{
    expanded: {[id: number]: boolean};
  }>();
  const [valuesCopy, setValuesCopy] =
    useState<GetSwatchesResponse<SwatchValuesUnion>>();

  useEffect(() => {
    setValuesCopy(structuredClone(values));
  }, [values]);

  useEffect(() => {
    if (values?.data.primary && values?.data?.dependency?.totalCount == 0) {
      values.data.primary.values = [];
      values.data.primary.totalCount = 0;
    }
    setValuesCopy(values);
  }, [values, values?.data?.dependency?.totalCount, values?.data.primary]);

  // Calculate dependency page number
  const dependencyPages = useMemo(() => {
    if (!values?.data?.dependency) return 1;

    return (
      values?.data?.dependency?.totalCount /
      SWATCHES_PAGINATION_DEPENDENCY_LIMIT
    );
  }, [values?.data?.dependency]);

  // Table columns definition
  const columns = useMemo<MRT_ColumnDef<PrimarySwatchValues | any>[]>(
    () => [
      {
        accessorFn: (row: {name: string; id: string; picture: string}) => {
          return (
            <TextWithRoundedImage
              key={row.id}
              image={row.picture}
              text={`${row.id} ${row.name}`}
              variant={'small'}
            />
          );
        },
        header: primaryName,
        accessorKey: 'name',
      },
    ],
    [primaryName],
  );

  // Retrieve panel data detail for primary field
  const detailsPanelData = useCallback(
    (rowId: string): DependencySwatchValuesExtended[] => {
      return values?.data?.dependency?.values?.map(dep => {
        const swatchesRowId = values.data.swatches[rowId];
        const rowHasDep = swatchesRowId && dep?.id in swatchesRowId;

        return {
          ...dep,
          ...(rowHasDep && {
            picture: swatchesRowId[dep?.id],
          }),
          rowId,
        };
      }) as Array<DependencySwatchValuesExtended>;
    },
    [values],
  );

  // Handle dependency pagination
  const paginationDependency = useCallback(
    (rowId: number) => {
      if (
        values?.data?.dependency &&
        values?.data?.dependency?.totalCount >
          SWATCHES_PAGINATION_DEPENDENCY_LIMIT
      ) {
        return {
          enablePrev: dependencyPagination.pageIndex !== 0,
          enableNext: dependencyPagination.pageIndex < dependencyPages - 1,
          paginationCallback: (direction: 'prev' | 'next'): void => {
            setExpandedRowDetails({
              expanded: {
                [rowId]: true,
              },
            });
            direction === 'next'
              ? setDependencyPagination({
                  pageIndex: dependencyPagination.pageIndex + 1,
                  pageSize: SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
                })
              : setDependencyPagination({
                  pageIndex: dependencyPagination.pageIndex - 1,
                  pageSize: SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
                });
          },
        };
      } else {
        return undefined;
      }
    },
    [
      dependencyPages,
      dependencyPagination.pageIndex,
      setDependencyPagination,
      values?.data?.dependency,
    ],
  );

  const memoizedTable = useMemo(() => {
    return (
      valuesCopy?.data?.primary && (
        <Table
          pageSize={dependencyPagination.pageSize}
          currentPage={dependencyPagination.pageIndex}
          totalRows={valuesCopy.data.primary.totalCount}
          tableOptions={{
            getRowId: (originalRow: any) => originalRow.id,
            columns,
            data: valuesCopy.data.primary.values,
            rowCount: valuesCopy.data.primary.totalCount,
            initialState: expandedRowDetails,
            state: {
              sorting,
              pagination: primaryPagination,
              showLoadingOverlay: isFetching || isUploading,
            },
            onSortingChange: setSorting,
            onPaginationChange: setPrimaryPagination,
            renderDetailPanel({row}) {
              //TODO temporary fix wait be check
              return (
                <DetailPanelSwatches
                  handleUpload={handleUpload}
                  panelData={detailsPanelData(row?.original?.id)}
                  panelPagination={paginationDependency(
                    Number(row?.original?.id),
                  )}
                />
              );
            },
            muiExpandButtonProps: ({row, table}) => ({
              onClick: () => {
                table.setExpanded({[row.id]: !row.getIsExpanded()});
                setDependencyPagination({
                  pageIndex: 0,
                  pageSize: SWATCHES_PAGINATION_DEPENDENCY_LIMIT,
                });
              },
            }),
            muiTableBodyRowProps: ({isDetailPanel, row, table}) => ({
              onClick: () => {
                !isDetailPanel &&
                  table.setExpanded({[row.id]: !row.getIsExpanded()});
              },
              sx: {
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'red',
                },
                '.Mui-TableBodyCell-DetailPanel': {
                  width: '100%',
                  '.MuiCollapse-vertical': {
                    width: '100%',
                  },
                },
              },
            }),
          }}></Table>
      )
    );
  }, [
    columns,
    dependencyPagination.pageIndex,
    dependencyPagination.pageSize,
    detailsPanelData,
    expandedRowDetails,
    handleUpload,
    isFetching,
    isUploading,
    paginationDependency,
    primaryPagination,
    setDependencyPagination,
    setPrimaryPagination,
    setSorting,
    sorting,
    valuesCopy,
  ]);

  return (
    <>
      <div className="2xl:container mx-auto py-5">
        <Filters
          filterList={[
            {
              id: 'primaryId',
              name: 'primaryId',
              label: t('Filters.nameId', {name: primaryName}),
            },
            {
              id: 'primaryName',
              name: 'primaryName',
              label: primaryName,
            },
            {
              id: 'dependencyId',
              name: 'dependencyId',
              label: t('Filters.nameId', {name: dependencyName}),
            },
            {
              id: 'dependencyName',
              name: 'dependencyName',
              label: dependencyName,
            },
          ]}></Filters>
      </div>
      {memoizedTable}
    </>
  );
};

export default React.memo(SwatchesCombinedTable);
