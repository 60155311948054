import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Breadcrumb} from 'components/atoms/Breadcrumb';
import {Button} from 'components/atoms/Button';
import {Filters} from 'components/molecules/Filters';
import {MainNavigation} from 'components/molecules/MainNavigation';
import {Table} from 'components/molecules/Table';
import {CellProps} from 'components/molecules/Table/definitions';
import {TextWithRoundedImage} from 'components/molecules/TextWithRoundedImage';
import GenericModal from 'components/organisms/GenericModal';
import {Model} from 'components/pages/Models/ProposedModels/definitions';
import {Base} from 'components/templates/Base';
import {useFilterContext} from 'contexts';
import {useUtils} from 'hooks';
import {MRT_RowSelectionState} from 'material-react-table';
import {useParams} from 'react-router-dom';
import {useModels} from 'store/models';
import {FiltersParams} from 'store/pageSwitch/definitions';
import {useTranslations} from 'vidiemme/react-i18n';

const ProposedModels = (): ReactElement => {
  const {t} = useTranslations();
  const {customizationId} = useParams();
  //Pagination param
  const [pagination, setPagination] = useState<{
    pageIndex: number;
    pageSize: number;
  }>({
    pageIndex: 0,
    pageSize: 100,
  });

  //Sorting params
  const [sorting, setSorting] = useState<[{id: string; desc: boolean}] | []>(
    [],
  );
  const [paramSorting, setParamSorting] = useState<{
    sortBy: string;
    orderBy: 'ASC' | 'DESC';
  }>();
  //Rows Data
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({}); //ts type available

  const [successDeleteModalsOpen, setSuccessDeleteModalsOpen] =
    useState<boolean>(false);
  const {
    useGetProposedModels,
    useGetCurrentModelsFilters,
    addModelsToCurrentModelsAsync,
  } = useModels();
  const {filters, resetFilters} = useFilterContext();

  const {getStorage} = useUtils();

  const currentName = useMemo(() => {
    const storageName = getStorage('currentTemplateName');
    return (
      (storageName && JSON.parse(JSON.parse(storageName)).currentName) || ''
    );
  }, [getStorage]);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 100,
    });
    setParamSorting({sortBy: 'codMod', orderBy: 'DESC'});
  }, []);

  const paramFilters: FiltersParams = useMemo(() => {
    if (filters) {
      return JSON.parse(filters);
    }
    return null;
  }, [filters]);

  const {data: models, refetch} = useGetProposedModels({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    customizationId: customizationId!, //TODO check if exist customizationID
    ...paramSorting,
    ...(paramFilters || {}),
  });

  const {data: filtersModels} = useGetCurrentModelsFilters(customizationId!);

  useEffect(() => {
    const sortingParam = sorting && sorting[0];
    if (sortingParam) {
      sorting.length > 0 &&
        setParamSorting({
          sortBy: sortingParam.id,
          orderBy: sortingParam?.desc ? 'DESC' : 'ASC',
        });
      refetch();
    }
  }, [refetch, sorting]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: 'modCod',
        header: t('Models.table.codMod'),
        size: 130,
      },
      {
        accessorKey: 'nameElements',
        header: t('Models.table.name'),
        minSize: 300,
        Cell: (cell: CellProps) => {
          return (
            <>
              {
                <TextWithRoundedImage
                  imageFromSketch={cell.row.id}
                  text={cell.row.original.name}
                />
              }
            </>
          );
        },
      },
      {
        accessorKey: 'modDes',
        header: t('Models.table.desMod'),
      },
      {
        accessorKey: 'depCod',
        header: t('Models.table.dept'),
        size: 100,
        grow: false,
      },
      {
        accessorKey: 'subDepCod',
        header: t('Models.table.subDept'),
        size: 120,
        grow: false,
      },
      {
        accessorKey: 'season',
        header: t('Models.table.season'),
        size: 120,
        grow: false,
      },
      {
        accessorKey: 'event',
        header: t('Models.table.event'),
        size: 100,
        grow: false,
      },
      {
        accessorKey: 'delivery_date',
        header: t('Models.table.deliveryDate'),
        Cell: (cell: CellProps) => {
          return (
            <p>{`${cell.renderedCellValue ? cell.renderedCellValue : ''} ${
              cell.renderedCellValue ? 'weeks' : '-'
            }   `}</p>
          );
        },
      },
    ];
  }, [t]);

  const selectedRowsObject = useMemo(() => {
    return Object.keys(rowSelection).map(singleRow => {
      return models?.data?.models.find((singleModel: Model) => {
        return singleModel.modCod === singleRow;
      });
    });
  }, [models?.data?.models, rowSelection]);

  const pickedRows = useMemo(() => {
    return selectedRowsObject.map(singleRow => {
      return (
        singleRow &&
        (({modCod, modDes}: Partial<Model>): Partial<Model> => ({
          id: modCod,
          modDes,
        }))(singleRow)
      );
    });
  }, [selectedRowsObject]);

  const addSelectedRows = useCallback(async () => {
    try {
      await addModelsToCurrentModelsAsync(
        {
          customizationId: customizationId!,
          models: pickedRows,
        },
        {
          onSuccess: () => {
            setSuccessDeleteModalsOpen(true);
            setRowSelection({});
            refetch();
          },
        },
      );
    } catch (e) {
      //TODO handle fail request
      /*  setOpenUploadModal(false);
        setOpenUploadErrorModal(true);*/
    }
  }, [addModelsToCurrentModelsAsync, customizationId, pickedRows, refetch]);

  const tableFooter = useMemo(() => {
    /*TODO i18n*/
    return (
      <div className="table-footer-buttons  sm:px-5 2xl:px-0  flex flex-row 2xl:container mx-auto fixed bottom-0 left-[50%] transform translate-x-[-50%] w-full">
        <div className="flex justify-end py-5 w-full">
          <Button
            onClick={() => {
              addSelectedRows();
            }}
            iconLeft={'add'}
            variant={'primary'}
            disabled={Object.keys(rowSelection).length === 0}
            count={Object.keys(rowSelection).length}>
            {t('Models.buttons.add')}
          </Button>
        </div>
      </div>
    );
  }, [addSelectedRows, rowSelection, t]);

  const navigationElementsProposed = useMemo(() => {
    return [
      {label: t('Navigation.currentModels'), url: `/models/${customizationId}`},
      {label: t('Navigation.proposedModels'), isActive: true},
    ];
  }, [customizationId, t]);

  const mainNavigationElem = useMemo(() => {
    return <MainNavigation navigationElements={navigationElementsProposed} />;
  }, [navigationElementsProposed]);

  return (
    <Base>
      <Breadcrumb
        breadcrumb={[
          {
            label: t('Navigation.template'),
            link: '/',
          },
          {label: currentName},
          {label: t('Navigation.models')},
        ]}
      />
      {mainNavigationElem}
      {filtersModels && (
        <div className="2xl:container mx-auto py-5">
          <Filters
            makeResetRequest={() => {
              resetFilters();
              setTimeout(() => {
                refetch();
              });
            }}
            filterList={[
              {
                id: 'modCod',
                name: 'modCod',
                label: t('Models.table.codMod'),
              },
              {
                id: 'modDes',
                name: 'modDes',
                label: t('Models.table.desMod'),
              },
              {
                id: 'subDepCod',
                name: 'subDepCod',
                label: t('Models.table.subDept'),
                type: 'select',
                options: filtersModels.data.filters.subDepCod,
              },
            ]}
          />
        </div>
      )}
      {models && (
        <Table
          pageSize={pagination.pageSize}
          currentPage={pagination.pageIndex}
          totalRows={models?.data.totalCount}
          tableFooter={tableFooter}
          tableOptions={{
            getRowId: (originalRow: any) => originalRow.modCod,
            getRowModel: (originalRow: any) => originalRow,
            enableRowSelection: true,
            columns,
            rowCount: models?.data.totalCount,
            data: models?.data.models,
            manualSorting: true,
            initialState: {pagination: pagination},
            state: {
              sorting,
              rowSelection: rowSelection,
              pagination: pagination,
            },
            onSortingChange: setSorting,
            onRowSelectionChange: setRowSelection,
            onPaginationChange: setPagination,
          }}
        />
      )}

      <GenericModal
        onClose={() => setSuccessDeleteModalsOpen(false)}
        open={successDeleteModalsOpen}
        content={t('Models.successModal.message')}
        iconName={'save'}
      />
    </Base>
  );
};

export default React.memo(ProposedModels);
