import React, {useMemo} from 'react';

import {CirclePreviewProps} from './definitions';

const CirclePreview = ({variant, value, label}: CirclePreviewProps) => {
  const placeholder = useMemo(() => {
    return (
      <div
        className="w-full h-full rounded-[100%] bg-tan-3 overflow-hidden"
        style={{
          background:
            'linear-gradient(to top right, transparent calc(50% - 1px), #D0B194, transparent calc(50% + 1px) )',
        }}
      />
    );
  }, []);

  switch (variant) {
    case 'image':
      return (
        <>
          <div className="w-16 h-16 bg-white rounded-[100%] p-1 border border-solid border-tan-0">
            {value ? (
              <div
                className="w-full h-full rounded-[100%] bg-tan-3 overflow-hidden bg-cover bg-no-repeat bg-center"
                style={{backgroundImage: `url("${value}")`}}
              />
            ) : (
              placeholder
            )}
          </div>
          {label && (
            <p className="uppercase text-small text-primary-main">{label}</p>
          )}
        </>
      );
    case 'color':
      return (
        <>
          <div className="w-16 h-16 bg-white rounded-[100%] p-1 border border-solid border-tan-0">
            {value ? (
              <div
                className="w-full h-full rounded-[100%] overflow-hidden"
                style={{backgroundColor: value}}
              />
            ) : (
              placeholder
            )}
          </div>
          {label && (
            <p className="uppercase text-small text-primary-main">{label}</p>
          )}
        </>
      );
  }
};

export default React.memo(CirclePreview);
