import React, {useCallback, useMemo, useState} from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {useTranslations} from 'vidiemme/react-i18n';

import {IProps, TabRoundedElem} from './interfaces';
import './tab.scss';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}
const RoundedTab = ({tabList, onChangeTab}: IProps) => {
  const [value, setValue] = useState<number>(0);
  const {t} = useTranslations();
  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      onChangeTab(newValue);
      setValue(newValue);
    },
    [onChangeTab],
  );

  const tabListTpl = useMemo(() => {
    return tabList.map((singleTab: TabRoundedElem, index) => {
      return (
        <Tab
          key={singleTab}
          label={singleTab}
          disableRipple
          {...a11yProps(index)}
          classes={{
            root: 'px-2.5 py-[10px]',
          }}
        />
      );
    });
  }, [tabList]);

  const tabs = useMemo(() => {
    return (
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="rounded-tab">
        {tabListTpl}
      </Tabs>
    );
  }, [handleChange, tabListTpl, value]);

  return (
    <div className="flex flex-row justify-end items-center">
      <p className="font-book mr-2.5">{t('Materials.viewBy')}</p>
      {tabs}
    </div>
  );
};

export default React.memo(RoundedTab);
