import React from 'react';

import {Drawer} from '@mui/material';
import {SidebarButtons} from 'components/molecules/SidebarButtons';

import {SidebarProps} from './definitions';

const Sidebar = ({children, ...props}: SidebarProps) => {
  return (
    <Drawer
      anchor="right"
      transitionDuration={500}
      classes={{
        paper: 'bg-tan-3 p-10 min-w-[440px] max-w-[520px]',
      }}
      {...props}>
      <div className="flex-1 mb-2.5 overflow-y-auto">{children}</div>
      <div>{props.actions && <SidebarButtons {...props.actions} />}</div>
    </Drawer>
  );
};

export default React.memo(Sidebar);
