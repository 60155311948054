import React from 'react';

import {Stack} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Input} from 'components/atoms/Input';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

import {ICloneModal} from './interfaces';

const CloneModal = ({
  onClose,
  onConfirm,
  inputs,
  confirmDisabled,
  ...props
}: ICloneModal) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      title={t('Template.cloneModal.title')}
      content={
        <Stack width="100%" gap={2}>
          {inputs.map(({name, placeholder, required, type = 'text'}) => (
            <Input
              key={name}
              placeholder={placeholder}
              required={required}
              size="big"
              type={type}
              name={name}
            />
          ))}
        </Stack>
      }
      actions={
        <Stack width="100%" flexDirection="row" justifyContent="space-between">
          <Button variant="tertiary" onClick={onClose}>
            {t('Template.cloneModal.cancel')}
          </Button>
          <Button
            variant="primary"
            onClick={onConfirm}
            disabled={confirmDisabled}>
            {t('Template.cloneModal.confirm')}
          </Button>
        </Stack>
      }
    />
  );
};

export default React.memo(CloneModal);
