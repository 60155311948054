import React, {ReactElement, useMemo} from 'react';

import {Badge} from '@mui/material';

import {IconSVG} from '../IconSVG';

import './button.scss';

import {ButtonProps} from './definitions';

const Button = ({
  onClick,
  children,
  iconLeft,
  iconRight,
  iconSize = 18,
  variant = 'primary',
  disabled = false,
  classNames,
  count,
  size = 'regular',
  iconColor,
  type = 'button',
  picker,
  pickerInputProps,
}: ButtonProps): ReactElement => {
  const Content = useMemo(() => {
    return (
      <>
        {iconLeft && (
          <IconSVG icon={iconLeft} size={iconSize} color={iconColor} />
        )}
        {children}
        {picker !== undefined && (
          <input className="hidden-input" type={picker} {...pickerInputProps} />
        )}
        {iconRight && (
          <IconSVG icon={iconRight} size={iconSize} color={iconColor} />
        )}
      </>
    );
  }, [
    children,
    iconColor,
    iconLeft,
    iconRight,
    iconSize,
    picker,
    pickerInputProps,
  ]);

  const ButtonElement = useMemo(() => {
    return !picker ? (
      <button
        type={type}
        className={`button button-${variant} button-${size} font-bold flex items-center justify-center gap-1 ${classNames}`}
        onClick={onClick}
        disabled={disabled}>
        {Content}
      </button>
    ) : (
      <label
        className={`button button-${variant} button-${size} font-bold flex items-center justify-center gap-1 cursor-pointer ${classNames}`}
        onClick={onClick}>
        {Content}
      </label>
    );
  }, [Content, classNames, disabled, onClick, picker, size, type, variant]);

  if (count !== undefined) {
    return (
      <Badge
        classes={{
          badge: 'rounded-[30px] h-0 p-2.5 bg-green-1 text-regular',
        }}
        max={99999}
        badgeContent={count}
        color="success">
        {ButtonElement}
      </Badge>
    );
  }

  return ButtonElement;
};

export default React.memo(Button);
