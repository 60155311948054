import React, {ReactElement} from 'react';

import './textarea.scss';

import {TextareaProps} from './definitions';

const Textarea = ({
  variant = 'primary',
  size = 'small',
  label,
  disabled,
  ...props
}: TextareaProps): ReactElement => {
  return (
    <div className="flex-col flex text-primary-main text-small">
      <label className="font-book uppercase">{label}</label>
      <textarea
        className={`textarea textarea-${variant} textarea-${size} ${
          disabled ? 'textarea-disabled' : ''
        }`}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default React.memo(Textarea);
