import React from 'react';

import {DialogProps} from '@mui/material';
import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {useTranslations} from 'vidiemme/react-i18n';

const UnexpectedValueModal: React.FC<
  DialogProps & {onClose: () => void; onIgnore: () => void}
> = ({onClose, onIgnore, ...props}) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon={'error'}
      content={t('Assignments.UnexpectedValueModal.text')}
      actions={[
        <Button variant="secondary" key="ignore" onClick={onIgnore}>
          {t('Assignments.UnexpectedValueModal.ignore')}
        </Button>,
        <Button variant="primary" key="review" onClick={onClose}>
          {t('Assignments.UnexpectedValueModal.review')}
        </Button>,
      ]}
    />
  );
};

export default React.memo(UnexpectedValueModal);
