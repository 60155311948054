import React, {ReactElement, useCallback, useMemo} from 'react';

import {Button} from 'components/atoms/Button';
import {Input} from 'components/atoms/Input';
import {Select} from 'components/atoms/Select';
import {useFilterContext} from 'contexts/filter/FiltersContext';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {useTranslations} from 'vidiemme/react-i18n';

import {FiltersForm, FiltersProps} from './definitions';

const Filters = ({
  filterList,
  makeResetRequest,
}: FiltersProps): ReactElement => {
  const {t} = useTranslations();

  const defaultValues = useMemo(() => {
    return {
      id: '',
      identifier: '',
      name: '',
      department: '',
      sub_departments: '',
      seasons: '',
      event: '',
      catalog: '',
      status: '',
      primaryId: '',
      dependencyId: '',
      primaryName: '',
      dependencyName: '',
      primary: '',
      dependency: '',
    };
  }, []);

  const formMethods = useForm<FiltersForm>({
    defaultValues: defaultValues,
  });

  const {watch, reset, control} = formMethods;

  const watchedInputs = watch();

  const isAnyInputFilled = useMemo(() => {
    return Object.values(watchedInputs).some(value => Boolean(value));
  }, [watchedInputs]);

  const {setFilters, filters, resetFilters} = useFilterContext();

  const basisClass = useMemo(() => {
    switch (filterList.length) {
      case 1:
        return 'basis-full';
      case 2:
        return 'basis-1/2';
      case 3:
        return 'basis-1/3';
      case 4:
        return 'basis-1/4';
      case 5:
        return 'basis-1/5';
      case 6:
        return 'basis-1/6';
      case 7:
        return 'basis-1/7';
      case 8:
        return 'basis-1/8';
    }
  }, [filterList]);

  const setNewQsFilter = useCallback(
    (filterName: string, filterValue: string) => {
      const newQsFilter = {[filterName]: filterValue};
      const parsedFilter = filters && JSON.parse(filters);
      const newFilter = {...parsedFilter, ...newQsFilter};
      if (filterValue === '') {
        delete newFilter[filterName];
      }
      setFilters(JSON.stringify(newFilter));
    },
    [filters, setFilters],
  );

  const filtersMarkup = useMemo(() => {
    return filterList.map(singleFilter => {
      return (
        <div
          className={singleFilter.maxi ? 'basis-2/3' : basisClass}
          key={singleFilter.id}>
          {(singleFilter.type === 'text' || !singleFilter.type) && (
            <Input
              id={singleFilter.id}
              label={singleFilter.label}
              name={singleFilter.name}
              value={watchedInputs[singleFilter.name]}
              placeholder={t('Filters.all')}
              onChange={newValue => {
                setNewQsFilter(
                  newValue.currentTarget.name,
                  newValue.currentTarget.value,
                );
              }}
            />
          )}
          {singleFilter.type === 'select' &&
            singleFilter.options !== undefined && (
              <Controller
                name={singleFilter.name}
                control={control}
                render={({field: {name, onChange}}) => (
                  <Select
                    name={name}
                    value={
                      singleFilter.options?.find(
                        opt => opt.value === watchedInputs[singleFilter.name],
                      ) ?? null
                    }
                    label={singleFilter.label}
                    options={singleFilter.options}
                    placeholder={t('Filters.all')}
                    onChange={option => {
                      if (option) {
                        onChange(option.value);
                        setNewQsFilter(singleFilter.id, option.value);
                      }
                    }}
                  />
                )}
              />
            )}
        </div>
      );
    });
  }, [basisClass, control, filterList, setNewQsFilter, t, watchedInputs]);

  const resetFiltersCallback = useCallback(() => {
    resetFilters();
    reset();
    makeResetRequest?.();
  }, [makeResetRequest, reset, resetFilters]);

  return (
    <FormProvider {...formMethods}>
      <form className="flex flex-row items-start gap-8">
        <div className="flex-col flex-1">
          <div className="flex flex-nowrap gap-2">{filtersMarkup}</div>
        </div>
        <div className="flex-col pt-3.5">
          <Button
            iconRight="cancel"
            onClick={() => {
              resetFiltersCallback();
            }}
            disabled={!isAnyInputFilled}
            variant="tertiary">
            {t('Filters.clear')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default React.memo(Filters);
