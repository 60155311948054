export enum AnonymousRoutes {
  LOGIN = '/login',
  SSO_CALLBACK = '/login/callback',
}

export enum AuthRoutes {
  MAIN = '/',
  MODELS = '/models/:customizationId',
  MATERIALS = '/materials/:customizationId',
  PROPOSED_MODELS = '/proposed_models/:customizationId',
  PAGES_SWITCH = '/configuration/:customizationId',
}
