import React from 'react';

import {Button} from 'components/atoms/Button';
import {Modal} from 'components/molecules/Modal';
import {Link} from 'react-router-dom';
import {useTranslations} from 'vidiemme/react-i18n';

import {InvalidCredentialsModalProps} from './interfaces';

const GoToForgotPasswordModal = ({...props}: InvalidCredentialsModalProps) => {
  const {t} = useTranslations();

  return (
    <Modal
      {...props}
      headerIcon="error"
      content={t('Login.modalPasswordExpired.content')}
      actions={[
        <Link to={props.url} key="primaryButtonModal">
          <Button variant="primary">
            {t('Login.modalPasswordExpired.updatePassword')}
          </Button>
        </Link>,
      ]}
    />
  );
};

export default React.memo(GoToForgotPasswordModal);
