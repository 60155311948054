import React from 'react';

import {Input} from 'components/atoms/Input';
import {Textarea} from 'components/atoms/Textarea';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslations} from 'vidiemme/react-i18n';

import {ModelFormFields} from '../definitions';

const Info = () => {
  const {t} = useTranslations();
  const {control} = useFormContext<ModelFormFields>();

  return (
    <div className="pb-[30px] border-b border-solid border-tan-0 flex flex-col gap-2.5">
      <p className="uppercase font-bold text-primary-main">
        {t('ValuesSidebar.info')}
      </p>
      <Input
        name="name"
        label={t('ModelsSidebar.fields.name.label')}
        placeholder={t('ModelsSidebar.fields.name.placeholder')}
        required={true}
      />
      <Input
        name="deliveryDate"
        label={t('ModelsSidebar.fields.deliveryDate.label')}
        placeholder={t('ModelsSidebar.fields.deliveryDate.placeholder')}
      />
      <Controller
        name="description"
        control={control}
        render={({field: {name, value, onChange}}) => (
          <Textarea
            name={name}
            value={value}
            onChange={onChange}
            label={t('ModelsSidebar.fields.description.label')}
            placeholder={t('ModelsSidebar.fields.description.placeholder')}
          />
        )}
      />
    </div>
  );
};

export default React.memo(Info);
