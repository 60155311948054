import React, {useMemo} from 'react';

import {FormControlLabel, FormGroup} from '@mui/material';
import {Checkbox} from 'components/atoms/Checkbox';
import {Input} from 'components/atoms/Input';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslations} from 'vidiemme/react-i18n';

import {ValueFormFields} from '../definitions';

import {AdvancedSettingsProps, SettingItem} from './definitions';

const AdvancedSettings = ({disabledFallbackField}: AdvancedSettingsProps) => {
  const {t} = useTranslations();

  const {control} = useFormContext<ValueFormFields>();

  const settings = useMemo<SettingItem[]>(() => {
    return [
      {
        label: t('ValuesSidebar.fields.fallback.label'),
        name: 'fallback',
        disabled: disabledFallbackField,
      },
      {
        label: t('ValuesSidebar.fields.selected.label'),
        name: 'always_selected',
      },
    ];
  }, [disabledFallbackField, t]);

  return (
    <div className="pb-[30px] border-b border-solid border-tan-0 flex flex-col gap-2.5">
      <p className="uppercase font-bold text-primary-main">
        {t('ValuesSidebar.advancedSettings')}
      </p>
      <FormGroup className="gap-2.5">
        <Controller
          key={'tracking_tool_id'}
          name={'tracking_tool_id'}
          control={control}
          render={({field: {value}}) => (
            <Input
              value={value}
              name="tracking_tool_id"
              label={t('ValuesSidebar.fields.tracking.label')}
              placeholder={t('ValuesSidebar.fields.tracking.placeholder')}
            />
          )}
        />
        <Controller
          key={'default_if'}
          name={'default_if'}
          control={control}
          render={({field: {value}}) => (
            <Input
              value={value}
              name="default_if"
              label={t('ValuesSidebar.fields.default.label')}
              placeholder={t('ValuesSidebar.fields.default.label')}
            />
          )}
        />
      </FormGroup>
      <FormGroup className="gap-2.5">
        {settings.map(({label, name, disabled = false}) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({field: {name, value, onChange}}) => (
              <FormControlLabel
                className="m-0"
                slotProps={{
                  typography: {
                    className: 'text-regular',
                  },
                }}
                label={label}
                name={name}
                checked={Boolean(value)}
                onChange={onChange}
                control={<Checkbox disabled={disabled} />}
              />
            )}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default React.memo(AdvancedSettings);
