import {useCallback, useMemo} from 'react';

import {useMutation} from '@tanstack/react-query';
import axios, {AxiosError, AxiosInstance} from 'axios';
import {useUtils} from 'hooks';
import {AnonymousRoutes, useNavigation} from 'navigation';

export const useAPI = () => {
  const {customError, setStorage, getStorage, removeStorage} = useUtils();
  const {goToPath} = useNavigation();
  const user = getStorage('user');

  const refreshToken =
    user && JSON.parse(user) && JSON.parse(user)?.data?.refreshToken;

  const token = useMemo(() => {
    return user && JSON.parse(user) && JSON.parse(user)?.data?.token;
  }, [user]);

  const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const proxyApi: AxiosInstance = axios.create({
    baseURL: 'https://gtg-wdproxy.wwmis.gucci',
  });

  const oldServerApi: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_OLD_SERVER_BASE_URL,
  });

  api.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async error => {
      if (
        //error code 777 and statusCode 401 means that token is expired
        error?.response?.data?.errorCode === 777 &&
        error?.response?.data?.statusCode === 401 &&
        !isLoadingRefreshTokenAsync
      ) {
        const originalRequest = error.config;
        originalRequest._retry = true;
        const access_token = await refreshTokenAsync();
        originalRequest.headers = access_token && {
          Authorization: `Bearer ${access_token}`,
        };
        return axios(originalRequest);
      } else if (
        error?.response?.data?.errorCode !== 777 &&
        error?.response?.data?.statusCode === 401
      ) {
        removeStorage('user');
        goToPath(AnonymousRoutes.LOGIN);
      } else {
        return Promise.reject(error);
      }
    },
  );

  const refreshAccessToken = useCallback(async () => {
    try {
      const {data} = await api.post('/api/v1/auth/token?refreshToken', {
        provider: 'refreshToken',
        token: refreshToken,
      });
      setStorage('user', data);
      return data.data.token;
    } catch (error) {
      customError(error as Error);
      if (error instanceof AxiosError && error?.response) {
        throw error;
      }
    }
  }, [api, customError, refreshToken, setStorage]);

  const {
    mutateAsync: refreshTokenAsync,
    isPending: isLoadingRefreshTokenAsync,
  } = useMutation({
    mutationFn: refreshAccessToken,
  });

  return {
    api: api,
    proxyApi: proxyApi,
    oldServerApi: oldServerApi,
  };
};
