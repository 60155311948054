import React from 'react';

import {Button} from 'components/atoms/Button';
import {Input} from 'components/atoms/Input';
import {PickerWithPreview} from 'components/molecules/PickerWithPreview';
import {usePageContext} from 'contexts/page/PageContext';
import {Controller, useFormContext} from 'react-hook-form';
import {useTranslations} from 'vidiemme/react-i18n';

import {ValueFormFields} from '../definitions';

import {AppearanceProps} from './definitions';

const Appearance = ({linkToSwathes}: AppearanceProps) => {
  const {t} = useTranslations();
  const {control} = useFormContext<ValueFormFields>();
  const {goToTab} = usePageContext();

  return (
    <div className="pb-[30px] border-b border-solid border-tan-0 flex flex-col gap-2.5">
      <p className="uppercase font-bold text-primary-main">
        {t('ValuesSidebar.appearance')}
      </p>
      {linkToSwathes ? (
        <Controller
          name="color"
          control={control}
          render={({field: {value, name, onChange}}) => (
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-2.5">
                <PickerWithPreview
                  variant="color"
                  value={value}
                  name={name}
                  type="color"
                  previewLabel={t('ValuesSidebar.fields.color.previewLabel')}
                  onChange={event => {
                    onChange(event.target.value);
                  }}
                />
              </div>

              <Button
                variant="tertiary"
                iconRight="arrow-forward"
                onClick={() => goToTab('swatches')}>
                {t('ValuesSidebar.fields.color.swatchesLabel')}
              </Button>
            </div>
          )}
        />
      ) : (
        <>
          <Controller
            name="picture"
            control={control}
            render={({field: {name, value, onChange}}) => (
              <PickerWithPreview
                variant="image"
                value={
                  value instanceof File ? URL.createObjectURL(value) : value
                }
                previewLabel={t('ValuesSidebar.fields.image.previewLabel')}
                buttonLabel={t('ValuesSidebar.fields.image.label')}
                allowedFiles="image/png,image/jpeg"
                name={name}
                type="file"
                onChange={event => {
                  const {
                    target: {files},
                  } = event;
                  if (files) {
                    onChange(files[0]);
                  }
                }}
              />
            )}
          />
          <Controller
            name="color"
            control={control}
            render={({field: {name, value, onChange}}) => (
              <PickerWithPreview
                variant="color"
                value={value}
                name={name}
                type="color"
                previewLabel={t('ValuesSidebar.fields.color.previewLabel')}
                buttonLabel={t('ValuesSidebar.fields.color.label')}
                onChange={event => {
                  onChange(event.target.value);
                }}
              />
            )}
          />
        </>
      )}
      <Controller
        key={'shortcut_image_provider'}
        name={'shortcut_image_provider'}
        control={control}
        render={({field: {value}}) => (
          <Input
            name="shortcut_image_provider"
            value={value}
            label={t('ValuesSidebar.fields.color.shortcutImageProvider')}
            placeholder={t('ValuesSidebar.fields.tracking.placeholder')}
          />
        )}
      />
    </div>
  );
};

export default React.memo(Appearance);
