import React, {FunctionComponent, useEffect, useState} from 'react';

import Login from 'components/pages/Login/Login';
import {Models} from 'components/pages/Models/CurrentModels';
import ProposedModels from 'components/pages/Models/ProposedModels/ProposedModels';
import PagesSwitch from 'components/pages/PagesSwitch/PagesSwitch';
import Sso from 'components/pages/Sso/Sso';
import Template from 'components/pages/Template/Template';
import {useToast} from 'hooks';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {useTranslations} from 'vidiemme/react-i18n';

import {FourZeroFour} from '../components/pages/404';

import Authenticated from './middleware/Authenticated';
import Guest from './middleware/Guest';
import {AnonymousRoutes, AuthRoutes} from './routes';

export const Navigation: FunctionComponent = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const {showErrorToast} = useToast();
  const {t} = useTranslations();

  useEffect(() => {
    setInitialized(true);
  }, [setInitialized, t, showErrorToast]);

  if (initialized) {
    return (
      <Router>
        <Routes>
          {/* @TODO: check if route exists and show 404 page*/}

          {/* Add all guest routes as child of this middleware route */}
          <Route element={<Guest />}>
            <Route path={AnonymousRoutes.LOGIN} element={<Login />} />
            <Route path={AnonymousRoutes.SSO_CALLBACK} element={<Sso />} />
          </Route>
          {/* Add all authenticated routes as child of this middleware route */}
          <Route element={<Authenticated />}>
            <Route path={AuthRoutes.MAIN} element={<Template />} />
            <Route path={AuthRoutes.PAGES_SWITCH} element={<PagesSwitch />} />
            <Route path={AuthRoutes.MODELS} element={<Models />} />
            <Route
              path={AuthRoutes.PROPOSED_MODELS}
              element={<ProposedModels />}
            />
          </Route>

          {/* Handle all non matching routes */}
          <Route path="*" element={<FourZeroFour />} />
        </Routes>
      </Router>
    );
  } else {
    return <></>;
  }
};
