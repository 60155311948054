import React, {useCallback, useState} from 'react';

import {yupResolver} from '@hookform/resolvers/yup';
import {Stack} from '@mui/material';
import {AxiosError} from 'axios';
import {Button} from 'components/atoms/Button';
import {Input} from 'components/atoms/Input';
import {Modal} from 'components/molecules/Modal';
import {InvalidCredentialsModal} from 'components/organisms/InvalidCredentialsModal';
import {FormProvider, useForm} from 'react-hook-form';
import {useAuthentication} from 'store/authentication';
import {useTranslations} from 'vidiemme/react-i18n';
import * as yup from 'yup';

import EmailSentModal from '../EmailSentModal/EmailSentModal';

import {ForgottenPasswordModalProps} from './interfaces';

const ForgottenPasswordModal = ({...props}: ForgottenPasswordModalProps) => {
  const {t} = useTranslations();
  const {forgotPasswordAsync} = useAuthentication();
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const schema = yup.object().shape({
    email: yup.string().required(),
  });
  const formMethods = useForm<{email: string}>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const {
    handleSubmit,
    formState: {isValid},
    resetField,
  } = formMethods;

  const handleConfirm = useCallback(
    async (data: {email: string}) => {
      props.onClose();
      try {
        await forgotPasswordAsync(
          {...data},
          {
            onSuccess: () => {
              console.log('here');
              setOpenEmailModal(true);
            },
          },
        );
      } catch (e) {
        if (e instanceof AxiosError) {
          setErrorMessage(e.message);
        }
      }
      resetField('email');
    },
    [forgotPasswordAsync, props, resetField],
  );

  return (
    <>
      <Modal
        {...props}
        title={t('Login.modalForgetPassword.title')}
        content={
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleConfirm)}>
              <Stack gap={4}>
                <p>{t('Login.modalForgetPassword.content')}</p>
                <Input
                  name="email"
                  size="big"
                  placeholder={t('Login.modalForgetPassword.placeholder')}
                />
                <Stack
                  key="forgottenPasswordActions"
                  width="100%"
                  flexDirection="row"
                  justifyContent="space-between">
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      props.onClose();
                      resetField('email');
                    }}>
                    {t('Login.modalForgetPassword.cancel')}
                  </Button>
                  <Button variant="primary" disabled={!isValid} type="submit">
                    {t('Login.modalForgetPassword.confirm')}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        }
      />
      <EmailSentModal
        open={openEmailModal}
        onClose={() => setOpenEmailModal(false)}
      />
      <InvalidCredentialsModal
        open={errorMessage !== ''}
        message={errorMessage}
        onClose={() => {
          setErrorMessage('');
        }}
      />
    </>
  );
};

export default React.memo(ForgottenPasswordModal);
