import React from 'react';

import {Navigate, Outlet} from 'react-router-dom';

import {useAuthentication} from '../../store/authentication';
import {AuthRoutes} from '../routes';

const Guest = () => {
  const {isLoggedIn} = useAuthentication();

  if (isLoggedIn) {
    return <Navigate to={AuthRoutes.MAIN} replace />;
  } else {
    return <Outlet />;
  }
};

export default React.memo(Guest);
