import {useCallback} from 'react';

import {useMutation, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks/index';

import {useAPI} from '../api';

import {
  AddCustomizationParams,
  CustomizationParams,
  CustomizationsParams,
  EditCustomizationParams,
  ModelsQueryKeys,
} from './definitions';

export const useModels = () => {
  const {api, proxyApi} = useAPI();
  const {customError} = useUtils();

  //Proxy getImage
  const proxyEndpoint: string = 'https://gtg-wdproxy.wwmis.gucci';
  const proxyApplication: string = '/getimg';
  const proxyGetImageEndpoint: string = 'https://getimage.emea.guccigroup.dom';
  const getImageApplication: string = '/getimage';
  const sizeParam: string = '&dim=s';
  const searchParam: string = '&ds=1';
  const resultFormat: string = '&fmt=j';

  const proxyMap = useCallback((url: string) => {
    return url
      ?.replace(proxyGetImageEndpoint, proxyEndpoint)
      .replace(getImageApplication, proxyApplication)
      .replace('/Thumbs', '/tmbs')
      .replace('ThPh', 'thPh')
      .replace('/Sketchs', '/skts')
      .replace('ThSk', 'thSk')
      .replace('/thMissing', '/thMis')
      .replace('/thWatermark', '/thWM');
  }, []);

  const getSketch = useCallback(
    async (sketchCode?: string) => {
      if (!sketchCode) {
        return;
      }
      const imageParam: string = `?cod=${sketchCode}${sizeParam}${searchParam}${resultFormat}`;
      const currentUrl = `${proxyApplication}${imageParam}`;

      try {
        const {data} = await proxyApi.get(currentUrl);
        return proxyMap(data?.GetImage?.Links[0]?.URL);
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [customError, proxyApi, proxyMap],
  );

  const getCurrentModels = useCallback(
    async ({...params}: CustomizationsParams) => {
      try {
        const {data} = await api.get(
          `/api/v1/customizations/${params.customizationId}/models/current`,
          {
            params,
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getProposedModels = useCallback(
    async ({...params}: CustomizationsParams) => {
      try {
        const {data} = await api.get(
          `/api/v1/customizations/${params.customizationId}/models/proposed`,
          {
            params,
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getFilters = useCallback(
    async (customizationId: string) => {
      try {
        const {data} = await api.get(
          `/api/v1/customizations/${customizationId}/models/filters`,
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const removeModelsFromCurrentModels = useCallback(
    async ({...params}: CustomizationParams) => {
      try {
        const {data} = await api.delete(
          `/api/v1/customizations/${params.customizationId}/models`,
          {data: {models: params.models}},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const addModelsToCurrentModels = useCallback(
    async ({...params}: AddCustomizationParams) => {
      try {
        const {data} = await api.put(
          `/api/v1/customizations/${params.customizationId}/models`,
          {models: [...params.models]},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const editCurrentModel = useCallback(
    async ({...params}: EditCustomizationParams) => {
      try {
        const {data} = await api.patch(
          `/api/v1/customizations/${params.customizationId}/models/${params.modelCode}`,
          {...params},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  //Queries
  const useGetCurrentModels = ({...params}: CustomizationsParams) =>
    useQuery({
      queryKey: [ModelsQueryKeys.CURRENT_MODELS_QUERY_KEY, params],
      queryFn: async () => getCurrentModels({...params}),
    });
  const useGetProposedModels = ({...params}: CustomizationsParams) =>
    useQuery({
      queryKey: [ModelsQueryKeys.PROPOSED_MODELS_QUERY_KEY, params],
      queryFn: async () => getProposedModels({...params}),
    });
  const useGetCurrentModelsFilters = (customizationId: string) =>
    useQuery({
      queryKey: [ModelsQueryKeys.CURRENT_MODELS_QUERY_KEY],
      queryFn: async () => getFilters(customizationId),
    });

  const {mutateAsync: removeModelsFromCurrentModelsAsync} = useMutation({
    mutationFn: removeModelsFromCurrentModels,
  });

  const {mutateAsync: editCurrentModelAsync} = useMutation({
    mutationFn: editCurrentModel,
  });

  const {mutateAsync: addModelsToCurrentModelsAsync} = useMutation({
    mutationFn: addModelsToCurrentModels,
  });

  const useGetSketch = (sketchCode?: string) =>
    useQuery({
      queryKey: [ModelsQueryKeys.CURRENT_MODELS_QUERY_KEY, {sketchCode}],
      queryFn: async () => getSketch(sketchCode),
      enabled: sketchCode !== undefined,
    });

  return {
    useGetCurrentModels,
    useGetCurrentModelsFilters,
    removeModelsFromCurrentModelsAsync,
    editCurrentModelAsync,
    useGetProposedModels,
    addModelsToCurrentModelsAsync,
    useGetSketch,
  };
};
