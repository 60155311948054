import {useCallback} from 'react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {useUtils} from 'hooks/index';
import {useAPI} from 'store/api';

import {
  AddValuesFromCatalogParams,
  CatalogValueItem,
  CreateValueParams,
  DeleteFieldValuesParams,
  GetValuesFromCatalogParams,
  GetValuesFromCatalogResponse,
  GetValuesParams,
  GetValuesResponse,
  UpdateValueParams,
  ValuesQueryKeys,
} from './definitions';

export const usePageValues = () => {
  const {api} = useAPI();
  const {customError} = useUtils();
  const queryClient = useQueryClient();

  // Api

  const getValues = useCallback(
    async ({customizationId, currentPageId, options}: GetValuesParams) => {
      try {
        const {data} = await api.get<GetValuesResponse>(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values`,
          {params: options},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const deleteValues = useCallback(
    async ({
      customizationId,
      currentPageId,
      params,
    }: DeleteFieldValuesParams) => {
      try {
        const {data} = await api.delete(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values`,
          {data: {valueIds: params.valueIds}},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const createValue = useCallback(
    async ({customizationId, currentPageId, params}: CreateValueParams) => {
      try {
        const {data} = await api.post(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values`,
          params,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const updateValue = useCallback(
    async ({
      customizationId,
      currentPageId,
      valueId,
      params,
    }: UpdateValueParams) => {
      try {
        const {data} = await api.patch(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values/${valueId}`,
          params,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const getValuesFromCatalog = useCallback(
    async ({
      customizationId,
      currentPageId,
      options,
    }: GetValuesFromCatalogParams) => {
      try {
        const {data} = await api.get<GetValuesFromCatalogResponse>(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values/fromCatalog`,
          {params: options},
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  const addValuesFromCatalog = useCallback(
    async ({
      customizationId,
      currentPageId,
      params,
    }: AddValuesFromCatalogParams) => {
      try {
        const {data} = await api.post(
          `/api/v1/customizations/${customizationId}/fields/${currentPageId}/values/fromCatalog`,
          params,
        );
        return data;
      } catch (error) {
        customError(error as Error);
        if (error instanceof AxiosError && error?.response) {
          throw error;
        }
      }
    },
    [api, customError],
  );

  // Queries

  const useGetValues = (params: GetValuesParams) =>
    useQuery({
      queryKey: [ValuesQueryKeys.GET_VALUES_QUERY_KEY, params],
      queryFn: async () => getValues(params),
    });

  const {mutateAsync: callDeleteValues} = useMutation({
    mutationFn: deleteValues,
    mutationKey: [ValuesQueryKeys.DELETE_VALUES_KEY],
    onSuccess: (data, variables) => {
      // Refresh values
      queryClient.invalidateQueries({
        queryKey: [
          ValuesQueryKeys.GET_VALUES_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ],
        exact: false,
        refetchType: 'all',
      });
    },
  });

  const {mutateAsync: callCreateValue} = useMutation({
    mutationFn: createValue,
    mutationKey: [ValuesQueryKeys.CREATE_VALUE_KEY],
    onSuccess: (data, variables) => {
      // Refresh values
      queryClient.invalidateQueries({
        queryKey: [
          ValuesQueryKeys.GET_VALUES_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ],
        exact: false,
        refetchType: 'all',
      });
    },
  });

  const {mutateAsync: callUpdateValue} = useMutation({
    mutationFn: updateValue,
    mutationKey: [ValuesQueryKeys.UPDATE_VALUE_KEY],
    onSuccess: (data, variables) => {
      // Refresh values (only current query since is coming from an update)
      queryClient.invalidateQueries({
        queryKey: [
          ValuesQueryKeys.GET_VALUES_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ],
        exact: false,
      });
    },
  });

  const useGetValuesFromCatalog = (params: GetValuesFromCatalogParams) =>
    useQuery({
      queryKey: [ValuesQueryKeys.GET_VALUES_FROM_CATALOG_QUERY_KEY, params],
      queryFn: async () => getValuesFromCatalog(params),
    });

  const {mutateAsync: callAddValuesFromCatalog} = useMutation({
    mutationFn: addValuesFromCatalog,
    mutationKey: [ValuesQueryKeys.ADD_VALUES_FROM_CATALOG_QUERY_KEY],
    onSuccess: (data, variables) => {
      // Refresh values
      queryClient.invalidateQueries({
        queryKey: [
          ValuesQueryKeys.GET_VALUES_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ],
        exact: false,
        refetchType: 'all',
      });

      // Refresh values from catalog
      queryClient.invalidateQueries({
        queryKey: [
          ValuesQueryKeys.GET_VALUES_FROM_CATALOG_QUERY_KEY,
          {
            customizationId: variables.customizationId,
            currentPageId: variables.currentPageId,
          },
        ],
        exact: false,
        refetchType: 'all',
      });
    },
  });

  const getFlattenedValuesFromCatalog = ({
    customizationId,
    currentPageId,
  }: Pick<GetValuesFromCatalogParams, 'customizationId' | 'currentPageId'>) => {
    const allQueries = queryClient.getQueriesData<GetValuesFromCatalogResponse>(
      {
        queryKey: [
          ValuesQueryKeys.GET_VALUES_FROM_CATALOG_QUERY_KEY,
          {
            customizationId,
            currentPageId,
          },
        ],
        exact: false,
        type: 'all',
      },
    );

    return allQueries
      .flatMap(query => {
        return query[1]?.data.results;
      })
      .filter((item): item is CatalogValueItem => item !== undefined);
  };

  return {
    useGetValues,
    callDeleteValues,
    callCreateValue,
    callUpdateValue,
    useGetValuesFromCatalog,
    callAddValuesFromCatalog,
    getFlattenedValuesFromCatalog,
  };
};
