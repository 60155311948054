import React, {ReactElement} from 'react';

import './input.scss';
import {useFormContext} from 'react-hook-form';

import {InputProps} from './definitions';

const Input = ({
  name,
  type = 'text',
  variant = 'primary',
  size = 'small',
  label,
  onChange,
  rules,
  disabled,
  ...props
}: InputProps): ReactElement => {
  const {register} = useFormContext();

  return (
    <div className="flex-col flex text-primary-main text-small">
      <label className="font-book uppercase whitespace-nowrap xl:whitespace-normal">{label}</label>
      <input
        {...props}
        type={type}
        className={`input input-${variant} input-${size} ${
          disabled ? 'input-disabled' : ''
        }`}
        disabled={disabled}
        {...register(name, {
          required: props.required,
          onChange: onChange && onChange,
          ...rules,
        })}
      />
    </div>
  );
};

export default React.memo(Input);
